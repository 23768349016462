<script lang="ts" setup>

import { ref } from "vue";

const props = defineProps({

  iconOn: {
    type: String,
    default: '',
  },
  iconOff: {
    type: String,
    default: '',
  },
  width: {
    type: [Number, String],
    default: () => null
  },
  height: {
    type: [Number, String],
    default: () => null
  },
  modelValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);
const modelValue = ref(props.modelValue);

const toggleValue = () => {
  modelValue.value = !modelValue.value;
  emit('update:modelValue', modelValue.value);
}

</script>


<template>
  <button
    :class="[
      'app-toggler',
      {
        'app-toggler_on': modelValue
      }
    ]"
    type="button"
    @click="toggleValue()"
  >
    <svg
      class="app-toggler__icon app-toggler__icon_on"
      :data-src="props.iconOn"
    />
    <svg
      class="app-toggler__icon app-toggler__icon_off"
      :data-src="props.iconOff"
    />
  </button>
</template>


<style lang="scss" src="./AppIconToggler.scss"/>