<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 15.2167L4.12259 18.5067L5.43509 11.9001L0.489258 7.32675L7.17843 6.53341L10.0001 0.416748L12.8218 6.53341L19.5109 7.32675L14.5651 11.9001L15.8776 18.5067L10.0001 15.2167Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>