import api from "@/network";

export default {
    async get(uuid: string) {
        return await api.get(`plans/${uuid}`);
    },
    async delete(uuid: string) {
        return await api.delete(`plans/${uuid}`);
    },
    async update(uuid: string, payload: any) {
        payload.planFiles = payload.files.map((file: any) => file.uuid);
        return await api.put(`plans/${uuid}`, payload);
    },
    async updateInterpretation(uuid: string, payload: any) {
        return await api.put(`plans/${uuid}/interpretation`, {
            interpritation: payload
        });
    },
    async createPlanAndAssignTests(testUuids: string[]) {
        return await api.post('tests/assign', {
            testUuids
        });
    },
    async create (payload?: any) {
        return await api.post('plans', payload);
    },
    async getParticipantsOfPlan(uuid: string, params: any) {
        return await api.get(
            `plans/${uuid}/users`,
            {
                params
            }
        )
    }
}