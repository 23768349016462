import CacheCore from "./core.cache";

export default abstract class CacheArrayCore extends CacheCore {

  protected constructor(key: string) {
    super(key)
  }

  add(value: any) {
    const valueJsonified = this.getJsonified();
    if(valueJsonified === null) {
      this.setStringified([value]);
      return;
    }

    if(!Array.isArray(valueJsonified)) {
      throw Error('Value is not array');
    }

    valueJsonified.push(value)
    this.setStringified(valueJsonified)
  }

  getJsonified(): any[] {
    let result: any[] = []

    const stored = localStorage.getItem(this.key)

    if (stored !== null) {
      try {
        const parsed = JSON.parse(stored);

        if (Array.isArray(parsed)) {
          result = parsed;
        }
      } catch {
      }
    }

    return result;
  }

  remove(value: any) {
    const valueJsonified = this.getJsonified();

    if(!Array.isArray(valueJsonified)) {
      throw Error('Value is not array');
    }

    valueJsonified.splice(valueJsonified.indexOf(value), 1);
    this.setStringified(valueJsonified)
  }
  removeObject(key: string, value: any) {
    const valueJsonified = this.getJsonified();

    if(!Array.isArray(valueJsonified)) {
      throw Error('Value is not array');
    }

    const index = valueJsonified.findIndex(item => item[key] === value);

    valueJsonified.splice(index, 1);
    this.setStringified(valueJsonified)
  }
  includes(value: any) {
    const valueJsonified = this.getJsonified();
    if(!valueJsonified) return false;
    return valueJsonified.includes(value);
  }
}
