<template>
  <div>
    <expert-review-table />

    <router-view />
  </div>
</template>

<script>

import ExpertReviewTable from "@/components/workspace/ExpertReviewTable.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ResultsTable",
  components: { ExpertReviewTable },
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan'])
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async initBreadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs;
      breadcrumbs.forEach(item => {
        if(item.link === 'AddEdit1') {
          item.value = this.assessmentPlan.name;
          item.params = {
            uuid: this.$route.params.planUuid
          };
        }

        return item;
      })

     await this.setBreadcrumbs(breadcrumbs)
    }
  },
  mounted() {
    this.fetchPlan({
      uuid: this.$route.params.planUuid,
    })
        .then(() => {
          this.initBreadcrumbs();
        })
  }
}
</script>

<style scoped>

</style>