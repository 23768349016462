import administrators from './administrators';
import administrator from './administrator';
import security from './security';
import branches from './branches';
import branch from './branch';

export default {
	state : {
    ...administrators.state,
    ...administrator.state,
    ...security.state,
  },

	getters : {
    ...administrators.getters,
    ...administrator.getters,
    ...security.getters,
  },

	actions : {
    ...administrators.actions,
    ...administrator.actions,
    ...security.actions,
    ...branches.actions,
    ...branch.actions,
  },

	mutations : {
    ...administrators.mutations,
    ...administrator.mutations,
    ...security.mutations,
    ...branches.mutations,
    ...branch.mutations,
  },
}