<template>
  <div class="footer">
    <div class="footer__copyright">© MainTest {{ new Date().getFullYear() }} Все права защищены</div>
    <router-link
        class="footer__link"
        :to="{
          name: 'Support'
        }"
    >
      Связаться с поддержкой
    </router-link>

    <div @click="getUserGuide();" class="link_user_guide">
      Руководство пользователя
    </div>
  </div>
</template>

<script>
import api from "@/network";

export default {
  name: "AppFooter",
  props: {
    user_role: String
  },
  methods: {

    getUserGuide() {
      api.get(`user_guide`, {
        responseType: 'blob',
        params: {
          role: this.user_role
        },
      })
          .then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            let fileName = `Инструкция.pdf`;

            switch (this.user_role) {

              case 'admin':
                fileName = `Инструкции по работе в вэб-кабинете и быстрому запуску тестирования Maintest.pdf`;
                break;
              case 'expert':
                fileName = `Инструкция для эксперта.pdf`;
                break;
              case 'respondent':
                fileName = `Инструкция для респондента.pdf`;
                break;
            }

            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
          })
    }
  }
}
</script>

<style lang="scss" src="./style.scss"/>
<style lang="scss">
  .link_user_guide {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #00788C;
    margin-left: 15px;
    cursor: pointer;
}
</style>
