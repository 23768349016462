import api from "@/network";

export default {
  namespaced: true,

  state: {
    settings: [],
  },

  getters: {
    settings(state) {
      return state.settings;
    }
  },

  actions: {
    /* FETCHERS */
    async fetchTestSettings({ }, { uuidTest, }) {
      return await api.get(`tests/${uuidTest}/settings`);
    },

    /* API ACTIONS */
    async saveExportExcelSettings({ }, { uuidTest, uuidSettings, payload, }) {
      return await api.put(
          `tests/${uuidTest}/settings/${uuidSettings}`,
          payload
      );
    },

    /* LOCAL ACTIONS */
    async setTestsSettingsStore({ commit, getters, }, payload = null) {
      if (!payload) return;

      commit('updateTestSettings', {
        ...getters.settings,
        ...payload,
      });
    },
  },

  mutations: {
    async updateTestSettings(state, settings) {
      state.settings = settings;
    },
  },
}