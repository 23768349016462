<template>
  <div class="tests-library-settings-default-report">
    <div class="tests-library-settings-default-report__title">Отчет по умолчанию:</div>
    <div class="tests-library-settings-default-report__option">
      <div class="tests-library-settings-default-report__option--title">Предъявлять отчёт респонденту</div>
      <mt-switch
          hide-details
          class="tests-library-settings-default-report__option--action"
          theme="solid"
          v-model="testSettings.submitReportToRespondent"
      />
    </div>
    <div class="tests-library-settings-default-report__reports">
      <div class="tests-library-settings-default-report__report">
        <mt-checkbox
            class="tests-library-settings-default-report__report--checkbox"
            v-model="all"
            :multiple="multiple"
            @click="selectAll"
        />
        <div
            class="tests-library-settings-default-report__report--title"
            @click="selectAll"
        >
          Предъявлять все
        </div>
      </div>
      <div
          class="tests-library-settings-default-report__report"
          v-for="report in testSettings?.reports"
          :key="report.uuid"
      >
        <mt-checkbox
            class="tests-library-settings-default-report__report--checkbox"
            v-model="report.status"
            @click="selectReport"
        />
        <div
            class="tests-library-settings-default-report__report--title"
            @click="selectReport({ report, })"
        >
          {{ report.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mtSwitch from "@/components/UI/mtSwitch/index.vue";
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue'

export default {
  components: {
    mtSwitch,
    mtCheckbox,
  },

  props: {},
  data: () => ({
    all: false,
    multiple: false,
  }),
  computed: {
    testSettings() {
      return this.$store.getters['test/settings'];
    },
    reports() {
      return this.testSettings.reports;
    },
    selectedReports() {
      return this.testSettings
          .reports
          .filter(report => report.status)
          .length;
    }
  },

  watch: {
    all(newAll) {
      if (this.multiple) {
        return;
      }

      this.testSettings.reports.forEach(report => report.status = newAll);
    },
    reports() {
      this.checkSelectedReports();
    }
  },
  methods: {
    /* GETTERS */
    /* SETTERS */
    /* HANDLERS */
    selectAll() {
      if (this.multiple) {
        this.all = false;
        this.multiple = false;

        this.testSettings.reports.forEach(report => report.status = false);

        return;
      }
    },
    selectReport({report,}) {
      if (report) report.status = !report.status;

      this.checkSelectedReports();
    },

    /* HELPERS */
    checkSelectedReports() {
      const TOTAL_COUNT = this.reports.length;
      const SELECTED_COUNT = this.selectedReports;

      if (SELECTED_COUNT === TOTAL_COUNT) {
        this.all = true;
        this.multiple = false;
      }
      if (SELECTED_COUNT < TOTAL_COUNT && SELECTED_COUNT) {
        this.all = false;
        this.multiple = true;
      }
      if (!SELECTED_COUNT) {
        this.multiple = false;
        this.all = false;
      }
    },
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.tests-library-settings-default-report {
  font-family: 'Mulish';
  font-style: normal;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__option {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1.5px solid #E7E8F0;
    border-radius: 8px;
    padding: 12px 15px;
    margin-top: 15px;


    &--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }

    &--action {
    }
  }

  &__reports {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    border: 1.5px solid #E7E8F0;
    border-radius: 8px;
    padding: 11px 16px;
    margin-top: 10px;
  }

  &__report {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;

    &:first-child {
      margin-left: 0;
    }

    &--checkbox {
      min-width: 20px;
    }

    &--title {
      margin-left: 15px;
      user-select: none;
    }
  }
}
</style>