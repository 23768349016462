<template>
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20833 8.95521L11.0078 5.15571L12.0094 6.15729L6.5 11.6667L0.990585 6.15729L1.99217 5.15571L5.79167 8.95521V0.333374H7.20833V8.95521Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "AlignBottom",
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>