<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50008 14.5833C3.58796 14.5833 0.416748 11.4121 0.416748 7.49996C0.416748 3.58783 3.58796 0.416626 7.50008 0.416626C11.4122 0.416626 14.5834 3.58783 14.5834 7.49996C14.5834 11.4121 11.4122 14.5833 7.50008 14.5833ZM10.9142 11.0955C11.7693 10.285 12.3078 9.19664 12.4334 8.02526C12.559 6.85389 12.2633 5.67607 11.5995 4.70283C10.9357 3.72958 9.94701 3.02449 8.81061 2.71388C7.6742 2.40327 6.46434 2.50744 5.39775 3.00771L6.08837 4.25083C6.62736 4.01658 7.21617 3.92013 7.80173 3.97019C8.38729 4.02024 8.95119 4.21523 9.4426 4.53756C9.93401 4.8599 10.3375 5.29946 10.6167 5.81661C10.8958 6.33377 11.0419 6.91227 11.0417 7.49996H8.91675L10.9142 11.0955ZM9.60242 11.9922L8.91179 10.7491C8.3728 10.9833 7.78399 11.0798 7.19843 11.0297C6.61287 10.9797 6.04897 10.7847 5.55756 10.4624C5.06615 10.14 4.66268 9.70046 4.38351 9.18331C4.10434 8.66615 3.95825 8.08765 3.95842 7.49996H6.08342L4.08591 3.90446C3.23088 4.71489 2.69234 5.80328 2.56677 6.97466C2.4412 8.14603 2.73681 9.32384 3.40065 10.2971C4.06448 11.2703 5.05316 11.9754 6.18956 12.286C7.32596 12.5966 8.53583 12.4925 9.60242 11.9922Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>