<template>
  <div
      class="mt-textarea"
      :class="[{ 'mt-textarea_focused': focused || hasValue },{ 'mt-textarea_invalid': error },{ 'mt-textarea_center': center}, `mt-textarea_theme-${theme}`, { 'mt-textarea_error': hasError} ]"
      @click="setFocus"
      :style="[{'min-height': `${minHeight}px`}]"
  >
    <div
        class="mt-textarea__label"
        :class="[{'mt-textarea__label_with-left': !!$slots.left || false}]"
        v-if="!!placeholder"
    >
      {{ placeholder }}
    </div>
    <textarea
        v-model="bufferValue"
        @focus="$emit('focus'); touched = true; focused = true"
        @blur="$emit('blur'); focused = false"
        ref="ipt"
        @input="$emit('input')"
        :disabled="disabled"
    />
    <div
        class="mt-textarea__switch"
        @click.stop="showPassword = !showPassword"
        v-if="type === 'password'"
    >
      <img
          src="@/assets/img/svg/eye-close.svg"
          v-if="showPassword"
      />
      <img
          src="@/assets/img/svg/eye-open.svg"
          v-else
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'mtTextarea',
    props: {
      type: {
        type: String,
        default: () => "text"
      },
      error: {
        type: Boolean,
        default: false
      },
      modelValue: {
        type: [String, Number],
        default: null
      },
      minHeight: {
        type: Number,
        default: 100
      },
      placeholder: {
        type: String,
        default: null
      },
      theme: {
        type: String,
        default: () => "default"
      },
      center: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      hasError: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    created () {
      this.bufferValue = this.modelValue
    },

    mounted () {
      if (this.autofocus) this.$nextTick(() => {
        this.setFocus()
      })
    },

    data () {
      return {
        bufferValue: this.modelValue,
        focused: false,
        touched: false,
        showPassword: false
      }
    },

    computed : {
      hasValue () {
        return !!this.modelValue
      }
    },

    methods : {
      setFocus () {
        this.$refs.ipt.focus()
      },
      setTouched(value) {
        this.touched = value;
      }
    },
    watch: {
      bufferValue (newVal, oldVal) {
        if (newVal !== oldVal) this.$emit('update:modelValue', this.bufferValue)
      },
      modelValue (newVal, oldVal) {
        if (newVal !== oldVal) this.bufferValue = newVal
      }
    }
  }
</script>

<style lang="scss" scoped>
.mt-textarea {
  flex-grow: 1;
  max-width: 100%;
  border: 1px solid #C2C8D0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  cursor: text;
  position: relative;
  padding: 15px;
  transition: all .2s ease-in-out;

  &_center {
    & input {
      text-align: center;
    }
  }
  & input, textarea {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #7C869D;
    border: 0;
    border: none;
    resize: none;
    width: 100%;
    min-width: 10px;
    background-color: transparent;
    box-sizing: border-box;
    height: 100%;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__label {
    position: absolute;
    background-color: #fff;
    padding: 5px;
    color: #858585;
    transition: all .2s ease-in-out;
    &_with-left {
      left: 40px;
    }
  }
  &_focused {
    border: 1px solid #b4b4b4;
    box-shadow: inset 0px 0px 0px 1px #00788C;
    & > .mt-textarea__label {
      transform: translate(-3px, -29px);
      font-size: 12px;
      color: #00788C;
    }
    & .mt-textarea__label_with-left {
      left: 15px;
    }
  }
  &_invalid {
    border: 1px solid #C24020;
    box-shadow: inset 0px 0px 0px 1px #C24020;

    & > .mt-textarea__label {
      color: #C24020;
    }
  }
  &__switch {
    cursor: pointer;
    & img {
      width: 20px;
      height: 20px;
    }
  }

  &_theme-solid {
    background-color: #f0f1f7;
    border: 0px;
    &.mt-textarea {
      &_focused {
        border: 0px;
        box-shadow: inset 0px 0px 0px 0px #00788C;
      }
    }
  }
  &_error {
    background-color: rgba(255, 0, 0, .3);
  }
}

</style>