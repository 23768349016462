<template>
  <div class="progress-bar">
    <div class="progress-bar-value" />
  </div>
</template>

<script>
export default {
  name: 'mtLoadingAnimationLinear'
}
</script>
<style lang="scss" src="./mtLoadingAnimationLinear.scss" />