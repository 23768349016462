<template>
  <mt-dialog
      :opened-by-default="true"
      size="md"
      class="custom-assessment"
      @close="onClose()"
  >
    <custom-assessment-preloader v-if="!isLoaded" />
    <template v-else>
      <mt-expander class="custom-assessment__expander">
        <mt-expander-item :disabled="expRespTask.materials.length === 0 && !expRespTask.description">
          <template #header>
            <h3 class="custom-assessment__expander__title">{{ expRespTask.name }} </h3>
          </template>
          <template #content>
            <p
                class="custom-assessment__expander__text"
                v-show="expRespTask.description"
            >
              {{ expRespTask.description }}
            </p>
            <template v-if="expRespTask.materials.length">
              <h2 class="custom-assessment__expander__file-title">Файлы</h2>
              <div class="card mt-2">
                <file-download-card :links="expRespTask.materials" />
              </div>
            </template>
          </template>
        </mt-expander-item>
      </mt-expander>
      <custom-assessment-chat />
    </template>
  </mt-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import CustomAssessmentChat
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentChat.vue";
import MtExpander from "@/components/UI/mtExpander/mtExpander.vue";
import fileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import MtExpanderItem from "@/components/UI/mtExpander/mtExpanderItem.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import AppPlaceholder from "@/components/UI/AppPlaceholder/AppPlaceholder.vue";
import CustomAssessmentPreloader
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentPreloader.vue";

export default {
  name: "RespondentCustomAssessment",
  components: {
    CustomAssessmentPreloader,
    AppPlaceholder,
    MtButton,
    AppTooltip,
    MtExpanderItem,
    ChevronLeftIcon,
    fileDownloadCard,
    MtExpander,
    CustomAssessmentChat,
    MtDialog
  },
  data: () => ({
    isLoaded: false,
  }),
  computed: {
    ...mapState('assessmentPlans/plan/task', ['expRespTask']),
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['startCustomAssessmentSession', 'fetchExpRespTask']),

    onClose() {
      this.$router.replace({
        name: 'ExpRespAssessmentPlanSingle',
        params: {
          uuid: this.$route.params.planUuid
        }
      })
    },

    async startSession() {
      return await this.startCustomAssessmentSession({
        planUuid: this.$route.params.planUuid,
        taskUuid: this.$route.params.taskUuid,
      });
    },

    async getTask() {
      return await this.fetchExpRespTask({
        uuidPlan: this.$route.params.planUuid,
        uuid: this.$route.params.taskUuid
      })
    }
  },
  mounted() {
    Promise.all([
      this.getTask(),
      this.startSession()
    ]).then(() => {
      this.isLoaded = true;
    });
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>