<template>
  <template
      v-for="(field, index) in form"
      :key="field.uuid"
  >
    <app-select
        half
        :label="field.name"
        v-if="field.type === 'list'"
        v-model="field.value"
        :items="field.variants"
        :errors="vuelidate?.formSettings.$each.$response?.$errors[index].value"
    />

    <div
        v-else-if="field.type === 'radio'"
        class="row"
    >
      <mt-radio-group
          v-model="field.value"
          :required="field.required"
          inline
          half
          return-object
          :hasError="!!additionalFieldsErrorMessages[field.name]"
          @focus="additionalFieldsErrorMessages[field.name] = false"
      >
        <mt-radio
            v-for="gender in field.variants"
            :key="gender.slug"
            :label="gender.name"
            :value="gender.slug"
        />
      </mt-radio-group>
    </div>

    <app-input
        v-else
        half
        :label="field.name"
        v-model="field.value"
        :errors="vuelidate?.formSettings.$each.$response?.$errors[index].value"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import MtRadioGroup from "@/components/UI/mtRadio/mtRadioGroup.vue";
import MtRadio from "@/components/UI/mtRadio/mtRadio.vue";

export default defineComponent({
  name: "DynamicFormBuilder",
  components: { MtRadio, MtRadioGroup, AppSelect, AppInput },
  props: {
    form: {
      type: Array,
      default: () => []
    },
    vuelidate: Object
  }
})
</script>

<style
    scoped
    lang="scss"
>

</style>