<script lang="ts">
import { defineComponent } from 'vue'
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import SortAscendingIcon from "@/components/icons/SortAscendingIcon.vue";
import SortDescendingIcon from "@/components/icons/SortDescendingIcon.vue";

export default defineComponent({
  name: "SortToggler",
  components: { AppTooltip },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        field: '',
        order: 'asc'
      })
    },
    sortType: {
      type: String,
      default: 'asc'
    },
    stub: {
      type: String
    }
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    SortDescendingIcon() {
      return SortDescendingIcon
    },
    SortAscendingIcon() {
      return SortAscendingIcon
    }
  },
  methods: {
    toggleSortType() {
      this.computedModelValue = {
        field: this.stub,
        order: this.computedModelValue.order === 'asc' ? 'desc' : 'asc'
      }
    }
  }
})
</script>

<template>
  <app-tooltip>
    <template #activator>
      <button
          @click="toggleSortType"
          class="sort-toggler"
      >
        <component
            :is="sortType === 'asc' ? SortAscendingIcon : SortDescendingIcon"
            class="sort-toggler__icon"
        />
      </button>
    </template>
    {{ sortType === 'asc' ? 'По возрастанию' : 'По убыванию' }}
  </app-tooltip>
</template>
<style lang="scss">
.sort-toggler {
  margin-left: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  transition: transform 0.3s;
  color: $green;
  flex-shrink: 0;
  &__icon {
    height: 100%;
    width: 100%;
  }
}
</style>