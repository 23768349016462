<script setup lang="ts">
import AppPlaceholder from '../AppPlaceholder/AppPlaceholder.vue';
import {useSlots} from "vue";
const slots = useSlots(['actions', 'header', 'body', 'footer']);
const props = defineProps({
  headers: {
    type: Array,
    default: [],
  },
  loading: {
    type: Boolean,
    default: false
  }
});

</script>

<template>
  <div class="mt-table">
    <div class="mt-table__actions">
      <slot name="actions" />
    </div>
    <div class="mt-table__wrapper">
      <table class="mt-table__table">
        <thead>
          <slot name="header" />
        </thead>
        <tbody>
          <slot name="body" />
          <template v-if="props.loading">
            <tr v-for="index in 5">
              <td
                v-for="(column, index) in props.headers"
                :key="index"
              >
                <app-placeholder
                  class="mt-data-table__placeholder"
                  height="32px"
                />
              </td>
            </tr>
          </template>
        </tbody>

        <slot name="table-insert" />
      </table>
      <slot />
    </div>
    <div v-if="$slots.footer" class="mt-table__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" src="./mtTable.scss" />
