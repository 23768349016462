<template>
  <app-form-block
      :active="isInputActive"
      :errors="errors"
      :hide-details="hideDetails"
  >
    <template #label>
      <slot name="label" />
    </template>

    <div
        class="custom-textarea"
        @click="$refs.textarea.focus()"
    >
    <textarea
        ref="textarea"
        v-model="computedModelValue"
        :maxlength="maxLength"
        :placeholder="placeholder"
        @keydown="keypress"
        @focus="focusIn()"
        @blur="focusOut()"
    />
      <div
          class="custom-textarea__length"
          v-if="maxLength"
      >
        {{ (computedModelValue ?? "").length }} / {{ maxLength }}
      </div>
    </div>

    <slot />
  </app-form-block>
</template>

<script>
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";

export default {
  name: 'AppTextarea',
  components: {AppFormBlock},
  props: {
    modelValue: String,
    maxLength: {
      type: Number,
    },
    maxHeight: {
      type: Number,
      default: 250
    },
    placeholder: {
      type: String
    },
    errors: {
      type: [Array, Boolean],
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    focused: false,
    touched: false,
    isInputActive: false,
    initialHeight: null
  }),
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      }
    }
  },
  methods: {
    calculateHeight() {
      const textarea = this.$refs.textarea;

      if (!this.initialHeight) {
        this.initialHeight = textarea.clientHeight || 50;
      }

      textarea.style.height = this.initialHeight + 'px';
      const newHeight = textarea.scrollHeight;

      if (newHeight > this.initialHeight && newHeight <= 100) {
        textarea.style.height = newHeight + 'px';
      } else if (newHeight > 100) {
        textarea.style.height = '100px'; // Максимальная высота
      } else {
        textarea.style.height = this.initialHeight + 'px'; // Минимальная высота
      }
    },

    focusIn() {
      this.isInputActive = true
    },
    focusOut() {
      if (this.computedModelValue) return;

      this.isInputActive = false
    },

    keypress(e) {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13 && !e.shiftKey && !e.metaKey) {
        e.preventDefault()
        this.$emit('submit')
      } else if (keyCode === 13 && e.metaKey) {
        this.computedModelValue += '\n'
      }
    }
  },
  mounted() {
    this.isInputActive = !!this.computedModelValue;
    this.calculateHeight();
  },
  watch: {
    computedModelValue() {
      this.calculateHeight();
      this.isInputActive = !!this.computedModelValue
    }
  }
}

</script>

<style
    lang="scss"
    src="./AppTextarea.scss"
/>