<template>
  <assessmentPlansRows :rows="rows">
    <template #item-statistics="assessmentPlan">
      <statisticsCircle
          class="assessment-plans-card__progress"
          v-model="assessmentPlan.score"
          :max="assessmentPlan.totalScore"
          radius="16"
          stroke="5"
      />
    </template>
  </assessmentPlansRows>
</template>

<script>
import assessmentPlansRows from '@/components/workspace/assessmentPlans/plans/rows/index.vue';
import StatisticsCircle from '@/components/workspace/assessmentPlans/plans/statisticsCircle/index.vue';

export default {
  components: {StatisticsCircle, assessmentPlansRows},
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  }
}
</script>