<template>
  <div
      ref="showMoreText"
      class="show-more-text"
  >
    <div
        class="show-more-text__content"
        :style="computedStyleForContent"
        ref="content"
    >
      <slot />
    </div>
    <div
        class="show-more-text-full"
        v-if="contentNotShowingFully"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "mtShowMoreTextDesktop",
  props: {
    height: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      dataHeight: 0,
      scrollHeight: 0,
      contentNotShowingFully: false,
    }
  },
  computed: {
    computedStyleForContent() {
      return {
        height: this.height
      }
    }
  },
  mounted () {
    const content = this.$refs.content;
    const dataHeight = content.clientHeight;
    const scrollHeight = content.scrollHeight;

    if(scrollHeight > dataHeight) {
      this.$refs.showMoreText.classList.add('show-more-text_hoverable');
      this.contentNotShowingFully = true;
    }
  }
}
</script>

<style lang="scss" src="./style.scss"/>