export function generateId(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(
        Math.random() * charactersLength
      )
    );
  }

  return result;
}
export function roundNumber (num, dp = 2) {
  if (!num) return 0;
  // Convert the number to a string with the specified number of decimal places
  let roundedNum = num.toFixed(dp);
  // Convert the fixed-point notation string to a number, removing trailing zeros
  roundedNum = Number.parseFloat(roundedNum);
  // Return the rounded number
  return roundedNum;
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}