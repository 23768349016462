<template>
  <div>
    <mt-show-more-text-desktop
        v-if="isDesktop"
        :height="height"
    >
      <slot/>
    </mt-show-more-text-desktop>
    <mt-show-more-text-mobile
        v-if="isMobile"
        :height="height"
    >
      <slot/>
    </mt-show-more-text-mobile>
  </div>
</template>
<script>
import MtShowMoreTextDesktop from '@/components/UI/mtShowMoreText/desktop.vue';
import MtShowMoreTextMobile from '@/components/UI/mtShowMoreText/mobile.vue';

export default {
  name: "mtShowMoreText",
  components: { MtShowMoreTextMobile, MtShowMoreTextDesktop },
  props: {
    height: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      dataHeight: 0,
      scrollHeight: 0,
      contentNotShowingFully: false,
    }
  },
  computed: {
    computedStyleForContent() {
      return {
        height: this.height
      }
    },
    isMobile () {
      return window.screen.availWidth < 575
    },
    isDesktop () {
      return !this.isMobile
    }
  },
}
</script>

<style lang="scss" src="./style.scss"/>