import TaskTypeEnum from "@/enums/task-type.enum.ts";

type TaskType = {
    value: TaskTypeEnum,
    title: string
}

const taskTypes: TaskType[] = [
    {
        value: TaskTypeEnum.TEST,
        title: 'Тест (библиотека)'
    },
    {
        value: TaskTypeEnum.TEST_PRO,
        title: 'Тест (собственные)'
    },
    {
        value: TaskTypeEnum.EXPERT,
        title: 'Экспертная оценка'
    },
]

export default taskTypes