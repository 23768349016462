<template>
  <div class="change-password">
    <div class="change-password__row">
      <form action="javascript:void(0);" autocomplete="off" class="change-password__form">
        <app-input-password
          autocomplete="password"
          v-model="payload.oldPassword"
          label="Старый пароль"
          :errors="v$.payload.oldPassword.$errors"
        />

        <app-input-password
          v-model="payload.newPassword"
          label="Новый пароль"
          autocomplete="new-password"
          :errors="v$.payload.newPassword.$errors"
          @input="v$.payload.newPassword.$validate()"
        />

        <app-input-password
          v-model="payload.confirmPassword"
          label="Подтвердите новый пароль"
          autocomplete="new-password"
          :errors="v$.payload.confirmPassword.$errors"
        />

        <mt-button
          :loading="requestPending"
          @click="submit"
          size="large"
        >
          Изменить
        </mt-button>
      </form>
      <password-rules
        ref="passwordRules"
        class="change-password__rules"
        :password-for-check="payload.newPassword"
      />
    </div>
  </div>
</template>

<script>
import mtButton from '@/components/UI/mtButton/index.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, sameAs } from '@vuelidate/validators'
import AuthApi from '@/api/auth.api.ts'
import AppInputPassword from '@/components/UI/AppInputPassword.vue'
import { useValidations } from '@/plugins/vuelidate-wrapper'
import PasswordRules from "@/components/workspace/auth/PasswordRules/index.vue";

export default {
  name: 'ChangePassword',
  components: {PasswordRules, AppInputPassword, AppInput, mtButton },
  props: {},
  data: () => ({
    requestPending: false,
    payload: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
  }),
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
      }),
    }
  },
  validations() {
    const { required } = useValidations()
    return {
      payload: {
        oldPassword: { required },
        newPassword: {
          required,
          isValid: helpers.withMessage(
            'Пожалуйста, учтите все правила создания пароля',
            (value) => this.$refs.passwordRules.allRulesAreValid,
          ),
        },
        confirmPassword: {
          required,
          sameAs: helpers.withMessage(
            'Пароль не совпадают',
            sameAs(this.payload.newPassword),
          ),
        },
      },
    }
  },
  computed: {},
  methods: {
    async submit() {
      const isValidated = await this.v$.$validate()
      if (!isValidated) return

      this.requestPending = true
      this.changePassword()
          .finally(() => {
            this.requestPending = false
            this.$toast.success('Пароль успешно изменен')
            this.payload = {
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }
            this.v$.$reset()
          })
    },

    async changePassword() {
      return await AuthApi.changePassword(this.payload)
    },
  },
}
</script>

<style lang="scss">
.change-password {
  &__row {
    display: flex;
  }
  &__form {
    flex-grow: 1;
    padding-right: 30px;
  }
  .mt-button {
    width: 100%;
  }
  &__rules {
    height: fit-content;
    margin: 95px 0 0;
    border-radius: 5px;
  }
}
</style>
