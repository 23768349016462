<template>
  <div class="statistics-amount">
    <mtSvg
        class="statistics-amount__icon"
        name="team-line"
    />
    <div class="statistics-amount__text">
      <h4 class="statistics-amount__title">Участников:</h4>
      <p>{{ amount }}</p>
    </div>
  </div>
</template>

<script>
import mtSvg from '@/components/UI/mtSvg/index.vue';

export default {
  name: "statisticsAmount",
  components: {mtSvg},
  props: {
    amount: [Number, String]
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>