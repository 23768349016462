<template>
  <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M13 0.999999L7.07071 6.92929C7.03166 6.96834 6.96834 6.96834 6.92929 6.92929L1 1"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000"
    },
  },
}
</script>