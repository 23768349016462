<template>
  <mtExpanderItem :disabled="tests?.length === 0">
    <template #header>
      <h3 class="assessment-plan__expander__title">{{ title }}</h3>
    </template>
    <template #content>
      <div class="assessment-plan-cards-row">
        <test-card-skeleton-loader v-for="i in 5" v-if="loading" />
        <test-card v-for="test in tests" :key="test.uuid" :test="test" :show_progress="show_progress" v-else/>
      </div>
      <alert
        v-if="tests?.length === 0"
        type="info"
        class="assessment-plan-alert"
      >
        Нет заданий
      </alert>
    </template>
  </mtExpanderItem>
</template>

<script>
import mtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue'
import testCard from '@/components/workspace/assessmentPlans/plans/plan/test/card/index.vue'
import Alert from '@/components/UI/AppAlert/AppAlert.vue'
import TestCardSkeletonLoader from '@/components/workspace/assessmentPlans/plans/card/CardSkeletonLoader.vue'

export default {
  name: 'testPlanRow',
  components: { Alert, mtExpanderItem, testCard, TestCardSkeletonLoader },
  props: {
    title: String,
    tests: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    show_progress: Boolean,
  },
}
</script>

<style scoped></style>
