/* ENTITIES */
import list from './list';
import item from './item';

/* MODULES */
import task from './task';

export default {
  namespaced: true,

	state : {
    ...list.state,
    ...item.state,
  },
	getters : {
    ...list.getters,
    ...item.getters,
  },
	actions : {
    ...list.actions,
    ...item.actions,
  },
	mutations : {
    ...list.mutations,
    ...item.mutations,
  },

  modules: {
    task,
  },
}