<template>
  <div class="error-message">{{ errorMessage }}</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      errorMessage: null
    };
  },

  methods: {
    ...mapActions(['impersonate', 'getAuthUser'])
  },

  async mounted() {
    this.impersonate({ code: this.$route.params.code })
        .then((response) => {
          this.getAuthUser()
              .then(() => {
                this.$router.push('/');
              });
        })
        .catch(error => {
          this.errorMessage = error.data.message;
        });


  },

  render() {}
};
</script>

<style scoped>
.error-message {
  display: grid;
  height: 100vh;
  place-items: center;
  color: red;
}
</style>
