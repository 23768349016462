<script
    setup
    lang="ts"
>
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import { Ref } from "vue";
import CloseIcon from "@/components/UI/mtSvg/svg/CloseIcon.vue";

type PropsType = {
  modelValue: string | undefined;
  placeholder?: string;
  isDebounce?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  placeholder: "Поиск",
  isDebounce: true,
});

const emit = defineEmits(["update:modelValue", "search"]);

const searchValue: Ref<string> = ref("");

const computedModelValue = computed({
  get: () => props.modelValue,
  set: (newValue: string) => {
    searchValue.value = newValue;

    if (!props.isDebounce) {
      return emit("update:modelValue", newValue);
    }

    debounceSearch();
  },
});

const timeout = ref<number | null>(null);

function debounceSearch() {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  timeout.value = window.setTimeout(() => {
    emit("update:modelValue", searchValue.value);
  }, 500);
}

function immediateSearch() {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  emit("update:modelValue", searchValue.value);
}

function clear() {
  searchValue.value = "";
  immediateSearch();
}
</script>

<template>
  <app-input
      v-model="computedModelValue"
      :placeholder="placeholder"
      @keyup.enter="immediateSearch"
  >
    <template #append>
      <button
          v-if="computedModelValue"
          class="app-input-search__clear-btn"
          @click="clear"
      >
        <close-icon />
      </button>

      <mt-button
          icon
          color="green"
          @click.stop="immediateSearch"
      >
        <search-icon />
      </mt-button>
    </template>
  </app-input>
</template>

<style lang="scss">
.app-input-search {
  &__clear-btn {
    display: flex;
    margin-right: 10px;
  }
}
</style>