<template>
  <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M15.2316 9.86558L13.9004 9.09683C14.0348 8.37183 14.0348 7.62808 13.9004 6.90308L15.2316 6.13433C15.3848 6.04683 15.4535 5.86558 15.4035 5.69683C15.0566 4.58433 14.466 3.57808 13.6941 2.74058C13.5754 2.61246 13.3816 2.58121 13.2316 2.66871L11.9004 3.43746C11.341 2.95621 10.6973 2.58433 10.0004 2.34058V0.806206C10.0004 0.631206 9.87851 0.478081 9.70663 0.440581C8.55976 0.184331 7.38476 0.196831 6.29413 0.440581C6.12226 0.478081 6.00038 0.631206 6.00038 0.806206V2.34371C5.30663 2.59058 4.66288 2.96246 4.10038 3.44058L2.77226 2.67183C2.61914 2.58433 2.42851 2.61246 2.30976 2.74371C1.53789 3.57808 0.947261 4.58433 0.600387 5.69996C0.547262 5.86871 0.619137 6.04996 0.772261 6.13746L2.10351 6.90621C1.96914 7.63121 1.96914 8.37496 2.10351 9.09996L0.772261 9.86871C0.619137 9.95621 0.550387 10.1375 0.600387 10.3062C0.947261 11.4187 1.53789 12.425 2.30976 13.2625C2.42851 13.3906 2.62226 13.4218 2.77226 13.3343L4.10351 12.5656C4.66288 13.0468 5.30663 13.4187 6.00351 13.6625V15.2C6.00351 15.375 6.12538 15.5281 6.29726 15.5656C7.44413 15.8218 8.61913 15.8093 9.70976 15.5656C9.88163 15.5281 10.0035 15.375 10.0035 15.2V13.6625C10.6973 13.4156 11.341 13.0437 11.9035 12.5656L13.2348 13.3343C13.3879 13.4218 13.5785 13.3937 13.6973 13.2625C14.4691 12.4281 15.0598 11.4218 15.4066 10.3062C15.4535 10.1343 15.3848 9.95308 15.2316 9.86558ZM8.00038 10.5C6.62226 10.5 5.50038 9.37808 5.50038 7.99996C5.50038 6.62183 6.62226 5.49996 8.00038 5.49996C9.37851 5.49996 10.5004 6.62183 10.5004 7.99996C10.5004 9.37808 9.37851 10.5 8.00038 10.5Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>