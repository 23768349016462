<template>
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
    <path d="M18.5837 6.58325H24.0003V8.74992H18.5837V6.58325ZM16.417 11.9999H24.0003V14.1666H16.417V11.9999ZM19.667 17.4166H24.0003V19.5833H19.667V17.4166ZM0.166992 22.8333C0.166992 20.5347 1.08008 18.3303 2.7054 16.705C4.33072 15.0797 6.53512 14.1666 8.83366 14.1666C11.1322 14.1666 13.3366 15.0797 14.9619 16.705C16.5872 18.3303 17.5003 20.5347 17.5003 22.8333H15.3337C15.3337 21.1093 14.6488 19.456 13.4299 18.2371C12.2109 17.0181 10.5576 16.3333 8.83366 16.3333C7.10975 16.3333 5.45645 17.0181 4.23747 18.2371C3.01848 19.456 2.33366 21.1093 2.33366 22.8333H0.166992ZM8.83366 13.0833C5.24241 13.0833 2.33366 10.1745 2.33366 6.58325C2.33366 2.992 5.24241 0.083252 8.83366 0.083252C12.4249 0.083252 15.3337 2.992 15.3337 6.58325C15.3337 10.1745 12.4249 13.0833 8.83366 13.0833ZM8.83366 10.9166C11.2278 10.9166 13.167 8.97742 13.167 6.58325C13.167 4.18909 11.2278 2.24992 8.83366 2.24992C6.43949 2.24992 4.50033 4.18909 4.50033 6.58325C4.50033 8.97742 6.43949 10.9166 8.83366 10.9166Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  name: "UserEdit",
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>

</style>