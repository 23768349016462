<script setup lang="ts">
import MtFileView from '@/components/UI/mtFileView.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import TemporaryFilesApi from '@/api/temporary-files.api.js'
import { VNodeRef } from 'vue'
import { generateRandomID } from '@/services/utils.js'
import useToast from '@/components/UI/AppToast/useToast.js'

interface Props {
  label: string
  modelValue: any[]
}
const { toast } = useToast()
const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
})

const emit = defineEmits(['delete', 'add', 'update:modelValue'])

const controller = ref(null)
const mtFilesField: any = ref<VNodeRef>()

const files = computed({
  get: () => props.modelValue,
  set: (value) => {
    const successfullyUploadedFiles = value.filter((file) => !file.isError)

    emit('update:modelValue', successfullyUploadedFiles)
  },
})

const triggerInputClick = () => {
  mtFilesField.value.value = null
  mtFilesField.value.click()
}

const deleteFile = (file, index: number) => {
  if (file.isLoading && controller.value) {
    controller.value.abort(); // Отмена текущего запроса
  }

  files.value.splice(index, 1)
}

const submit = () => {
  for (let i = 0; i < mtFilesField.value.files.length; i++) {
    upload(mtFilesField.value.files[i])
  }
}

function upload(file: File) {
  const size = file.size

  if (size > 10 * 1024 * 1024) {
    toast.error('Размер файла не должен превышать 10 МБ')
    return
  }

  const randomId = generateRandomID()
  const fileForUpload = {
    id: randomId,
    name: file.name,
    size: file.size,
    type: file.type,
    file: file,
    isLoading: true,
  }

  files.value.push(fileForUpload)
  const fileForUploadIndex = files.value.findIndex(
    (item) => item.id === randomId,
  )

  controller.value = new AbortController()

  TemporaryFilesApi.upload(file, controller.value.signal)
    .then((newFile) => {
      files.value[fileForUploadIndex] = {
        ...newFile,
        isLoading: false,
      }
    })
    .catch((error) => {
      if (!files.value[fileForUploadIndex]) return;

      if (error?.config?.signal?.aborted) {
        deleteFile(fileForUpload, fileForUploadIndex)
      } else {
        files.value[fileForUploadIndex] = {
          ...fileForUpload,
          isLoading: false,
          isError: true,
        };
      }
    })
}

onUnmounted(() => {
  if (controller.value) {
    controller.value.abort()
  }
})
</script>

<template>
  <div class="mt-files-field">
    <div class="mt-files-field__label" v-if="!!label">
      {{ label }}
    </div>
    <div class="mt-files-field__container" @click="triggerInputClick()">
      <div class="mt-files-field__files">
        <mt-file-view
          v-for="(file, index) in files"
          :file="file"
          :key="file.name"
          @delete="deleteFile(file, index)"
          @click.stop
        />
      </div>
      <div class="mt-files-field__actions">
        <button class="mt-files-field__actions__btn">
          <upload-icon class="mt-files-field__actions__btn__icon" />
        </button>
      </div>
    </div>
    <input
      type="file"
      style="display: none"
      ref="mtFilesField"
      multiple
      @change="submit()"
    />
  </div>
</template>

<style lang="scss">
.mt-files-field {
  margin-bottom: 30px;
  position: relative;

  &__label {
    color: #7c869d;
    font-size: 12px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    left: 10px;
    top: -13px;
    padding: 3px;
  }

  &__container {
    width: 100%;
    min-height: 92px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    background: #fff;
    border: 1px solid #b4b4b4;
    border-radius: 6px;
  }

  &__files {
    width: calc(100% - 40px);
    overflow: auto;
    padding: 10px;
    border-right: 1px solid #b4b4b4;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  &__actions {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $gray;
    transition: color 0.3s ease-in-out;
    &__btn {
      width: 100%;

      &__icon {
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      color: $dark;
    }
  }
}
</style>
