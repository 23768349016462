<template>
  <div class="tests-library-settings-block-visibility">
    <div class="tests-library-settings-block-visibility__title">Видимость блоков:</div>
    <mtSelect
        class="tests-library-settings-block-visibility__options"
        v-model="report"
        :items="testSettingsReports"
    />
    <div class="tests-library-settings-block-visibility__options-list">
      <draggable
          v-model="report.blocks"
          v-bind="dragOptions"
          item-key="order"
          :component-data="componentDataDraggable"
          handle=".js-drag"
          @end="initBlocksOrder"
      >
        <template #item="{ element }">
          <div
              class="tests-library-settings-block-visibility__options-list--item"
              v-show="element.action !== 'delete'"
          >
            <div class="tests-library-settings-block-visibility__options-list--item-content">
              <svg
                  class="js-drag tests-library-settings-block-visibility__options-list--item-drag-icon"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
                    fill="#C7C7C7"
                />
              </svg>
              <div class="tests-library-settings-block-visibility__options-list--item-title">{{ element.name }}</div>
            </div>
            <div class="tests-library-settings-block-visibility__options-list--item-actions">
              <svg
                  class="tests-library-settings-block-visibility__options-list--item-edit"
                  v-show="!element.required"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="editBlock({ block: element, uuidVariant: report.uuid, })"
              >
                <path
                    d="M3.414 14.0001L13.556 3.85808L12.142 2.44408L2 12.5861V14.0001H3.414ZM4.243 16.0001H0V11.7571L11.435 0.322083C11.6225 0.134612 11.8768 0.0292969 12.142 0.0292969C12.4072 0.0292969 12.6615 0.134612 12.849 0.322083L15.678 3.15108C15.8655 3.33861 15.9708 3.59292 15.9708 3.85808C15.9708 4.12325 15.8655 4.37756 15.678 4.56508L4.243 16.0001V16.0001ZM0 18.0001H18V20.0001H0V18.0001Z"
                    fill="#C5C7CD"
                ></path>
              </svg>
              <svg
                  class="tests-library-settings-block-visibility__options-list--item-delete"
                  v-show="!element.required"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="activateConfirmationDialog({ block: element })"
              >
                <path
                    d="M3 6H5H21"
                    stroke="#C5C7CD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                    stroke="#C5C7CD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M14 11V17"
                    stroke="#C5C7CD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M10 11V17"
                    stroke="#C5C7CD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
              </svg>
              <mt-switch
                  hide-details
                  class="tests-library-settings-block-visibility__options-list--item-status"
                  theme="solid"
                  v-model="element.status"
                  @click="toggleBlock({ block: element, })"
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <mt-button
        class="tests-library-settings-block-visibility__options-add"
        @click="addBlock({ uuidVariant: report.uuid, })"
    >
      <div class="tests-library-settings-block-visibility__options-add--icon"></div>
      <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M15.4821 6.98214H10.3214C10.1538 6.98214 10.0179 6.84622 10.0179 6.67857V1.51786C10.0179 0.679626 9.33823 0 8.5 0C7.66177 0 6.98214 0.679626 6.98214 1.51786V6.67857C6.98214 6.84622 6.84622 6.98214 6.67857 6.98214H1.51786C0.679626 6.98214 0 7.66177 0 8.5C0 9.33823 0.679626 10.0179 1.51786 10.0179H6.67857C6.84622 10.0179 6.98214 10.1538 6.98214 10.3214V15.4821C6.98214 16.3204 7.66177 17 8.5 17C9.33823 17 10.0179 16.3204 10.0179 15.4821V10.3214C10.0179 10.1538 10.1538 10.0179 10.3214 10.0179H15.4821C16.3204 10.0179 17 9.33823 17 8.5C17 7.66177 16.3204 6.98214 15.4821 6.98214Z"
            fill="#00788C"
        ></path>
      </svg>
      <div class="tests-library-settings-block-visibility__options-add--title">Добавить блок</div>
    </mt-button>

    <teleport to="body">
      <confirmation-dialog
          v-model="isConfirmationDialogOpen"
          :title="`Удалить блок отчёта: '${deletingBlock?.name}'`"
          :handler="deleteBlock"
      />
    </teleport>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import mtSelect from '@/components/UI/mtSelectSolid/mtSelectSolid.vue';
import mtSwitch from "@/components/UI/mtSwitch/index.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import ConfirmationDialog from "@/components/composite/confirmationDialog/confirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
    draggable,
    mtSelect,
    mtSwitch,
    mtButton,
  },

  props: {
    reports: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    report: 'Вариант отчета',
    drag: false,
    isConfirmationDialogOpen: false,
    deletingBlock: null
  }),
  computed: {
    testSettingsReports() {
      return this.$store.getters['test/settings']?.reports;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    componentDataDraggable() {
      return {
        tag: 'ul',
        type: 'transition-group',
        name: !this.drag ? 'flip-list' : null,
      }
    },
  },

  watch: {
    testSettingsReports(newReports) {
      this.report = this.testSettingsReports[0] || this.report;
    },
  },
  methods: {
    /* GETTERS */
    /* SETTERS */
    setToUpdate({block,}) {
      if (block.action && block.action !== 'delete') {
        return true;
      } else if (!block.action) {
        return true;
      }

      return false;
    },

    /* HANDLERS */
    initBlocksOrder() {
      if (this.report.blocks) {
        this.report.blocks.forEach(
            (block, index) => {
              block.order = index + 1;

              if (this.setToUpdate({block,})) {
                block.action = 'update';
              }
            }
        );
      }
    },
    addBlock({uuidVariant,}) {
      this.$router.push({
        name: 'TestSettingsCreateBlock',
        params: {
          uuid_variant: uuidVariant,
        },
        query: {
          ...this.$route.query,
          page: this.$route.name,
          blockName: 'Новый дополнительный блок',
        },
      });
    },
    editBlock({block, uuidVariant,}) {
      this.$router.push({
        name: 'TestSettingsEditBlock',
        params: {
          uuid_block: block.uuid,
          uuid_variant: uuidVariant,
        },
        query: {
          ...this.$route.query,
          page: this.$route.name,
          blockName: block.name,
        },
      });
    },

    activateConfirmationDialog ({block}) {
      this.deletingBlock = null
      this.deletingBlock = block
      this.isConfirmationDialogOpen = true
    },

    deleteBlock() {
      this.deletingBlock.action = 'delete';
    },

    toggleBlock({block}) {
      if (this.setToUpdate({block,})) {
        block.action = 'update';
      }
    },

    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.tests-library-settings-block-visibility {
  font-family: 'Mulish';
  font-style: normal;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__options {
    width: 447px;
    margin-top: 15px;

    .mt-solid__wrapper {
      background: #FFFFFF;
      box-shadow: unset;
      border: 1.5px solid #DFE0EB;
      border-radius: 4px;

      .mt-solid__selected {
        & > span {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #000000;
        }
      }
    }
  }

  &__options-list {
    margin-top: 15px;

    &--item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      background: #FFFFFF;
      box-shadow: unset;
      border: 1.5px solid #DFE0EB;
      border-radius: 4px;
      padding: 12px 15px;

      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }

      &-drag-icon {
        cursor: grabbing;
      }

      &-title {
        margin-left: 18px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }

      &-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
      }

      &-edit {
        cursor: pointer;
      }

      &-delete {
        margin-left: 15px;
        margin-right: 15px;
        cursor: pointer;
      }

      &-status {
      }
    }
  }

  &__options-add {
    width: max-content !important;
    height: 33px !important;
    border-radius: 8px !important;
    background: #E6F2F4 !important;
    margin-top: 15px;
    padding: 0 10px;

    &.mt-button_active {
      &:active {
        background: #cde3e7 !important;
      }
    }

    &--title {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #00788C;
      margin-left: 15px;
      white-space: nowrap;
      user-select: none;
    }
  }
}
</style>