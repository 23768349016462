<template>
  <app-form-block
      :half="half"
      active
      class="app-form-block_radio-group"
  >
    <template #label>
      {{ label }}
      <slot name="label"/>
    </template>
    <div
        :class="[
          'mt-radio-group',
          {
            'mt-radio-group--inline': inline
          }
        ]"
        ref="radioGroup"
    >
      <slot>
        <mt-radio
          class="mt-radio-group__item"
          v-for="(item, index) in items"
          :key="index"
          :label="item[itemText]"
          :value="item[itemValue]"
        />
      </slot>
    </div>
  </app-form-block>
</template>

<script>
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";
import { generateRandomID } from "@/services/utils";
import { computed } from "vue";
import MtRadio from "@/components/UI/mtRadio/mtRadio.vue";

export default {
  name: "mtRadioGroup",
  components: {MtRadio, AppFormBlock },
  props: {
    modelValue: [String, Object],
    half: Boolean,
    inline: Boolean,
    returnObject: Boolean,
    items: Array,
    label: String,
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'value'
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    randomId: generateRandomID(),
  }),
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    change({ label, value }) {
      if(this.returnObject) {
        this.computedModelValue = {
          [this.itemText]: label,
          [this.itemValue]: value
        };
      } else {
        this.computedModelValue = value;
      }
    },

    checkOnMount(newValue) {
      [...this.$refs.radioGroup.getElementsByClassName('mt-radio__input')]
          .forEach(item => {
            const value = typeof newValue === 'object' ? newValue?.slug : newValue;
            if(value === item.value) item.checked = true
          });
    }
  },
  mounted() {
    setTimeout(() => {
      [...this.$refs.radioGroup.getElementsByClassName('mt-radio__input')]
          .forEach(item => {
            item.setAttribute('name', this.randomId)
          });
    });

    this.checkOnMount(this.computedModelValue)
  },
  created() {
    this.unwatchModelValue = this.$watch('modelValue', (newVal) => {

      this.checkOnMount(newVal)

      this.unwatchModelValue();
    });
  },
}
</script>

<style
    lang="scss"
    src="./mtRadio.scss"
/>