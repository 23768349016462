<template>
  <button
      :class="[
        'mt-filters__item',
        {
          'mt-filters__item_active': props.active,
        }
      ]"
  >
    <component :is="props.icon" class="mt-filters__item__icon"/>
    <span class="mt-filters__item__label">
      {{ props.label }}
    </span>
  </button>
</template>

<script setup lang="ts">
import {type Component} from "vue";

type Props = {
  active: boolean,
  label: string,
  icon: Component
}

const props = defineProps<Props>()
</script>

<style
    lang="scss"
    src="./mtFilters.scss"
/>