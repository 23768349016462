<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
    <path d="M0.5 0.333374H15.5V2.00004H0.5V0.333374ZM0.5 12.8334H15.5V14.5H0.5V12.8334ZM7.16667 8.66671H15.5V10.3334H7.16667V8.66671ZM7.16667 4.50004H15.5V6.16671H7.16667V4.50004ZM3.83333 7.41671L0.5 10.3334V4.50004L3.83333 7.41671Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  name: "AlignLeft",
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>