import api from "@/network";

export default {
    state: {
        security: [],
    },

    getters: {
        security(state) {
            return state.security;
        },
    },

    actions: {
        async fetchSecurity({ commit }) {
            const response = await api.get(`admin/security`);
            commit('updateSecurity', response?.data?.data);
        },

        async setSecurityItemStatus({ commit, state }, payload = {}) {
            await api.put(
                `admin/security/${ payload.uuid }`,

                {
                    active: payload.status,
                }
            );

            commit(
                'updateSecurity',

                {
                    ...state.security.map(
                        (item) => {
                            if (item.uuid === payload.uuid) {
                                item.active = payload.status;
                            }

                            return item;
                        }
                    ),
                }
            );
        },

        async uploadSecurityMinCount({ commit, state }, payload = {}) {
            await api.put(`admin/security/${ payload.uuid }`, { rule: payload.rule });

            const value = state.security.map((item) => {
                    if (item.uuid === payload.uuid) {
                        item.rule = payload.rule;
                    }

                    return item;
                }
            );
            commit('updateSecurity', value);
        },
    },

    mutations: {
        async updateSecurity(state, security) {
            state.security = security;
        },
    },
}