<template>
  <div class="security-page">
    <app-title>
      <template #title>
        Изменение пароля
      </template>
    </app-title>

    <change-password/>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle/AppTitle.vue";
import ChangePassword from "@/components/workspace/ChangePassword.vue";

export default {
  name: "Security",
  components: { ChangePassword, AppTitle }
}
</script>

<style lang="scss">
.security-page {
  border: 1px solid #dfe0eb;
  padding: 32px;
  max-width: 900px;
  border-radius: 8px;
}
</style>