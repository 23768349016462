import api from "@/network";


export default {

  async setPermanentPassword(payload) {
    return await api.post('auth/set-permanent-password', payload);
  },
  async signIn(payload) {
    return await api.post('auth/login', payload);
  },

  async impersonate(payload) {
    return await api.put('auth/impersonate', payload);
  },

  async selfRegister(payload) {
    return await api.post('/auth/self_registration', payload)
  },
  async forgotPassword(email) {
    return await api.put('/auth/forgot', { email });
  },

  async getUserInfo() {
    return await api.get('user')
  },

  async getSelfRegistrationInfo(payload) {
    return await api.get('auth/self_registration_info', {
      params: payload
      })
  },

  async getAgreements() {
    return await api.get('/user/agreements')
  },

  async putAgreements(payload) {
    return await api.put('/user/agreements', payload)
  },

  async getUserAdditionalForm() {
    return await api.get('/user/form')
  },

  async updateUserInfo(payload) {
    return await api.put('/user/settings', payload)
  },

  async getPasswordRules() {
    return await api.get('/user/security')
  },

  async changePassword(payload) {
    return await api.put('/user/password', payload)
  },

  async checkEmailExisting(payload) {
    return await api.get('/email', {
      params: {
        email: payload
      }
    })
  },


  async getBanners() {
    return await api.get('/admin/banners')
  },

  async readBanner(id) {
    return await api.put('/admin/banners/' + id + '/read')
  },

  async updateUserSettings(payload: {
    firstname: string,
    lastname: string,
    middlename: string | null,
    gender: string,
    formSettings: any,
    personalDataText: string,
    confidentialityText: string
  }) {
    return await api.put('/user/settings', payload)
  },

  async confirmPassword(password: string) {
    return await api.put('auth/confirm', {
      password
    })
  },

  async finishSignUpSetPermanentPassword(password: string) {
    return await api.post('auth/finish-sign-up/permanent-password', {
      password
    })
  },

  async finishSignUpSaveSettings(payload) {
    return await api.post('auth/finish-sign-up/settings', payload)
  }
}

