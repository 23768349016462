<template>
  <div
      :class="['mt-solid', `mt-solid__theme-${theme}`, {
        'mt-solid_opened': showList,
        'mt-solid_error': errorState
      }]"
      v-click-outside="vcoSettings"
  >
    <div class="mt-solid__wrapper">
      <div
          class="mt-solid__selected"
          @click="toggle"
      >
        <span>
          <template v-if="value">{{ value }}</template>
          <span
              v-else-if="placeholder"
              class="mt-solid__selected__placeholder"
          >
            {{ placeholder }}
            <i v-if="required">*</i>
          </span>
        </span>

        <mt-icon
            class="mt-solid__arrow"
            name="arrow-down"
            :fill="!errorState ? '#00788C' : 'rgba(220, 0, 0, .3)'"
        />
      </div>
      <div class="mt-solid__list">
        <div
            class="mt-solid__list-item"
            v-for="(item, index) in items"
            :key="index"
            @click="selectItem(index)"
        >
          {{ item.value || item.name || item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue';

export default {
  name: 'mtSelectSolid',
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    hasError: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  components: {
    mtIcon,
  },
  data() {
    return {
      showList: false,
      errorState: this.hasError,
      vcoSettings: {
        handler: this.hide,
        events: ['mousedown'],
        isActive: true,
        detectIFrame: true,
        capture: false
      },
      focused: false
    }
  },

  computed: {
    value() {
      if (this.modelValue !== null) return this.modelValue.name || this.modelValue
      return this.modelValue
    },
  },

  methods: {
    toggle() {
      this.focused = true
      this.showList = !this.showList
      this.errorState = false
      this.$emit('focus')
    },
    hide() {
      this.showList = false
      if (this.focused) {
        this.focused = false
        this.$emit('blur')
      }
    },
    selectItem(index) {
      this.$emit('update:modelValue', this.items[index])
      this.$emit('change', this.items[index])
      this.hide()
    },
  },
  watch: {
    hasError(newV) {
      this.errorState = newV
    },
  }
}
</script>

<style
    lang="scss"
    src="./mtSelectSolid.scss"
/>