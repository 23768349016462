<template>
  <mt-dialog
      v-model="computedModelValue"
      class="import-dialog"
      size="md"
  >
    <app-title>
      <template #title>Импортировать пользователей</template>
    </app-title>

    <div class="import-dialog__content">
      В таблице должны присутствовать обязательные колонки, имеющие точное название как в образце ниже: <br/>
      <ul>
        <li>
          <b>Email *</b> - обязательное поле, должно содержать валидный email
        </li>
        <li>
          <b>Имя *</b> - обязательное поле, должно содержать только буквы, дефисы
        </li>
        <li>
          <b>Фамилия *</b> - обязательное поле, должно содержать только буквы, дефисы
        </li>
        <li>
          <b>Отчество</b> - необязательное поле, должно содержать только буквы, дефисы
        </li>
        <li>
          <b>Роль *</b> - обязательное поле, варианты значений: <br/>
          <ul>
            <li>Админ</li>
            <li>Проверяющий</li>
            <li>Эксперт</li>
            <li>Участник</li>
            <li>Методолог</li>
            <li>Оператор</li>
            <li>Респондент</li>
          </ul>
        </li>
        <li>
          <b>Дата рождения</b> - необязательное поле, должно содержать дату в формате “dd.mm.yyyy”
        </li>
        <li>
          <b>Пол</b> - необязательное поле, варианты значений: <br/>
          <ul>
            <li>Мужской</li>
            <li>Женский</li>
          </ul>
        </li>
        <li>
          <b>Телефон</b> - необязательное поле, должно содержать только цифры
        </li>

      </ul>

      <app-alert type="info" class="mb-2 mt-2">
        <b>Подсказка:</b> Вы можете изменить скачанный файл экспорта, отредактировать его и загрузить обратно,
        либо скачать пример файла импорта и заполнить его
        <mt-chip
            class="mt-1"
            bg-color="green"
            :href="ImportUsersExampleFile"
            target="_blank"
        >
          Скачать пример файла
        </mt-chip>
      </app-alert>
    </div>

    <app-uploader-file
        accept=".csv, .xlsx, .xls"
        v-model="file"
        label="Выбрать файл"
        ref="fileInput"
        @change="(newFile) => file = newFile"
    >
      <template #label>
        Выбрать файл (поддерживаемые форматы: .csv, .xlsx, .xls)
      </template>
    </app-uploader-file>
    <mt-button
      @click="runImportUsersAction()"
      :loading="requestPending"
      size="large"
      class="import-dialog__btn"
    >
      Импортировать
    </mt-button>
  </mt-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import AppTitle from "@/components/layouts/AppTitle/AppTitle.vue";
import AppUploaderFile from "@/components/UI/AppUploaderFile/AppUploaderFile.vue";
import AppAlert from "@/components/UI/AppAlert/AppAlert.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import {mapActions} from "vuex";
import api from "@/network/index.js";
import UsersAdminApi from "@/api/users-admin.api";
import AppChip from "@/components/UI/AppChips/AppChip.vue";
import MtChip from "@/components/UI/mtChip/index.vue";
import ImportUsersExampleFile from "@/assets/files/Пример_импорта_пользователей.xlsx";

export default defineComponent({
  name: "ImportDialog",
  components: {MtChip, AppChip, MtButton, AppAlert, AppUploaderFile, AppTitle, MtDialog, ImportUsersExampleFile },
  props: {
    modelValue: Boolean
  },
  data () {
    return {
      file: null,
      requestPending: false,
      ImportUsersExampleFile
    }
  },
  computed: {

    computedModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    ...mapActions([
      'importUsers',
    ]),
    async runImportUsersAction() {
      if(!this.file) {
        this.$toast.error("Не выбран файл для импорта");
        return;
      }
      this.requestPending = true;
      UsersAdminApi.import(this.file, this.$route.params.planUuid)
          .then(res => {
            if(res) this.$toast.success('Пользователи успешно импортированы');
            this.$refs.fileInput.clear();
            this.$emit('update:modelValue', false);
            this.$emit('success', false);
          })
          .catch((error) => {
            this.$toast.error('Что-то пошло не так, пожалуйста проверьте валидность файла')
          })
          .finally(() => {
            this.requestPending = false;
          })
    }
  }
})
</script>

<style lang="scss">
.import-dialog {
  &__content {
    font-size: 13px;
    line-height: 1.4;
  }
  &__btn {
    width: 100%;
    margin-top: 30px;
  }
}
</style>