<template>
  <div class="mt-card">
    <div class="mt-card__header">
      <div
          class="mt-card__header__icon"
          :style="computedStyles"
          v-if="icon"
      >
        <mtSvg
            :name="icon"
            :color="iconColor"
        />
      </div>
      <slot name="header"/>
    </div>
    <slot name="content"/>
  </div>
</template>

<script>
import mtSvg from '@/components/UI/mtSvg/index.vue';

export default {
  name: 'mtCard',
  components: {
    mtSvg
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    iconBgColor: {
      type: String
    },
    iconColor: {
      type: String
    }
  },
  computed: {
    computedStyles() {
      return {
        "background-color": this.iconBgColor,
      }
    }
  }
}

</script>

<style
    lang="scss"
    src="./style.scss"
/>