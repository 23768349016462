<template>
  <mt-dialog
      size="md"
      class="custom-assessment"
      :opened-by-default="true"
      @close="onClose()"
  >
    <div class="custom-assessment__loader">
      <mt-loading-animation-linear
          v-if="isLoaded && isLoading"
          class="custom-assessment__loader__linear"
      />
    </div>

    <custom-assessment-preloader v-if="!isLoaded" />

    <div
        v-else
        :class="['custom-assessment__content', {
          'custom-assessment__content_loading': isLoading
        }]"
    >
      <div class="custom-assessment__header">
        <div class="custom-assessment__header__user-icon">
          <img
              src="@/assets/img/svg/user.svg"
              alt="user"
          />
        </div>

        <h2 class="custom-assessment__header__title">
          Респондент: {{ respondent?.fullName }}
        </h2>
      </div>

      <mt-expander class="custom-assessment__expander">
        <mt-expander-item :disabled="expRespTask.materials.length === 0 && !expRespTask.description">
          <template #header>
            <h3 class="custom-assessment__expander__title">{{ expRespTask.name }} </h3>
          </template>
          <template #prependHeader>
            <app-tooltip>
              <template #activator>
                <mt-button
                    color="light-green"
                    icon
                    class="custom-assessment__expander__header__btn"
                    :disabled="!hasPrevTask"
                    @click="() => changeTask('prev')"
                >
                  <chevron-left-icon :doubled="prevTaskIsOtherRespondent" />
                </mt-button>
              </template>

              {{
                !hasPrevTask ? 'Это самое первое задание' : prevTaskIsOtherRespondent ? 'Предыдущий респондент' : 'Предыдущее задание'
              }}
            </app-tooltip>
          </template>
          <template #appendHeader>
            <app-tooltip>
              <template #activator>
                <mt-button
                    color="light-green"
                    icon
                    class="custom-assessment__expander__header__btn"
                    :disabled="!hasNextTask"
                    @click="() => changeTask('next')"
                >
                  <chevron-left-icon
                      :doubled="nextTaskIsOtherRespondent"
                      style="transform: rotate(0);"
                  />
                </mt-button>
              </template>

              {{
                !hasNextTask ? 'Это последнее задание' : nextTaskIsOtherRespondent ? 'Следующий респондент' : 'Следующее задание'
              }}
            </app-tooltip>
          </template>
          <template #content>
            <p
                class="custom-assessment__expander__text"
                v-show="expRespTask.description"
            >
              {{ expRespTask.description }}
            </p>
            <template v-if="expRespTask.materials.length">
              <h2 class="custom-assessment__expander__file-title">Файлы</h2>
              <div class="card mt-2">
                <file-download-card :links="expRespTask.materials" />
              </div>
            </template>
          </template>
        </mt-expander-item>
      </mt-expander>

      <span class="custom-assessment__line" />

      <div class="custom-assessment__title">Оценка</div>

      <div class="custom-assessment__form">
        <div
            v-for="(scale, index) in scales"
            :key="index"
            class="form__group"
        >
          <h2>{{ scale.name }}</h2>
          <scale-mark-range
              v-model="scale.value"
              :min="scale.min"
              :max="scale.max"
              :step="scale.step"
              @update:modelValue="setMark(scale)"
          />
        </div>
      </div>

      <custom-assessment-chat
          ref="chatRef"
          :key="sessionUuid"
      />
    </div>
  </mt-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import mtButton from '@/components/UI/mtButton/index.vue';
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import CustomAssessmentApi from "@/api/custom-assessment.api";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";
import ScaleMarkRange from "@/components/workspace/ScaleMarkRange.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import CustomAssessmentPreloader
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentPreloader.vue";
import MtExpander from "@/components/UI/mtExpander/mtExpander.vue";
import fileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import MtExpanderItem from "@/components/UI/mtExpander/mtExpanderItem.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import CustomAssessmentChat
  from "@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessmentChat.vue";
import MtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";

export default {
  name: "expertCustomAssessment",
  components: {
    MtLoadingAnimationLinear,
    MtLinearProgress,
    CustomAssessmentChat,
    AppTooltip,
    MtExpanderItem,
    ChevronLeftIcon,
    fileDownloadCard,
    MtExpander,
    CustomAssessmentPreloader,
    MtDialog,
    ScaleMarkRange,
    AppCheckbox,
    AppSelect,
    mtButton
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      prevTask: false,
      nextTask: false
    }
  },
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'respondents']),
    ...mapState('assessmentPlans/plan/task', ['expRespTask', 'mark', 'scales', 'sessionUuid']),
    ...mapGetters(['isExpert']),

    planUuid() {
      return this.$route.params.planUuid;
    },

    taskUuid() {
      return this.$route.params.taskUuid;
    },

    task() {
      return this.respondent?.tests.find((item) => item.uuid === this.taskUuid);
    },

    respondentUuid() {
      return this.$route.params.respondentUuid;
    },

    respondent() {
      if (!this.respondentUuid) return null;
      return this.respondents.find((item) => item.uuid === this.respondentUuid);
    },

    hasNextTask() {
      if (!this.respondent) return false;

      const currentTaskIndex = this.respondent.tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getNextTask(currentTaskIndex, currentRespondentIndex);

      if (result) {
        this.nextTask = result;
      } else {
        this.nextTask = false;
      }

      return this.nextTask;
    },

    hasPrevTask() {
      if (!this.respondent) return false;

      const currentTaskIndex = this.respondent.tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getPrevTask(currentTaskIndex, currentRespondentIndex);

      if (result) {
        this.prevTask = result;
      } else {
        this.prevTask = false;
      }

      return this.prevTask;
    },

    nextTaskIsOtherRespondent() {
      if (!this.hasNextTask) return false;
      return this.hasNextTask.respondentUuid !== this.respondentUuid;
    },

    prevTaskIsOtherRespondent() {
      if (!this.hasPrevTask) return false;
      return this.hasPrevTask.respondentUuid !== this.respondentUuid;
    },
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['getScales', 'getMark', 'getUserSession', 'fetchExpRespTask']),
    ...mapMutations('assessmentPlans/plan/task', ['updateStore']),
    ...mapActions('assessmentPlans/plan', ['setPlanProgress']),

    onClose() {
      let routeName;
      switch (this.$route.name) {
        case "ExpertTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentTaskSingle":
          routeName = 'ExpRespAssessmentPlanSingle';
          break;
        case "RespondentCabinetTaskSingle":
          routeName = 'RespondentCabinet';
          break;
        default:
          routeName = 'AssessmentPlanSingleResults';
      }

      this.$router.replace({
        name: routeName,
        params: {
          uuid: this.planUuid
        }
      })
    },

    async setMark(scale) {
      CustomAssessmentApi.setMark(
          this.planUuid,
          this.taskUuid,
          this.sessionUuid,
          [
            {
              uuid: scale.uuid,
              grade: scale.value
            }
          ],
      )
          .then(() => {
            this.setPlanProgress({
              uuid: this.planUuid,
            });
            this.getMark({
              planUuid: this.planUuid,
              taskUuid: this.taskUuid,
              sessionUuid: this.sessionUuid
            });
            this.updateStore({entity: 'marksChanged', payload: true})
          })
    },

    async getSession() {
      return await this.getUserSession({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        respondentUuid: this.respondentUuid
      });
    },

    async getAllScales() {
      return await this.getScales({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        sessionUuid: this.sessionUuid
      });
    },

    async getTask() {
      return await this.fetchExpRespTask({
        uuidPlan: this.planUuid,
        uuid: this.taskUuid
      })
    },

    async getMarks() {
      return await this.getMark({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        sessionUuid: this.sessionUuid,
      })
    },

    getAll() {
      this.isLoading = true;
      return this.getSession()
          .then(() => {
            return Promise.all([
              this.getAllScales(),
              this.getTask(),
              this.getMarks()
            ])
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    loadNextTask() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.nextTask.uuid,
          respondentUuid: this.nextTask.respondentUuid
        }
      })
    },

    loadPrevTask() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.prevTask.uuid,
          respondentUuid: this.prevTask.respondentUuid
        }
      })
    },

    getPrevTask(taskIndex, respondentIndex) {
      let result = false;

      if (taskIndex === 0) {
        const prevRespondent = this.respondents[respondentIndex - 1];
        if (prevRespondent) {
          result = this.getPrevTask(prevRespondent.tests.length, respondentIndex - 1);
        } else {
          result = false;
        }
      } else {
        const prevTask = this.respondents[respondentIndex].tests[taskIndex - 1];
        if (prevTask && prevTask.type === 'expert') {
          result = {
            ...prevTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getPrevTask(taskIndex - 1, respondentIndex);
        }
      }

      return result;
    },

    getNextTask(taskIndex, respondentIndex) {
      let result = false;

      if (taskIndex < this.respondent.tests.length - 1) {
        const nextTask = this.respondent.tests[taskIndex + 1];

        if (nextTask && nextTask.type === 'expert') {
          result = {
            ...nextTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getNextTask(taskIndex + 1, respondentIndex);
        }
      } else if (respondentIndex < this.respondents.length - 1) {
        const nextRespondent = this.respondents[respondentIndex + 1];
        if (nextRespondent) {
          result = this.getNextTask(-1, respondentIndex + 1);
        }
      }

      return result;
    },

    changeTask(direction) {
      this.$refs.chatRef.checkToExistingNewMessage(direction === 'next' ? this.loadNextTask : this.loadPrevTask);

      const unWatch = watch(() => this.$route.params, () => {
        this.getAll().then(() => {
          unWatch();
        });
      });
    }
  },
  mounted() {
    this.getAll().then(() => {
      this.isLoaded = true;
    });
  }
}
</script>

<style src="./style.scss" />