<template>
  <auth-user-header-block-wrapper>
    <template #title>
      {{ authUser?.full_name }} <span class="u-color-grey">({{ authUser.account_name }})</span>
    </template>
    <template #subtitle>
      {{ authUser.company_name }} / {{ authUser.role.name }}
    </template>
  </auth-user-header-block-wrapper>
</template>

<script>
import AuthUserHeaderBlockWrapper from "@/components/workspace/auth/AuthUserHeaderBlock/AuthUserHeaderBlockWrapper.vue";
import {mapGetters} from "vuex";

export default {
  components: {AuthUserHeaderBlockWrapper},
  name: "AdminAuthUserHeaderBlock",
  computed: {
    ...mapGetters(['authUser']),
  },
}
</script>