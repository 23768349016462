export default function ( instance ) {
  return {
    getExpResp ( uuid )
    {
      return instance.get( `plans/${uuid}/expert-respondents` );
    },

    setExpResp ( uuid, data )
    {
      return instance.put( `plans/${uuid}/expert-respondents`, data );
    },

    setActiveCorrespondence ( uuid, active_correspondence )
    {
      return instance.put( `plans/${uuid}/active-correspondence`, { active_correspondence: active_correspondence });
    },
  }
}