<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.265625 15.5157H15.7344V17.2345H0.265625V15.5157ZM8.85938 10.5073L14.0766 5.28918L15.2918 6.50434L8 13.797L0.708203 6.5052L1.92336 5.28918L7.14062 10.5056V0.906372H8.85938V10.5073Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>