<template>
  <div class="mt-res-download-options">
    <div
        class="mt-res-download-options__option"
        v-if="isGroup"
    >
      <div class="mt-res-download-options__option-title">
        <div class="mt-res-download-options__option-logo">
          <svg
              class="mt-res-download-options__option-logo-icon"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M9.87262 0.583252L14.625 5.33325L14.6258 8.70575C14.1618 8.3207 13.6225 8.03676 13.0425 7.87213L13.0417 6.12492H9.08333V2.16659H1.95833V14.8333H5.96417C6.05473 15.4039 6.2761 15.9458 6.61096 16.4166H1.16112C0.968683 16.4166 0.782931 16.346 0.639066 16.2182C0.495201 16.0903 0.403216 15.9142 0.380542 15.7231L0.375 15.6313V1.36859C0.375 0.974335 0.681375 0.63471 1.07562 0.588794L1.16825 0.583252H9.87262ZM11.8542 9.29159C12.589 9.29159 13.2938 9.58351 13.8134 10.1031C14.3331 10.6228 14.625 11.3275 14.625 12.0624L14.6242 12.144C15.1195 12.2835 15.5493 12.594 15.8375 13.0202C16.1256 13.4465 16.2535 13.9611 16.1983 14.4727C16.1432 14.9843 15.9087 15.4598 15.5363 15.8149C15.164 16.17 14.6779 16.3818 14.1642 16.4126L14.0312 16.4166H9.67708L9.54408 16.4126C9.03057 16.3815 8.54467 16.1695 8.17255 15.8143C7.80043 15.459 7.56613 14.9835 7.51121 14.472C7.45628 13.9605 7.58427 13.446 7.87248 13.0199C8.1607 12.5937 8.59051 12.2834 9.08571 12.144L9.08333 12.0624C9.08333 11.3275 9.37526 10.6228 9.89489 10.1031C10.4145 9.58351 11.1193 9.29159 11.8542 9.29159V9.29159ZM11.8542 10.8749C11.5783 10.8749 11.3111 10.9708 11.0983 11.1463C10.8855 11.3219 10.7405 11.566 10.688 11.8368L10.6722 11.9476L10.6667 12.0624V13.3449L9.5235 13.6663C9.39176 13.7015 9.27614 13.7809 9.19607 13.8913C9.11599 14.0017 9.07633 14.1362 9.08376 14.2724C9.09119 14.4085 9.14525 14.5379 9.23687 14.6389C9.32848 14.7399 9.45205 14.8063 9.58683 14.8269L9.67312 14.8333H14.0352C14.1788 14.8322 14.317 14.7791 14.4245 14.6839C14.5319 14.5887 14.6012 14.4578 14.6196 14.3154C14.6379 14.1731 14.6041 14.0289 14.5243 13.9095C14.4445 13.7902 14.3242 13.7038 14.1856 13.6663L13.3837 13.4423L13.0417 13.3449V12.0624L13.0361 11.9484C13.0078 11.6546 12.871 11.3818 12.6525 11.1834C12.434 10.9849 12.1494 10.8749 11.8542 10.8749V10.8749Z"
                fill="white"
            ></path>
          </svg>
        </div>
        <div class="mt-res-download-options__option-title-text">EXCEL</div>
      </div>
      <svg
          class="mt-res-download-options__option-action"
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="download({ format: 'xlsx' })"
      >
        <path
            d="M9.29159 5.91667H13.2499L8.49992 10.6667L3.74992 5.91667H7.70825V0.375H9.29159V5.91667ZM2.16659 13.0417H14.8333V7.5H16.4166V13.8333C16.4166 14.0433 16.3332 14.2447 16.1847 14.3931C16.0362 14.5416 15.8349 14.625 15.6249 14.625H1.37492C1.16496 14.625 0.963592 14.5416 0.815126 14.3931C0.666659 14.2447 0.583252 14.0433 0.583252 13.8333V7.5H2.16659V13.0417Z"
            fill="#00788C"
        ></path>
      </svg>
    </div>
    <div class="mt-res-download-options__option">
      <div class="mt-res-download-options__option-title">
        <div class="mt-res-download-options__option-logo">
          <svg
              class="mt-res-download-options__option-logo-icon"
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M7.24434 0.375L8.82767 1.95833H15.6249C15.8349 1.95833 16.0362 2.04174 16.1847 2.19021C16.3332 2.33867 16.4166 2.54004 16.4166 2.75V13.8333C16.4166 14.0433 16.3332 14.2447 16.1847 14.3931C16.0362 14.5416 15.8349 14.625 15.6249 14.625H1.37492C1.16496 14.625 0.963592 14.5416 0.815126 14.3931C0.666659 14.2447 0.583252 14.0433 0.583252 13.8333V1.16667C0.583252 0.956704 0.666659 0.75534 0.815126 0.606874C0.963592 0.458407 1.16496 0.375 1.37492 0.375H7.24434ZM13.2499 12.25H10.0833V9.875H11.6666V8.29167H10.0833V6.70833H11.6666V5.125H10.0833V3.54167H8.17217L6.58884 1.95833H2.16659V13.0417H14.8333V3.54167H11.6666V5.125H13.2499V6.70833H11.6666V8.29167H13.2499V12.25Z"
                fill="white"
            ></path>
          </svg>
        </div>
        <div class="mt-res-download-options__option-title-text">PDF</div>
      </div>
      <svg
          class="mt-res-download-options__option-action"
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="download({ format: 'pdf' })"
      >
        <path
            d="M9.29159 5.91667H13.2499L8.49992 10.6667L3.74992 5.91667H7.70825V0.375H9.29159V5.91667ZM2.16659 13.0417H14.8333V7.5H16.4166V13.8333C16.4166 14.0433 16.3332 14.2447 16.1847 14.3931C16.0362 14.5416 15.8349 14.625 15.6249 14.625H1.37492C1.16496 14.625 0.963592 14.5416 0.815126 14.3931C0.666659 14.2447 0.583252 14.0433 0.583252 13.8333V7.5H2.16659V13.0417Z"
            fill="#00788C"
        />
      </svg>
    </div>
    <div class="mt-res-download-options__option">
      <div class="mt-res-download-options__option-title">
        <div class="mt-res-download-options__option-logo">
          <svg
              class="mt-res-download-options__option-logo-icon"
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12.4583 15.0416H14.8333V3.9583H12.4583V2.37497H15.625C15.8349 2.37497 16.0363 2.45837 16.1848 2.60684C16.3332 2.75531 16.4166 2.95667 16.4166 3.16663V15.8333C16.4166 16.0433 16.3332 16.2446 16.1848 16.3931C16.0363 16.5416 15.8349 16.625 15.625 16.625H12.4583V15.0416ZM1.26335 2.27759L11.2146 0.85655C11.2708 0.848494 11.328 0.852604 11.3824 0.868603C11.4369 0.884603 11.4872 0.912116 11.5301 0.94928C11.573 0.986444 11.6073 1.03239 11.6309 1.084C11.6545 1.13561 11.6667 1.19169 11.6666 1.24843V17.7515C11.6666 17.8082 11.6545 17.8642 11.631 17.9157C11.6074 17.9673 11.5731 18.0132 11.5304 18.0503C11.4876 18.0875 11.4373 18.115 11.383 18.1311C11.3286 18.1471 11.2715 18.1513 11.2154 18.1434L1.26256 16.7223C1.07384 16.6955 0.901159 16.6014 0.776231 16.4574C0.651304 16.3134 0.582521 16.1292 0.58252 15.9386V3.06134C0.582521 2.87072 0.651304 2.6865 0.776231 2.54252C0.901159 2.39854 1.07384 2.30447 1.26256 2.27759H1.26335ZM2.16664 3.74851V15.2514L10.0833 16.3827V2.61722L2.16664 3.74851ZM7.70831 6.3333H9.29165V12.6666H7.70831L6.12498 11.0833L4.54164 12.6666H2.95831V6.3333H4.54164L4.54956 10.2916L6.12498 8.7083L7.70831 10.2829V6.3333Z"
                fill="white"
            ></path>
          </svg>
        </div>
        <div class="mt-res-download-options__option-title-text">DOCX</div>
      </div>
      <svg
          class="mt-res-download-options__option-action"
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="download({ format: 'docx' })"
      >
        <path
            d="M9.29159 5.91667H13.2499L8.49992 10.6667L3.74992 5.91667H7.70825V0.375H9.29159V5.91667ZM2.16659 13.0417H14.8333V7.5H16.4166V13.8333C16.4166 14.0433 16.3332 14.2447 16.1847 14.3931C16.0362 14.5416 15.8349 14.625 15.6249 14.625H1.37492C1.16496 14.625 0.963592 14.5416 0.815126 14.3931C0.666659 14.2447 0.583252 14.0433 0.583252 13.8333V7.5H2.16659V13.0417Z"
            fill="#00788C"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rr: false,
  }),
  computed: {},

  watch: {},
  methods: {
    /* GETTERS */
    /* SETTERS */

    /* HANDLERS */
    download({format,}) {
      this.$emit('download', {format,});
    },

    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.mt-res-download-options {
  margin-top: 20px;

  &__option {
    font-family: 'Mulish';
    font-style: normal;
    border: 1.5px solid #DFE0EB;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000;
      user-select: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      &-text {
        margin-left: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
        cursor: pointer;
      }
    }

    &-logo {
      background: #00788C;
      border-radius: 2px;
      width: 31px;
      height: 31px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      &-icon {
      }
    }

    &-action {
      cursor: pointer;
    }
  }
}
</style>