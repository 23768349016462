<template>
  <div
      class="mt-select"
      :class="[{ 'mt-select_opened': showList }, `mt-select__theme-${theme}`]"
      v-click-outside="vcoSettings"
      ref="select"
  >
    <div
        class="mt-select__selected"
        @click="toggle"
    >
      <span>{{ selectedValue }}</span>
      <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M2.98351 4.5H9.01398C9.43117 4.5 9.63976 5.00391 9.34445 5.29922L6.33039 8.31562C6.14757 8.49844 5.84992 8.49844 5.66711 8.31562L2.65304 5.29922C2.35773 5.00391 2.56632 4.5 2.98351 4.5Z"
            fill="#9FA2B4"
        />
      </svg>
    </div>
    <div
        class="mt-select__list"
        ref="list"
        v-if="showList"
        :style="{'left': getListPos.left + 'px', 'top': getListPos.top + 'px', 'width': getListPos.width + 'px'}"
    >
      <div
          class="mt-select__list-item"
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem(index)"
      >
        {{ item.name || item.value || item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mtSelect',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    valueProp: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: null
    },
    placeholder: String
  },
  created() {
  },
  data() {
    return {
      showList: false,
      vcoSettings: {
        handler: this.hide,
        events: ['mousedown'],
        isActive: true,
        detectIFrame: true,
        capture: false
      },
      listPos: {
        left: 0,
        top: 0,
        width: 0
      }
    }
  },

  computed: {
    getListPos() {
      return this.listPos;
    },
    selectedValue() {
      return this.modelValue ? this.modelValue : this.placeholder
    }
  },

  methods: {
    toggle() {
      this.showList = !this.showList
      if (this.showList) {
        setTimeout(() => {
          const clientHeight = document.documentElement.clientHeight;
          const clientWidth = document.documentElement.clientWidth;
          const listClientRect = this.$refs.list.getBoundingClientRect();
          const select = this.$refs.select.getBoundingClientRect();
          const listBottom = Math.ceil(select.bottom + listClientRect.height);
          const listRight = Math.ceil(listClientRect.left + listClientRect.width);
          this.listPos.left = select.left;
          this.listPos.width = select.width;
          if (listBottom > clientHeight) {
            this.listPos.top = (select.top - listClientRect.height);
          } else {
            this.listPos.top = select.bottom;
          }

        })
      }
    },
    hide() {
      this.showList = false
    },
    selectItem(index) {
      this.$emit('update:modelValue', this.items[index])
      this.hide()
    }
  }
}
</script>

<style
    lang="scss"
    src="./mtSelect.scss"
    scoped
/>