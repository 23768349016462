<script setup lang="ts">
import { computed } from "vue";
import { generateRandomID } from "@/services/utils";
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";
import TickIcon from "@/components/icons/TickIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";

const props = defineProps({
  modelValue: {
    type: [Boolean, String, Number, Array],
    default: false
  },
  indeterminate: {
    type: Boolean,
    default: false
  },
  value: {
    type: [Number, String, Boolean],
    default: null
  },
  trueValue: {
    type: undefined,
    default: true
  },
  falseValue: {
    type: undefined,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: null
  }
})
const emit = defineEmits([
  'change', 'update:modelValue'
])
const randomID = generateRandomID();

const isChecked = computed(() => {
  if(props.checked !== null) {
    return props.checked;
  }
  if (typeof props.modelValue === 'object') {
    return props.modelValue.includes(props.value)
  } else {
    return props.modelValue === props.trueValue
  }
})
const computedActiveValue = computed(() => {
  if (typeof props.modelValue === 'object') {
    return props.value
  } else {
    return props.modelValue
  }
})

function change () {
  if (typeof props.modelValue === 'object') {
    toggleArrayValue()
  } else {
    toggleBooleanValue()
  }
}

function toggleBooleanValue () {
  emit('update:modelValue', isChecked.value ? props.falseValue : props.trueValue)
  emit('change', isChecked.value ? props.falseValue : props.trueValue)
}

function toggleArrayValue () {
  if (props.modelValue.includes(props.value)) {
    removeFromArray()
  } else {
    props.modelValue.push(props.value)
  }
  emit('change', computedActiveValue.value)
}

function removeFromArray () {
  const index = props.modelValue.findIndex((item: object) => item === props.value)
  props.modelValue.splice(index, 1)
}

</script>

<template>
  <app-form-block>
    <div
        :class="[
          'checkbox',
          {
            'checkbox--disabled': disabled,
            'checkbox--indeterminate': indeterminate,
          }
        ]"
    >
      <input
          :id="`${randomID}`"
          :checked="isChecked"
          :value="computedActiveValue"
          :disabled="disabled"
          class="checkbox__input"
          type="checkbox"
          @change="change()"
      >
      <label
          :for="`${randomID}`"
          class="checkbox__label"
      >
      <span class="checkbox__label__icon">
        <component
            :is="indeterminate ? MinusIcon : TickIcon"
        />
      </span>
      <label
          :for="`${randomID}`"
          v-if="$slots.label"
          class="checkbox__label__text"
        >
        <slot name="label"/>
      </label>
      </label>
    </div>
  </app-form-block>
</template>

<style lang="scss" src="./AppCheckbox.scss"/>