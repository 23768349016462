<script setup lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="sort-ascending">
    <rect width="256" height="256" fill="none"></rect>
    <polyline fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"
              points="144 167.994 184 207.994 223.999 168"></polyline>
    <line x1="184" x2="184" y1="111.993" y2="207.993" fill="none" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
    <line x1="48" x2="119.999" y1="127.994" y2="127.994" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
    <line x1="48" x2="183.999" y1="63.994" y2="63.994" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
    <line x1="48" x2="104" y1="191.994" y2="191.994" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
  </svg>
</template>