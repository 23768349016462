<template></template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import store from '@/store'

const router = useRouter()
const authStore = useStore()

const loginURL = import.meta.env.VITE_LOGIN_URL

async function signIn() {
  const params = new URLSearchParams(location.search)
  const email = params.get('email')
  const cabinetId = params.get('cabinetId')
  const roleCode = params.get('roleCode')
  const accessToken = params.get('accessToken')
  const refreshToken = params.get('refreshToken')

  if (!email || !accessToken || !refreshToken) {
    location = loginURL
    return
  }

  const payload = {
    email,
    accessToken,
    refreshToken,
    cabinetId,
    roleCode,
  }

  authStore
    .dispatch('signIn', payload)
    .then((response) => {
      const status = response.data.type
      if (status === 'signin') {
        location = '/';
      } else if (status === 'finish_register') {
        router.push({
          name: 'FinishSignup',
        })
      }
    })
    .catch((error) => {
      location = loginURL
    })
}

onBeforeMount(signIn)
</script>
