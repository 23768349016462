<template>
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
    <path d="M3.33301 17.5001H5.49967V19.6667H18.4997V2.33341H5.49967V4.50008H3.33301V1.25008C3.33301 0.962764 3.44714 0.687213 3.65031 0.484049C3.85347 0.280885 4.12902 0.166748 4.41634 0.166748H19.583C19.8703 0.166748 20.1459 0.280885 20.349 0.484049C20.5522 0.687213 20.6663 0.962764 20.6663 1.25008V20.7501C20.6663 21.0374 20.5522 21.313 20.349 21.5161C20.1459 21.7193 19.8703 21.8334 19.583 21.8334H4.41634C4.12902 21.8334 3.85347 21.7193 3.65031 21.5161C3.44714 21.313 3.33301 21.0374 3.33301 20.7501V17.5001ZM5.49967 9.91675H13.083V12.0834H5.49967V15.3334L0.0830078 11.0001L5.49967 6.66675V9.91675Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  name: "Exit",
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>

</style>