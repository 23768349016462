<template>
  <div class="statistics-circle">
    <mtCircleProgress
        v-model="computedModelValue"
        :max="max"
        :radius="radius"
        :stroke="stroke"
    />
    <div class="statistics-circle__desc">
      <div class="statistics-circle__title">Баллы:</div>
      <div class="statistics-circle__subtitle">{{ modelValue }} из {{ max }}</div>
    </div>
  </div>
</template>

<script>
import mtCircleProgress from "@/components/UI/mtCircleProgress/mtCircleProgress.vue";

export default {
  name: "statisticsCircle",
  components: {mtCircleProgress},
  props: {
    modelValue: [String, Number],
    max: [String, Number],
    radius: {
      type: [String, Number],
      default: 16
    },
    stroke: {
      type: [String, Number],
      default: 5
    }
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      }
    }
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>