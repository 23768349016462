<template>
  <div class="auth__container">
    <img
        class="auth__header__logo"
        src="@/assets/img/svg/full-logo.svg"
    />
    <div class="auth__wrapper">
      <slot name="prepend"/>
      <div class="auth__header">
        <slot name="title"/>
      </div>
      <div class="auth__body">
        <slot name="body"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthWrapper'
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>