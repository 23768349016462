<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75462 10.6606V12.3007C5.41104 12.3007 4.12249 12.8191 3.17244 13.7418C2.22239 14.6645 1.68865 15.916 1.68865 17.221H0C0 15.481 0.711645 13.8124 1.97838 12.5821C3.24512 11.3518 4.96318 10.6606 6.75462 10.6606ZM6.75462 9.84055C3.95567 9.84055 1.68865 7.63872 1.68865 4.92027C1.68865 2.20182 3.95567 0 6.75462 0C9.55356 0 11.8206 2.20182 11.8206 4.92027C11.8206 7.63872 9.55356 9.84055 6.75462 9.84055ZM6.75462 8.20046C8.62058 8.20046 10.1319 6.73257 10.1319 4.92027C10.1319 3.10797 8.62058 1.64009 6.75462 1.64009C4.88865 1.64009 3.37731 3.10797 3.37731 4.92027C3.37731 6.73257 4.88865 8.20046 6.75462 8.20046ZM14.7301 15.6071L16 16.8405L14.8061 18L13.5363 16.7667C12.8282 17.1722 11.9921 17.3129 11.1848 17.1625C10.3775 17.0122 9.65433 16.581 9.15093 15.9498C8.64753 15.3186 8.39842 14.5308 8.45031 13.734C8.50219 12.9372 8.85151 12.1862 9.43277 11.6216C10.014 11.0571 10.7873 10.7178 11.6077 10.6674C12.4281 10.617 13.2392 10.859 13.8891 11.3479C14.539 11.8368 14.9829 12.5392 15.1377 13.3232C15.2926 14.1073 15.1476 14.9194 14.7301 15.6071ZM11.8206 15.5809C12.2684 15.5809 12.698 15.4081 13.0146 15.1005C13.3313 14.7929 13.5092 14.3758 13.5092 13.9408C13.5092 13.5058 13.3313 13.0886 13.0146 12.7811C12.698 12.4735 12.2684 12.3007 11.8206 12.3007C11.3727 12.3007 10.9432 12.4735 10.6265 12.7811C10.3098 13.0886 10.1319 13.5058 10.1319 13.9408C10.1319 14.3758 10.3098 14.7929 10.6265 15.1005C10.9432 15.4081 11.3727 15.5809 11.8206 15.5809Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>