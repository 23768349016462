<script
    setup
    lang="ts"
>
import { generateRandomID } from "@/services/utils";
import { computed, ref } from "vue";

const randomId = generateRandomID();

const props = defineProps({
  accept: {
    type: String,
    default: "",
  },
  imageOnly: {
    type: Boolean,
    default: false,
  },
});

const accept = computed(() => {
  if (props.imageOnly) {
    return "image/*";
  }

  return props.accept;
});

const emit = defineEmits(["update:modelValue", 'change']);

const loadedImage = ref(null);
const loadedFileName = ref('');

const handleFileChange = (e: Event) => {
  e.preventDefault();
  const file = (e.target as HTMLInputElement).files?.[0];
  if (!file) return;

  emit("change", file);

  const reader = new FileReader();
  reader.onload = (e) => {
    loadedImage.value = e.target?.result;
  };
  if(file.type.includes('image')) reader.readAsDataURL(file);
  loadedFileName.value = file.name;
  e.target.value = '';
};

const clear = () => {
  loadedImage.value = null;
  loadedFileName.value = '';
};

defineExpose({
  clear,
});

</script>

<template>
  <div class="app-uploader-file">
    <input
        :id="randomId"
        type="file"
        :accept="accept"
        @change="handleFileChange"
        class="app-uploader-file__input"
    />
    <label
        :for="randomId"
        class="app-uploader-file__label"
    >
      <img
          v-if="loadedImage"
          :src="loadedImage"
          class="app-uploader-file__image"
          alt=""
      />

      <slot name="label"/>

      <template v-if="!$slots.label">
        Загрузить файл
      </template>

      <span class="app-uploader-file__name">{{ loadedFileName }}</span>
    </label>
  </div>
</template>

<style
    lang="scss"
    src="./AppUploaderFile.scss"
/>