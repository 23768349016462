<template>
  <div class="app-data-table">
    <div class="app-data-table__header">
      <div class="app-data-table__header__title" v-if="hasTitleSlot">
        <h2 class="app-data-table__header__title__text">
          <slot name="title" />
        </h2>
        <div class="app-data-table__header__title__right">
          <slot name="header-right" />
        </div>
      </div>

      <div class="app-data-table__header__actions">
        <slot name="header-actions" />
      </div>
    </div>
    <div class="app-data-table__wrap">
      <table>
        <thead>
          <tr>
            <th v-if="checkable">
              <app-checkbox
                v-model="allItemsAreSelected"
                :indeterminate="
                  computedSelectedItems.length > 0 && !allItemsAreSelected
                "
                @change="toggleSelectAllItems"
                style="margin-bottom: 0"
              />
            </th>
            <template v-for="(item, index) in computedHeaders" :key="index">
              <th v-if="item.visible">
                <div class="d-flex">
                  <button
                    :class="[
                      'app-data-table__table__header__title',
                      {
                        'app-data-table__table__header__title_is-sortable':
                          item.sortable,
                        'app-data-table__table__header__title_is-sorted':
                          item.isSorted,
                      },
                    ]"
                    :disabled="!item.sortable"
                    @click="sortBy(item.stub)"
                  >
                    {{ item.title }}
                  </button>

                  <sort-toggler
                    :style="{
                      visibility: item.isSorted ? 'visible' : 'hidden',
                    }"
                    v-model="computedSortValue"
                    :stub="item.stub"
                    :sort-type="item.sortType"
                  />
                </div>
              </th>
            </template>

            <template v-if="hasActionsSlotInItem">
              <th></th>
            </template>
          </tr>
        </thead>
        <tbody
          :class="[
            'app-data-table__tbody',
            {
              'app-data-table__tbody_is-loading': isLoaded && isLoading,
            },
          ]"
        >
          <template v-if="isLoading && isLoaded">
            <td
              :colspan="checkable ? headers.length + 1 : headers.length"
              class="app-data-table__progress"
            >
              <mt-loading-animation-linear
                class="app-data-table__progress__linear"
              />
            </td>
          </template>

          <template v-if="isLoading && !isLoaded">
            <tr v-for="index in 5">
              <td v-if="checkable">
                <app-placeholder
                  class="mt-data-table__placeholder"
                  height="20px"
                />
              </td>

              <td v-for="(column, columnIndex) in headers" :key="columnIndex">
                <app-placeholder
                  class="mt-data-table__placeholder"
                  height="32px"
                />
              </td>

              <td v-if="hasActionsSlotInItem">
                <app-placeholder
                  class="mt-data-table__placeholder"
                  height="32px"
                />
              </td>
            </tr>
          </template>

          <template v-else-if="Object.keys($slots).includes('tbody')">
            <slot name="tbody" />
          </template>

          <tr
            v-else
            v-for="(item, itemIndex) in computedItems"
            :key="item.uuid"
          >
            <td v-if="checkable">
              <mt-checkbox
                v-model="item.selected"
                @change="singleItemToggleSelect(item)"
              />
            </td>

            <template
              v-for="(column, columnIndex) in headers"
              :key="columnIndex"
            >
              <td v-if="column.visible">
                <template v-if="!$slots[`${column[headerValueKey]}`]">
                  {{ item[column[headerValueKey]] }}
                </template>
                <slot
                  v-else
                  :name="`${column[headerValueKey]}`"
                  :item="item"
                  :index="itemIndex"
                />
              </td>
            </template>

            <template v-if="hasActionsSlotInItem">
              <td>
                <div class="app-data-table__item-actions">
                  <slot name="actions" :item="item" :index="itemIndex" />
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <info-block v-if="!items.length && !isLoading && !$slots.tbody" />

    <div
      v-if="Object.keys($slots).includes('footer-actions') || withPagination"
      class="app-data-table__footer"
    >
      <div class="app-data-table__footer__linear">
        <mt-loading-animation-linear v-if="isPaginationRequestPending" />
      </div>

      <div class="app-data-table__footer__actions">
        <slot name="footer-actions" />
      </div>
      <div class="app-data-table__footer__selected-count" v-if="computedSelectedItems.length > 0">
        Выбрано: {{ computedSelectedItems.length }}
      </div>

      <mt-pagination
        v-if="withPagination"
        :page="pagination.page"
        :pages="pagination.pages"
        :per-page="pagination.perPage"
        :total="pagination.total"
        class="app-data-table__mt-pagination"
        @change="changePagination"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, useSlots } from 'vue'
import mtPagination from '@/components/UI/mtPagination/mtPagination.vue'
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue'
import InfoBlock from '@/components/workspace/InfoBlock.vue'
import AppPlaceholder from '@/components/UI/AppPlaceholder/AppPlaceholder.vue'
import MtSvg from '@/components/UI/mtSvg/index.vue'
import SortAscendingIcon from '@/components/icons/SortAscendingIcon.vue'
import SortDescendingIcon from '@/components/icons/SortDescendingIcon.vue'
import AppTooltip from '@/components/UI/AppTooltip/AppTooltip.vue'
import MtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue'
import SortToggler from '@/components/workspace/SortToggler.vue'
import AppCheckbox from '@/components/UI/AppCheckbox/AppCheckbox.vue'

export default defineComponent({
  name: 'AppDataTable',
  components: {
    AppCheckbox,
    SortToggler,
    MtLoadingAnimationLinear,
    AppTooltip,
    MtSvg,
    AppPlaceholder,
    InfoBlock,
    mtCheckbox,
    mtPagination,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    headerTitleKey: {
      type: String,
      default: 'name',
    },
    headerValueKey: {
      type: String,
      default: 'value',
    },
    items: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: [Object, undefined],
      default: undefined,
    },
    selectedItems: Array,
    withPagination: Boolean,
    isPaginationRequestPending: Boolean,
    isLoading: Boolean,
    isLoaded: Boolean,
    checkable: Boolean,
  },
  data() {
    return {
      allItemsAreSelected: false,
    }
  },
  setup() {
    const sort = inject('sort')
    const slots = useSlots()

    return {
      sort,
      slots
    }
  },
  computed: {
    hasTitleSlot() {
      return (
        Object.keys(this.$slots).includes('title') ||
        Object.keys(this.$slots).includes('header-right')
      )
    },
    computedItems() {
      if (!this.computedSelectedItems.length) {
        return this.items.map((i) => {
          i.selected = false
          return i
        })
      }

      const items = this.items

      this.computedSelectedItems.forEach((i) => {
        const foundItem = items.find((x) => x.uuid === i.uuid)
        if (foundItem) foundItem.selected = true
      })

      return items
    },
    computedSelectedItems: {
      get() {
        return this.selectedItems || []
      },
      set(newValue) {
        this.$emit('update:selectedItems', newValue)
      },
    },

    SortDescendingIcon() {
      return SortDescendingIcon
    },
    SortAscendingIcon() {
      return SortAscendingIcon
    },
    computedHeaders() {
      return this.headers.map((item) => {
        return {
          ...item,
          isSorted: this.computedSort && item.stub === this.computedSort?.field,
          sortType: this.computedSort?.order,
        }
      })
    },
    hasActionsSlotInItem() {
      return Object.keys(this.$slots).includes('actions')
    },

    computedSort() {
      if (!this.$route.query.sort) return

      return JSON.parse(this.$route.query.sort)
    },

    computedIsPaginationRequestPending: {
      get() {
        return this.isPaginationRequestPending
      },
      set(newValue) {
        this.$emit('update:isPaginationRequestPending', newValue)
      },
    },
    computedSortValue: {
      get() {
        return this.sort
      },
      set(newValue) {
        this.sort = newValue
      },
    },
  },
  methods: {
    toggleSelectAllItems() {
      if (this.allItemsAreSelected) {
        this.computedSelectedItems = [
          ...this.computedSelectedItems.filter((x) => x.selected),
          ...this.computedItems.filter((x) => !x.selected),

        ]
      } else {
        this.computedSelectedItems = []
      }
    },

    singleItemToggleSelect(item) {
      const foundItemIndex = this.computedSelectedItems.findIndex(
        (x) => x.uuid === item.uuid,
      )
      if (foundItemIndex < 0) {
        this.computedSelectedItems.push(item)
      } else {
        this.computedSelectedItems.splice(foundItemIndex, 1)
      }
    },

    changePagination(newValue) {
      this.$emit('update:pagination', {
        page: newValue.page,
        perPage: newValue.perPage,
        total: newValue.total,
      })
    },

    sortBy(field) {
      const isSameField = field === this.computedSortValue?.field;
      const newOrder = this.computedSortValue?.order === 'asc' ? 'desc' : 'asc';

      if (isSameField && this.computedSortValue.order === 'desc') {
        // Reset sorting when the same field is clicked twice with descending order
        this.computedSortValue = null;
      } else {
        // Update sorting either by switching the order or changing the field
        this.computedSortValue = {
          field,
          order: isSameField ? newOrder : 'asc',
        };
      }
    },

    checkIfAllAreSelected() {
      if(this.computedItems.length === 0) {
        this.allItemsAreSelected = false
        return
      }
      this.allItemsAreSelected = this.computedItems.every((x) => x.selected)
    },
  },
  mounted() {
    this.checkIfAllAreSelected();
  },
  watch: {
    computedItems() {
      this.checkIfAllAreSelected();
    },
    computedSelectedItems: {
      handler() {
        this.checkIfAllAreSelected();
      },
      deep: true
    },
  },
})
</script>

<style lang="scss" src="./AppDataTable.scss" />
