export default function ( instance ) {
  return {
    get ( per_page = 5, page = 1, name = '', sort = null /*filters = {}*/ )
    {
      let params = {
        data : JSON.stringify(
          {
            name      : name,
            sort      : sort,
            //filters   : filters,
          }
        )
      };

      return instance.get( `plans?per_page=${per_page}&page=${page}`, { params : params } );
    },

    createNewPlan ()
    {
      return instance.post( 'plans' );
    },

    switchPlans ( data )
    {
      return instance.put( 'plans/active', data );
    },

    addToArchive ( data )
    {
      return instance.put( 'plans/archive', data.body );
    },
    restorePlan ( data )
    {
      return instance.put( 'plans/unarchive', data.body );
    },

    addTags ( data )
    {
      return instance.put( 'plans/tags-attach', data );
    },

    deletePlans ( data )
    {
      return instance.delete( 'plans/delete', { data: data } );
    },
  }
}
