<script lang="ts" setup>
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import { computed } from 'vue'
import { inputNumbersOnly } from '@/services/utils'

interface IProps {
  modelValue: string | number | null
  large?: boolean
  hint?: String
  label?: String
  errors?: any[]
  unformatted?: boolean
}

const props = defineProps<IProps>()
const emit = defineEmits(['update:modelValue'])

const computedModelValue = computed({
  get() {
    if (props.unformatted) return props.modelValue!

    return formatValue(props.modelValue!)
  },
  set(newValue) {
    let formattedValueAsNumber = newValue.replace(/\D/g, '')

    emit('update:modelValue', formattedValueAsNumber)
  },
})

function formatValue(val: string | number) {
  let valueAsString = String(val)

  valueAsString = valueAsString
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return valueAsString
}

function onInput(event: Event) {
  const target = event.target as HTMLInputElement

  if (target.value.split('.').length > 2) {
    target.value = target.value.slice(0, -1)
  }
}
</script>

<template>
  <app-input
    v-model="computedModelValue"
    :large="large"
    :keypress="inputNumbersOnly"
    @input="onInput"
    :label="props.label"
    :hint="props.hint"
    :errors="props.errors"
    :maxLength="20"
  >
    <template #label>
      <slot name="label" />
    </template>
  </app-input>
</template>
