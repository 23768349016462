<template>
  <div class="password-rules">
    <div class="password-rules__title">Ваш пароль должен содержать:</div>

    <template v-for="(rule, index) in computedRules" :key="index">
      <div
        :class="[
          'password-rules__item',
          {
            'password-rules__item_error': !rule.valid,
            'password-rules__item_correct': rule.valid,
            'password-rules__item_empty':
              passwordForCheck === '' || passwordForCheck === null,
          },
        ]"
      >
        <div class="password-rules__item__status" />
        <div class="password-rules__item__title">
          {{ rule.name }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mtButton from '@/components/UI/mtButton/index.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, sameAs } from '@vuelidate/validators'
import AuthApi from '@/api/auth.api.ts'
import AppInputPassword from '@/components/UI/AppInputPassword.vue'
import { useValidations } from '@/plugins/vuelidate-wrapper'
import {arrayOfObjectsToObject} from "@/services/utils.js";

export default {
  name: 'PasswordRules',
  components: { AppInputPassword, AppInput, mtButton },
  props: {
    passwordForCheck: String,
  },
  data: () => ({
    requestPending: false,
    rules: [],
  }),
  computed: {
    computedRules() {
      const password = this.passwordForCheck ?? '';
      // convert rules to object
      const rules = arrayOfObjectsToObject(this.rules, 'slug');

      if (rules.hasOwnProperty('min')) {
        let minLength = rules.min.rule.split(':')[1]
        rules['min'].name = 'Минимальное количество символов: ' + minLength;
        rules['min'].valid = password.length >= minLength;
      }

      if (rules.hasOwnProperty('0_9')) {
        let re = /(.*\d.*)/
        rules['0_9'].valid = re.test(password);
      }

      if(rules.hasOwnProperty('a_z')) {
        let re = /(?=.*[a-z])/
        rules['a_z'].valid = re.test(password);
      }

      if(rules.hasOwnProperty('A_Z')) {
        let re = /(?=.*[A-Z])/
        rules['A_Z'].valid = re.test(password);
      }

      if(rules.hasOwnProperty('spec')) {
        let re = /(.*\W.*)/
        rules['spec'].valid = re.test(password);
      }

      return rules;
    },
    allRulesAreValid() {
      if(this.newPassword === '' || this.newPassword === null) return false;

      return Object.values(this.computedRules).every((rule) => rule.valid);
    },
  },
  methods: {
    async getPasswordRules() {
      const { data } = await AuthApi.getPasswordRules()
      this.rules = data.data
    },
  },
  mounted() {
    this.getPasswordRules()
  },
}
</script>

<style lang="scss" src="./style.scss"/>
