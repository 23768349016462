<script setup lang="ts">
import MtDialog from '@/components/UI/mtDialog/mtDialog.vue'
import { useValidations } from '@/plugins/vuelidate-wrapper'
import useVuelidate from '@vuelidate/core'
import AppTextarea from '@/components/UI/AppTextarea/AppTextarea.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import useToast from '@/components/UI/AppToast/useToast'
import MtChip from '@/components/UI/mtChip/index.vue'
import mtButton from '@/components/UI/mtButton/index.vue'


const { required } = useValidations()
const { toast } = useToast()

const rules = {
  message: {
    required,
  },
  theme: {
    required,
  },
}

const props = defineProps({
  apiProvider: {
    type: Function,
    default: () => null,
  },
})

const loading = ref(false)
const isDialogActive = ref(false)
const participants = ref([])
const payload = reactive({
  theme: '',
  message: '',
})

const v$ = useVuelidate(rules, payload)

function openModal(items: any) {
  participants.value = items
  isDialogActive.value = true
}

function closeModal() {
  participants.value = []
}

async function send() {
  const isValid = await v$.value.$validate()

  if (!isValid) return

  loading.value = true

  props
    .apiProvider({
      message: payload.message,
      theme: payload.theme,
      participantsUuids: participants.value.map(
        (participant) => participant.uuid,
      ),
    })
    .then(() => {
      toast.success('Сообщение успешно отправлено пользователям')
    })
    .finally(() => {
      payload.message = ''
      payload.theme = ''
      participants.value = []
      loading.value = false
      isDialogActive.value = false
    })
}

defineExpose({
  openModal,
})
</script>

<template>
  <mt-dialog
    size="md"
    v-model="isDialogActive"
    class="send-message-modal"
    @close="closeModal"
  >
    <h3 class="send-message-modal__title">Отправить сообщение</h3>

    <div class="send-message-modal__selected-users">
      <label>Выбранные пользователи:</label>
      <mt-chip v-for="participant in participants">
        {{ participant.full_name }}
      </mt-chip>
    </div>
    <app-input
      class="u-mb-4"
      v-model="payload.theme"
      :errors="v$.theme.$errors"
    >
      <template #label> Тема </template>
    </app-input>
    <app-textarea
      class="u-mb-4"
      v-model="payload.message"
      :errors="v$.message.$errors"
    >
      <template #label> Сообщение </template>
    </app-textarea>
    <mt-button @click="send()" :loading="loading" class="w-100">
      Отправить
    </mt-button>
  </mt-dialog>
</template>
