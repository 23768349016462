import api from "@/network";

export default {
    state: {
        administrators: [],

        administratorsSortConfig: {
            field: null,
            order: null,
        },
    },

    getters: {
        administrators(state) {
            return state.administrators;
        },

        administratorsSortConfig(state) {
            return state.administratorsSortConfig;
        },
    },

    actions: {
        async importUsers(context, file) {
            let fd = new FormData()
            fd.append('file', file);
            let result = await api.put('import/users', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return result
        },


        async exportAdministrators(context, payload) {
            let url = `export/users?type=admin&settings=${JSON.stringify(payload.settings)}`;

            return await api.get(url, {responseType: 'blob'});
        },

        async exportRespondents(context, payload) {
            let url = `export/users?type=user&settings=${JSON.stringify(payload.settings)}`;
            return await api.get(url, {responseType: 'blob'});
        },
        async fetchAdministrators({commit}) {
            const { data } = await api.get('admin/user?type=admin');

            commit('updateAdministrators', data?.data);
        },
        async exportBilling(context, payload) {
            let url = `export/billing?settings=${JSON.stringify(payload.settings)}`;
            return await api.get(url, {responseType: 'blob'});
        },

        /**
         *
         * @param { object } payload
         * {
         *    @param { string } uuid : "e0dde03b-becf-41f9-8e74-92fa224cb841",
         * }
         */
        async deleteAdministrator({commit, state}, payload = {}) {
            await api.delete(`admin/user/${payload.uuid}`);
            let administrators = state.administrators.filter((administrator) => {
                return administrator.uuid !== payload.uuid
            });

            commit('updateAdministrators', administrators);
        },

        async getAdministrators(context, payload) {
            let url = 'admin/user?type=admin';
            if (payload.page > 1) {
                url = `${url}&page=${payload.page}`

            }
            if (payload.per_page !== 0) {
                if (payload.page > 1) {
                    url = `${url}&per_page=${payload.per_page}`
                } else {
                    url = `${url}&per_page=${payload.per_page}`
                }
            }
            if (!!payload.settings) {
                url = `${url}&settings=${JSON.stringify(payload.settings)}`
            }
            return await api.get(url);
        }
    },

    mutations: {
        async updateAdministrators(state, administrators) {
            state.administrators = administrators;
        },
    },
}