import { createStore } from 'vuex';

import auth from './modules/auth/';
import assessmentPlans from './modules/workspace/assessmentPlans';
import administration from './modules/workspace/administration/index.js';
import results from './modules/workspace/results';
import test from './modules/workspace/test';
import report from './modules/workspace/report';
import breadcrumbs from './modules/workspace/breadcrumbs';

export default createStore(
  {
    modules: {
      auth,
      assessmentPlans,
      administration,
      results,
      test,
      report,
      breadcrumbs,
    }
  }
)
