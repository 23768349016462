import api from "@/network";

export default {
  state: {
    branch: {},
    branchTests: [],
  },

  getters: {
    branch(state) {
      return state.branch;
    },
    branchTests(state) {
      return state.branchTests;
    },
  },

  actions: {
    /* FETCHERS */
    async fetchBranch({ commit }, payload = {}) {
      const response = await api.get(`admin/branch/${payload.uuid}`);



      commit('updateBranch', response?.data?.data);
      return response
    },
    async fetchBranchTests({ commit }, {uuid,}) {
      const response = await api.get(`admin/branch/${uuid}/tests`);



      commit('updateBranchTests', response?.data?.data);

      return response
    },

    /* SETTERS STORE */
    async setBranchStore({ commit, state }, payload = {}) {
      commit('updateBranch', { ...state.branch, ...payload });
    },
    async resetBranchStore({ commit }) {
      commit('updateBranch', {});
      commit('updateBranchTests', []);
    },

    /* UPLOADERS */
    async createBranch({ commit, state }, payload = false) {

      let data = (!!payload) ? payload : state.branch
      const response = await api.post(`admin/branch`, data);



      commit('updateBranch', response?.data?.data);
      return response
    },
    async uploadBranch({ state }, payload = {}) {

      let data = (!!payload.branch) ? payload.branch : state.branch
      return await api.put(`admin/branch/${payload.uuid}`, data);
    },
    async updateBranch(context, payload) {
      return await api.put(`admin/branch/${payload.uuid}`, payload);
    },
    async updateBranchTests({getters,}) {
      return await api.put(
        `admin/branch/${getters.branch.uuid}/tests/status`,
        getters.branchTests.map((branchTest) => ({
          uuid: branchTest.uuid,
          status: branchTest.status,
        }))
      );
    },
  },

  mutations: {
    updateBranch(state, branch) {
      state.branch = branch;
    },
    updateBranchTests(state, branchTests) {
      state.branchTests = branchTests;
    },
  },
}