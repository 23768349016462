<template>
  <div class="steps-nav">
    <component
        v-for="(item, index) in computedSteps"
        :to="{
          name: item.to
        }"
        :is="item.tag"
        :key="index"
        :class="[
            'steps-nav__item',
            {
              'steps-nav__item--active': item.isActive,
              'steps-nav__item--isClickable': item.isClickable,
            }
        ]"
        @click="item.onClick"
    >
      <span class="steps-nav__item__order">
        {{index + 1}}
      </span>
      <span class="steps-nav__item__title">{{item.name}}</span>
    </component>
  </div>
</template>

<script setup lang="ts">

import type {Ref} from "vue";

type Step = {
  onClick?: () => void,
  isActive?: boolean,
  to?: String,
  name: String,
}

type ModifiedStep = Step & {
  tag: string,
  isClickable: boolean
}
interface Props {
  steps: Step[]
}

const props = defineProps<Props>();
const route = useRoute();
const computedSteps: Ref<ModifiedStep[]> = computed(() => {
  return props.steps.map((step) : ModifiedStep => {

    if(step.to) {
      step.isActive = route.name === step.to
    }

    let tag: string = 'div';
    let isClickable: boolean = false;

    if(step.to) {
      isClickable = true;
      tag = 'router-link'
    }

    if(step.onClick) {
      isClickable = true;
      tag = 'button';
    }

    return {
      ...step,
      tag,
      isClickable
    };
  })
})

</script>

<style lang="scss" src="./AppSteps.scss"/>