<template>
  <transition name="mt-dialog">
    <div
        class="mt-dialog"
        v-if="modelValue || openedByDefault"
        :class="[
            'mt-dialog',
            size ? 'mt-dialog_' + size : '',
        ]"
    >
      <div
          class="mt-dialog__overlay"
          @click="closeModal()"
      />
      <div class="mt-dialog__window">
        <div class="mt-dialog__window__wrapper">
          <button
              class="mt-dialog__close-btn"
              @click="closeModal()"
          >
            <close-icon color="#fff"/>
          </button>
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MtSvg from '@/components/UI/mtSvg/index.vue';
import CloseIcon from "@/components/UI/mtSvg/svg/CloseIcon.vue";

export default {
  name: "mtDialog",
  components: {CloseIcon, MtSvg},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    size: String,
    openedByDefault: Boolean
  },
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false)
      this.$emit('close')
    },
    closeOnEscape(event) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.closeOnEscape)
  },
  unmounted() {
    document.removeEventListener('keydown', this.closeOnEscape)

  }
}
</script>

<style
    lang="scss"
    src="./mtDialog.scss"
/>