<template>
  <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M14.6479 6.90027H11.6593V8.67355H14.6479C15.5366 8.67355 16.2262 9.12752 16.2262 9.51823V17.3816C16.2262 17.7723 15.5366 18.2262 14.6479 18.2262H3.35151C2.4628 18.2262 1.77329 17.7723 1.77329 17.3816V9.51852C1.77329 9.12781 2.4628 8.67385 3.35151 8.67385H6.33949V6.90056H3.35151C1.47212 6.90056 0 8.05054 0 9.51852V17.3819C0 18.8501 1.47212 19.9998 3.35151 19.9998H14.6482C16.5273 19.9998 17.9997 18.8498 17.9997 17.3819V9.51852C17.9994 8.05024 16.5273 6.90027 14.6479 6.90027Z"
        :fill="color"
    />
    <path
        d="M6.37571 4.41991C6.60269 4.41991 6.82937 4.33332 7.00256 4.16013L8.11264 3.05005V6.90044V8.67373V12.0708C8.11264 12.5605 8.50956 12.9574 8.99928 12.9574C9.48901 12.9574 9.88593 12.5605 9.88593 12.0708V8.67373V6.90044V2.99005L11.056 4.16013C11.2292 4.33332 11.4562 4.41991 11.6829 4.41991C11.9095 4.41991 12.1365 4.33332 12.3097 4.16013C12.6561 3.81404 12.6561 3.2525 12.3097 2.90641L9.66249 0.259195C9.4893 0.0860043 9.26262 0 9.03593 0C9.03357 0 9.0315 0 9.02913 0C9.02677 0 9.0247 0 9.02234 0C8.79565 0 8.56897 0.0860043 8.39578 0.259195L5.74856 2.90641C5.40217 3.2525 5.40217 3.81404 5.74856 4.16013C5.92204 4.33332 6.14873 4.41991 6.37571 4.41991Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000", // #00788C
    },
  }
}
</script>