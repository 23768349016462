<template>
  <transition name="slideInOutAnimation">
    <div v-show="modelValue">
      <div ref="content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "mtSlideInOutAnimation",
  props: {
    modelValue: Boolean
  },
  mounted () {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach((entry) => {
        if(entry.contentRect.height){
          this.$refs.content.parentElement.style.maxHeight = Math.round(entry.contentRect.height) + 'px'
        }
      })
    })

    resizeObserver.observe(this.$refs.content)
  }
}
</script>

<style lang="scss" src="./style.scss"/>