<template>
  <div class="app-title">
    <h2 class="app-title__heading">
      <slot name="title"/>
    </h2>
    <div class="app-title__btns">
      <slot name="buttons"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTitle"
}
</script>

<style lang="scss" src="./AppTitle.scss"/>