<template>
  <div class="auth__fields">
    <h4 class="auth__subtitle">Придумайте постоянный пароль к вашему аккаунту</h4>
    <div class="signup__email-view">{{ user.email }}</div>
    <app-input-password
        v-model="newPassword"
        label="Пароль"
        @keyup.enter="send()"
        class="finish-signup__password-field"
    />
    <password-rules
      :password-for-check="newPassword"
      ref="passwordRules"
    />
    <mt-button
        class="auth__actions__btn"
        @click="send()"
        :loading="requestPending"
    >
      <span>Продолжить</span>
    </mt-button>
  </div>

</template>
<script>
import PasswordRules from "@/components/workspace/auth/PasswordRules/index.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import api from "@/network/index.js";
import AuthApi from "@/api/auth.api.ts";
import mtButton from "@/components/UI/mtButton/index.vue";
import {mapMutations} from "vuex";
import useToast from "@/components/UI/AppToast/useToast.js";
import AppInputPassword from "@/components/UI/AppInputPassword.vue";
export default {
  components: {
    AppInputPassword,
    mtButton,
    PasswordRules,
    AppInput,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      newPassword: null,
      requestPending: false
    };
  },
  methods: {
    ...mapMutations(['UPDATE_USER_DATA']),
    send() {
      const { toast } = useToast();
      if(!this.$refs.passwordRules.allRulesAreValid) {
        toast.error('Пароль не соответствует требованиям');
        return;
      }

      this.requestPending = true;
      AuthApi.finishSignUpSetPermanentPassword(this.newPassword)
          .then((response) => {
            this.$emit('success');
          })
          .finally(() => {
            this.requestPending = false;
          });
    },
  },
};
</script>

<style lang="scss">
  .finish-signup {
    &__password-field {
      margin-bottom: 20px;
    }
  }
</style>