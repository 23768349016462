<template>
  <div class="step3">
    <app-data-table
        :headers="cols"
        header-title-key="title"
        header-value-key="stub"
        :items="computedInterpretation"
    >
      <template #title>
        Шаг 3. Настройка уровней интерпретации
      </template>
      <template #header-right>
        <mt-page-nav
            :next-btn-loading="requestPending"
            @back="goToBack()"
            @next="goToNext()"
        >
          <template #back>
            Назад
          </template>
          <template #next>
            Далее
          </template>
        </mt-page-nav>
      </template>
      <template #header-actions>
        <app-alert type="error" v-if="maxAvailablePoints === 0">
          На шаге №2 не выбрано ни одной шкалы в заданиях, добавленных в план оценки.<br/><br/>
          Для заданий типа "тест" будут доступны отчёты для скачивания, но не будет возможности рассчитать интегральный балл для всех типов заданий . Кликните на нужный шаг для возможности перехода вперед или назад.
        </app-alert>
      </template>

      <template #name="{index}">
        {{ index + 1 }}
      </template>
      <template #toPoints="{index, item}">
        <div class="step3__table__cell">
          <app-input-amount
              class="step3__input step3__input_percentage"
              v-model="item.to"
              hide-details
              @input="setValueForPoints(item)"
          />
          <cell-error
              :errors="item.errors.to"
          />
        </div>

      </template>
      <template #maxPoints="{index, item}">
        <div class="step3__table__cell">
          <app-input-amount
              class="step3__input step3__input_score"
              v-model="item.toPoints"
              hide-details
              @input="setValueForPercentage(item)"
          />
          <cell-error
              :errors="item.errors?.toPoints"
          />
        </div>
      </template>
      <template #result="{index, item}">
        <app-input
            class="step3__input"
            v-model="item.result"
            hide-details
        />
        <cell-error
            :errors="item.errors.result"
        />
      </template>
      <template #interpritation="{index, item}">
        <app-textarea
            class="step3__textarea"
            hide-details
            initial-height="36"
            :max-length="200"
            v-model="item.interpritation"
        />
        <cell-error
            :errors="item.errors.interpritation"
        />
      </template>

      <template #actions="{index}">
        <mt-button
            @click="deleteItem(index)"
            icon
            color="light-red"
        >
          <trash-icon />
        </mt-button>
      </template>
    </app-data-table>
    <mt-button
        class="step3__add-button"
        @click="addNewInterpretation()"
        v-if="maxAvailablePoints > 0"
    >
      <plus-icon />
      Добавить уровень интерпретации
    </mt-button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import mtPageNav from "@/components/composite/mtPageNav.vue";
import mtTable from "@/components/UI/mtTable/mtTable.vue";
import mtPreloader from "@/components/UI/mtPreloader/mtPreloader.vue";
import MtInputAmount from "@/components/UI/mtInputAmount/mtInputAmount.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import AppDataTable from "@/components/UI/AppDataTable/AppDataTable.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import PlansAdminApi from "@/api/plans-admin.api";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import AppInputAmount from "@/components/UI/AppInputAmount/AppInputAmount.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import SecondStepCellError from "@/components/workspace/assessmentPlans/plans/plan/step2/SecondStepCellError.vue";
import CellError from "@/components/workspace/assessmentPlans/plans/plan/CellError.vue";
import AppAlert from "@/components/UI/AppAlert/AppAlert.vue";

export default {
  components: {
    AppAlert,
    CellError,
    SecondStepCellError,
    AppTextarea,
    AppInput,
    AppInputAmount,
    PlusIcon,
    TrashIcon,
    AppDataTable,
    mtButton,
    MtInputAmount,
    mtPageNav,
    mtTable,
    mtPreloader,
  },

  data() {
    return {
      maxAvailablePoints: 0,
      toPoints: 100,
      requestPending: false,
      errorsMessages: {},
      interpritations: [],
      cols: [
        {
          title: "№",
          stub: "name",
          visible: true,
        },

        {
          title: "До, %",
          stub: "toPoints",
          visible: true,

        },

        {
          title: "До, баллы",
          stub: "maxPoints",
          visible: true,

        },

        {
          title: "Оценка",
          stub: "result",
          visible: true,

        },

        {
          title: "Интерпретация",
          stub: "interpritation",
          visible: true,

        },
      ],

      /* VALIDATION PARAMS */
      percentField: {
        field: 'to',
        rules: [
          {rule: 'required', message: 'Заполните поле',},
          {rule: 'checkStep3PercentVal', message: 'Нарушена граница',},
          {rule: 'checkStep3PercentMax', message: 'Отсутствует максимум (100)',},
        ],
      },
      resultField: {field: 'result', rules: [{rule: 'required', message: 'Заполните поле'}]},
      interpritationField: {field: 'interpritation', rules: [{rule: 'required', message: 'Заполните поле'}]},

      validationErrors: {
        required: () => {
          return `Поле не заполнено`;
        }
      }
    };


  },

  computed: {
    ...mapGetters('assessmentPlans/plan', ["planEditdataExd"]),
    hasErrors() {
      // check if errors exist
      return this.interpritations.some(item => {
        return item.errors.result.length ||
            item.errors.to.length ||
            item.errors.toPoints.length ||
            item.errors.interpritation.length;
      });
    },
    computedInterpretation(){
      if(!this.interpritations.length || !this.maxAvailablePoints) return [];

      return this.interpritations.map(item => {
        item.errors = {
          to: [],
          toPoints: [],
          result: [],
          interpritation: [],
        };

        if(!item.toPoints) item.errors.to.push(this.validationErrors.required());
        if(!item.result) item.errors.result.push(this.validationErrors.required());

        if(!item.to) item.errors.to.push(this.validationErrors.required());
        if(item.to > 100) item.errors.to.push('Максимальный значение поля не должен превышать 100');

        if(!item.interpritation) item.errors.interpritation.push(this.validationErrors.required());

        return item;
      })
    }
  },

  methods: {
    goToBack() {
      this.$router.push({
        name: "AddEdit2",
      });
    },
    goToNext() {
      if (!this.interpritations.length) {
        this.$toast.error('Необходимо настроить уровни интерпретации')
        return;
      }

      if(this.hasErrors) {
        this.$toast.error('Форма заполнена некорректно, пожалуйста введите корректные данные');
        return;
      }

      this.requestPending = true;
      PlansAdminApi.updateInterpretation(this.planEditdataExd.uuid, this.interpritations)
          .then((response) => {
            this.requestPending = false;
            this.$router.push({
              name: "AddEdit4",
            });
          })
    },
    addNewInterpretation() {
      this.interpritations.push({
        to: null,
        toPoints: null,
        result: null,
        interpritation: null,
      });
    },
    deleteItem(index) {
      this.interpritations.splice(index, 1);
    },


    getMaxAvailablePoints() {
      this.maxAvailablePoints = this.planEditdataExd.structure.reduce((acc, item) => {
        const criteriaMaxes = item.parameters.criteria.map(criteria => Number(criteria.max) * criteria.weight);
        return acc + criteriaMaxes.reduce((sum, current) => sum + current, 0);
      }, 0);
    },

    setValueForPercentage(item) {
      item.to = (item.toPoints * 100) / this.maxAvailablePoints;
    },

    setValueForPoints(item) {
      item.toPoints = this.toScoresLocal(item.to);
    },

    toScoresLocal(to) {
      return (Number(to) / 100) * Number(this.maxAvailablePoints);
    },
  },
  created() {
    this.getMaxAvailablePoints();

    this.interpritations = this.planEditdataExd.interpritation.map(item => ({
      ...item,
      toPoints: this.toScoresLocal(item.to),
    }));
  },
};
</script>

<style lang="scss">
.step3 {
  &__table {
    &__cell {
      position: relative;
    }
  }
  .app-data-table {
    td {
      position: relative;
    }
  }
  &__add-button {
    width: 100%;
    margin-top: 30px;
  }
  &__input {
    width: calc(100% - 30px);
    &_percentage {
      width: 60px;
    }
    &_score {
      width: 100px;
    }
    .app-input__field {
      height: 36px;
      font-size: 12px;
      background-color: $light-gray;
      border-color: $light-gray;

    }

  }
  &__textarea {
    width: calc(100% - 30px);
    textarea {
      height: 36px;
      font-size: 12px;
      width: 100%;
      background-color: $light-gray;
      border-color: $light-gray;
    }
  }
}
</style>