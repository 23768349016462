<template>
  <div class="mt-svg">
    <component
        :is="getIconCmpName({ name, })"
        class="mt-svg__icon"
        v-show="!preloader"
        :color="color"
        :width="width"
        @click="svgClickHandler"
    />
    <component
        :is="getIconCmpName({ name: preloaderName, })"
        class="mt-svg__loader"
        v-show="preloader"
        :color="preloaderColor"
    />
  </div>
</template>

<script>
import Failed from './svg/Failed.vue';
import Delete from './svg/Delete.vue';
import Preloader from './svg/Preloader.vue';
import Settings from './svg/Settings.vue';
import Loader from './svg/Loader.vue';
import Cogwheel from './svg/Cogwheel.vue';
import File from './svg/File.vue';
import ArrowVector from './svg/ArrowVector.vue';
import ExportSvg from './svg/Export.vue';
import Users from './svg/Users.vue';
import TeamLine from './svg/TeamLine.vue';
import Timer from './svg/Timer.vue';
import TimerFilled from './svg/TimerFilled.vue';
import Finished from './svg/Finished.vue';
import Play from './svg/Play.vue';
import Group from './svg/Group.vue';
import Refresh from './svg/Refresh.vue';
import RefreshBg from './svg/RefreshBg.vue';
import Department from './svg/Department.vue';
import Lock from './svg/Lock.vue';
import TwoTicks from './svg/TwoTicks.vue';
import Download from './svg/Download.vue';
import Paper from './svg/Paper.vue';
import Close from './svg/Close.vue';
import Submit from './svg/Submit.vue';
import Link from './svg/Link.vue';
import Star from './svg/Star.vue';
import Search from './svg/Search.vue';
import UserEdit from './svg/UserEdit.vue';
import Exit from './svg/Exit.vue';
import AlignLeft from './svg/AlignLeft.vue';
import AlignRight from './svg/AlignRight.vue';
import Pencil from './svg/Pencil.vue';
import Upload from './svg/Upload.vue';
import TestExpert from "@/components/UI/mtSvg/svg/TestExpert.vue";
import AlignBottom from "@/components/UI/mtSvg/svg/AlignBottom.vue";

export default {
  name: 'mtSvg',
  components: {
    Pencil,
    TeamLine,
    Delete,
    Preloader,
    Settings,
    Loader,
    Cogwheel,
    File,
    ArrowVector,
    ExportSvg,
    Users,

    Timer,
    TimerFilled,
    Finished,
    Play,
    Group,
    Refresh,
    RefreshBg,
    Department,
    Lock,
    TwoTicks,
    Download,
    Paper,
    Close,
    Submit,
    Link,
    Star,
    Search,
    UserEdit,
    Exit,
    AlignLeft,
    AlignRight,
    AlignBottom,

    Failed,
    Upload,
    TestExpert
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#000000",
    },
    preloader: {
      type: Boolean,
      default: false,
    },
    preloaderColor: {
      type: String,
      default: "#000000",
    },
    preloaderName: {
      type: String,
      default: 'Preloader',
    },
    preloaderRotation: {
      type: String,
      default: 'right',
    },
    width: [String, Number]
  },
  data: () => ({}),
  computed: {},

  watch: {},
  methods: {
    /* GETTERS */
    getIconCmpName({ name, }) {
      if(!name) return;

      switch (name.toLowerCase()) {
        case 'pencil':
          return 'Pencil';

        case 'failed':
          return 'Failed';

        case 'delete':
          return 'Delete';

        case 'preloader':
          return 'Preloader';

        case 'settings':
          return 'Settings';

        case 'loader':
          return 'Loader';

        case 'cogwheel':
          return 'Cogwheel';

        case 'file':
          return 'File';

        case 'team-line':
          return 'TeamLine';

        case 'play':
          return 'Play';

        case 'timer':
          return 'Timer';

        case 'timerfilled':
          return 'TimerFilled';

        case 'finished':
          return 'Finished';

        case 'arrowvector':
          return 'ArrowVector';

        case 'export':
          return 'ExportSvg';

        case 'users':
          return 'Users';

        case 'group':
          return 'Group';

        case 'refresh':
          return 'Refresh';

        case 'refresh-bg':
          return 'RefreshBg';

        case 'department':
          return 'Department';

        case 'lock':
          return 'Lock';

        case 'twoticks':
          return 'TwoTicks';

        case 'download':
          return 'Download';

        case 'paper':
          return 'Paper';

        case 'close':
          return 'Close';

        case 'submit':
          return 'Submit';

        case 'link':
          return 'Link';

        case 'star':
          return 'Star';

        case 'search':
          return 'Search';

        case 'useredit':
          return 'UserEdit';

        case 'exit':
          return 'Exit';

        case 'alignleft':
          return 'AlignLeft';

        case 'alignright':
          return 'AlignRight';

        case 'alignbottom':
          return 'AlignBottom';

        case 'upload':
          return 'Upload';

        case 'testexpert':
          return 'TestExpert';

        default:
          return null;
      }
    },

    /* SETTERS */
    /* HANDLERS */
    svgClickHandler() {
      this.$emit('svg-click');
    },

    /* HELPERS */
  },

  created() { },
  mounted() { },
}
</script>

<style lang="scss">
.mt-svg {
  display: flex;
  &__icon {
    transition: all ease-in-out 0.2s;
  }
  &__loader {
    animation: spin infinite linear 1s;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>