<script setup lang="ts">

</script>

<template>
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.414 16L16.556 5.85796L15.142 4.44396L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32196C14.6225 2.13449 14.8768 2.02917 15.142 2.02917C15.4072 2.02917 15.6615 2.13449 15.849 2.32196L18.678 5.15096C18.8655 5.33849 18.9708 5.5928 18.9708 5.85796C18.9708 6.12313 18.8655 6.37743 18.678 6.56496L7.243 18ZM3 20H21V22H3V20Z"
        fill="currentColor"
    />
  </svg>
</template>