export const USER_ROLES = [
    {
        "uuid": "7f706760-10e8-4bf0-b2a5-14ad1eab8379",
        "type": "user",
        "name": "Респондент",
        "slug": "respondent"
    },
    {
        "uuid": "4f4851cd-b135-43cd-8e13-9fd146d5182b",
        "type": "user",
        "name": "Эксперт",
        "slug": "expert"
    },
    /*
    {
        "uuid": "e2f5503e-cc40-452a-b20f-538a9dc4cf15",
        "type": "user",
        "name": "Участник",
        "slug": "user"
    },
     */
    {
        "uuid": "2f546d24-47a9-408d-8f0e-9587629243c0",
        "type": "user",
        "name": "Проверяющий",
        "slug": "inspector"
    }
];

export const ADMIN_ROLES = [
    /*{
        "uuid": "59f30a6b-8506-4a7f-837c-1819840342b3",
        "type": "admin",
        "name": "Оператор",
        "slug": "operator"
    },
    {
        "uuid": "9f16c5a8-52a2-4dde-a92c-d213faae9dac",
        "type": "admin",
        "name": "Методолог",
        "slug": "metodolog"
    },*/
    {
        "uuid": "e7dfa244-28b8-4bc1-aedd-c66806705979",
        "type": "admin",
        "name": "Админ",
        "slug": "admin"
    }
]