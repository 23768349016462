<script
    setup
    lang="ts"
>
import AppRangeSlider from "@/components/UI/AppRangeSlider.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import debounce from "lodash/debounce";

const props = defineProps<{
  modelValue: number | undefined;
  data?: number[];
  min?: number;
  max?: number;
  step?: number;
}>();
const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
  get: () => props.modelValue,
  set: (value: number) => emit('update:modelValue', value)
});


/**
 * Корректирует значение с учётом min, max и step.
 */
function adjustToStep(value: number): number {
  const stepMultiplier = 1 / props.step;
  // Округляем вверх для более высоких значений
  const adjustedValue = Math.ceil((value - props.min) * stepMultiplier) / stepMultiplier + props.min;

  // Ограничение значения в пределах min и max
  return Math.min(Math.max(adjustedValue, props.min), props.max);
}

/**
 * Обрабатывает ввод с задержкой (debounce).
 */
const debouncedInputHandler = debounce((value: number, input: HTMLInputElement) => {
  if (input.value === '') return;

  const adjustedValue = adjustToStep(value);
  if (adjustedValue !== props.modelValue) {
    input.value = adjustedValue.toString();
    emit('update:modelValue', adjustedValue);
  }
}, 300);

/**
 * Основной обработчик ввода.
 */
function handleInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let rawValue = input.value;

  // Убираем все символы, кроме цифр и точки
  rawValue = rawValue.replace(/[^0-9.]/g, '');

  // Если строка пустая, не запускаем debounce
  if (rawValue === '') {
    debouncedInputHandler.cancel();
    return;
  }

  // Если значение заканчивается на точку и перед точкой есть цифры, оставляем её
  if (rawValue.endsWith('.') && rawValue.length > 1 && !isNaN(Number(rawValue.slice(0, -1)))) {
    input.value = rawValue;
    debouncedInputHandler.cancel();
    return;
  }

  // Проверка на допустимость (не больше одной точки)
  if ((rawValue.match(/\./g) || []).length > 1) {
    return; // Если точка встречается более одного раза, не обрабатываем
  }

  // Конвертируем строку в число
  const numericValue = parseFloat(rawValue);

  // Если значение корректное, вызываем debounced обработчик
  if (!isNaN(numericValue)) {
    debouncedInputHandler(numericValue, input);
  } else {
    // Если введено некорректное число, оставляем исходное значение
    input.value = rawValue;
  }
}
</script>

<template>
  <div class="scale-mark-range">
    <app-range-slider
        v-model="computedModelValue"
        :min="props.min"
        :max="props.max"
        :step="props.step"
        :data="props.data"
    />

    <app-input
        :value="computedModelValue"
        small
        hide-details
        class="scale-mark-range__input"
        @input="handleInput"
    />
  </div>
</template>

<style lang="scss">
.scale-mark-range {
  flex-grow: 1;
  padding: 0 10px;

  &__input {
    width: 50px;
    margin: 30px auto 0;

    .app-input__field {
      text-align: center;
    }
  }
}
</style>