<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.95841 6.79163C3.53983 6.79163 3.12534 6.70918 2.73861 6.54899C2.35189 6.38881 2.0005 6.15402 1.70451 5.85803C1.40853 5.56204 1.17374 5.21065 1.01355 4.82393C0.853362 4.4372 0.770915 4.02271 0.770915 3.60413C0.770915 3.18554 0.853362 2.77105 1.01355 2.38432C1.17374 1.9976 1.40853 1.64621 1.70451 1.35022C2.0005 1.05424 2.35189 0.819447 2.73861 0.65926C3.12534 0.499073 3.53983 0.416626 3.95841 0.416626C4.80379 0.416626 5.61455 0.752451 6.21232 1.35022C6.81009 1.948 7.14592 2.75875 7.14592 3.60413C7.14592 4.4495 6.81009 5.26026 6.21232 5.85803C5.61455 6.4558 4.80379 6.79163 3.95841 6.79163V6.79163ZM11.3959 9.62496C10.6445 9.62496 9.9238 9.32645 9.39245 8.7951C8.86109 8.26374 8.56258 7.54307 8.56258 6.79163C8.56258 6.04018 8.86109 5.31951 9.39245 4.78816C9.9238 4.2568 10.6445 3.95829 11.3959 3.95829C12.1474 3.95829 12.868 4.2568 13.3994 4.78816C13.9307 5.31951 14.2292 6.04018 14.2292 6.79163C14.2292 7.54307 13.9307 8.26374 13.3994 8.7951C12.868 9.32645 12.1474 9.62496 11.3959 9.62496ZM11.3959 10.3333C12.2413 10.3333 13.052 10.6691 13.6498 11.2669C14.2476 11.8647 14.5834 12.6754 14.5834 13.5208V13.875H8.20842V13.5208C8.20842 12.6754 8.54424 11.8647 9.14201 11.2669C9.73978 10.6691 10.5505 10.3333 11.3959 10.3333V10.3333ZM3.95841 7.49996C4.42351 7.49996 4.88406 7.59157 5.31375 7.76955C5.74345 7.94754 6.13388 8.20842 6.46275 8.53729C6.79163 8.86616 7.0525 9.25659 7.23049 9.68629C7.40847 10.116 7.50008 10.5765 7.50008 11.0416V13.875H0.416748V11.0416C0.416748 10.1023 0.789887 9.20148 1.45408 8.53729C2.11827 7.8731 3.01911 7.49996 3.95841 7.49996V7.49996Z" fill="#00788C"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>