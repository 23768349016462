<template>
  <assessment-plans-rows :rows="rows">
    <template #item-statistics="assessmentPlan">
      <statistics-amount
          class="assessment-plans-card__amount"
          :amount="assessmentPlan.participantsAmount"
      />
    </template>
  </assessment-plans-rows>
</template>

<script>
import assessmentPlansRows from '@/components/workspace/assessmentPlans/plans/rows/index.vue';
import statisticsAmount from '@/components/workspace/assessmentPlans/plans/statisticsAmount/index.vue';

export default {
  components: {statisticsAmount, assessmentPlansRows},
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      assessmentPlans: []
    }
  },
}
</script>