<template>
  <div class="table__empty">
    <svg
        :data-src="icon ?? '/img/icons/empty.svg'"
        class="table__empty__icon"
    />
    <div class="table__empty__title">
      {{ title ?? 'Записей пока нет' }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "InfoBlock",
  props: {
    icon: String,
    title: String
  }
})
</script>

<style lang="scss">
.table__empty {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 15px;
  color: #9FA2B4;

  &__icon {
    width: 35px;
  }
}
</style>