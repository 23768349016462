<template>
  <div class="mobile-text">
    <div
        ref="content"
        :class="computedClasses"
        :style="computedStyleForContent"
    >
      <div style="position: relative">
        <slot />
      </div>
    </div>
    <div
        class="mobile-text__btn"
        v-if="contentNotShowingFully"
        @click="show = !show"
    >
      {{ btnLabel }}
    </div>
  </div>
</template>
<script>
export default {
  name: "mtShowMoreTextMobile",
  props: {
    height: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      dataHeight: 0,
      scrollHeight: 0,
      contentNotShowingFully: false,
      show: false
    }
  },
  computed: {
    btnLabel () {
      return this.show ? 'скрыть' : 'еще';
    },
    computedClasses () {
      const classes = ['mobile-text__content'];
      if(this.show) classes.push('mobile-text__content_show');
      if(this.contentNotShowingFully) classes.push('mobile-text__content_expandable');

      return classes;
    },
    computedStyleForContent() {
      return {
        height: this.height
      }
    }
  },

  mounted () {
    const content = this.$refs.content;
    const dataHeight = content.clientHeight;
    const scrollHeight = content.scrollHeight;

    if(scrollHeight > dataHeight) {
      this.contentNotShowingFully = true;
    }
  }
}
</script>

<style lang="scss">
.mobile-text{
  position: relative;
  &__content {
    overflow: hidden;
    position: relative;

    &_expandable {
      &:after {
        content: '...';
        position: absolute;
        bottom: -3px;
        right: 0;
        background: #fff;
        padding: 3px;
      }
    }
    &_show {
      height: auto!important;
      &:after {
        display: none;
      }
    }
  }
  &__btn {
    color: $green;
    margin-top: 4px;
    text-decoration: underline;
  }
}
</style>