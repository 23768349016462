<template>
  <div class="auth-main-layout">
    <div class="left-background-picture">
      <img
          class="left-background-picture__item"
          src="@/assets/img/svg/left-background-picture.svg"
      />
    </div>
    <div class="right-background-picture">
      <img
          class="right-background-picture__item"
          src="@/assets/img/svg/right-background-picture.svg"
      />
    </div>
    <main>
      <slot/>
    </main>
  </div>
</template>

<style lang="scss">

.auth-main-layout {
  .left-background-picture {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;
  }

  .right-background-picture {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
  }
}

</style>
