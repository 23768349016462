<template>
  <input
      @input="onInput"
      :value="value"
  />
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue';

// Define the props with TypeScript types
interface Props {
  input?: (event: Event) => void;
  value?: string | number;
  modelValue?: string | number;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const isInputActive = ref<boolean>(false);

function onInput(event: Event): void {
  const target = event.target as HTMLInputElement;
  let regexpStr = /[A-Za-zА-Яа-яЁё,_\s+]/g;

  target.value = target.value.replace(regexpStr, "");

  // disable input if first char is dot
  if(target.value[0] === ".") {
    target.value = "";
  }

  // check if input contains any other dot, then remove it
  if(target.value.split(".").length > 2) {
    target.value = target.value.slice(0, -1);
  }

  // allow only two digits after dot
  if(target.value.split(".").length > 1 && target.value.split(".")[1].length > 2) {
    target.value = target.value.slice(0, -1);
  }

  // check if input's first char is zero, and other are numbers, then remove zero, but if second char is dot, then do nothing
  if(target.value.length > 1 && target.value[0] === "0" && target.value[1] !== ".") {
    target.value = target.value.slice(1);
  }


  if(props.input) props.input(event);
  emit("update:modelValue", target.value);
}

// Expose reactive data and methods
defineExpose({
  isInputActive,
  onInput,
});
</script>
<style lang="scss" src="./AppAmountInput.scss"/>