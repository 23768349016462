<template>
  <auth-wrapper class="signup">
    <template #title>Дозавершение регистрации</template>
    <template #body>
      <app-steps :steps="steps"/>
      <finish-sign-up-set-password-step
          v-if="step === SET_PASSWORD"
          :user="authUser"
          @success="step = PERSONAL_DATA"
      />

      <!-- TODO: make separate component for second step too -->
      <div v-if="step === PERSONAL_DATA">
        <div class="auth__subtitle">Вы почти закончили регистрацию! Пожалуйста, заполните анкету пользователя</div>

        <user-form-builder
          :handler="send"
          :user="authUser"
          ref="userFormBuilder"
          @change="buildFullNameFromForm"
          handler-button-text="Завершить регистрацию"
          :disable-button="!isConfidentialityAndAgreementChecked"
        >
          <template #prepend-buttons>
            <app-checkbox v-model="personalDataChecked">
              <template #label>
                  <span class="agreement-label">
                    Соглашаюсь с условиями обработки персональных данных
                  </span>
                <button class="agreement-link" @click="showPersonalDataText = true">(показать)</button>
              </template>
            </app-checkbox>
            <app-checkbox v-model="confidentialityChecked">
              <template #label>
                  <span class="agreement-label">
                      Соглашаюсь с условиями соблюдения конфиденциальности
                  </span>
                <button class="agreement-link" @click="showConfidentialityText = true">(показать)</button>
              </template>
            </app-checkbox>

            <div class="agreement-policy">
              <app-tooltip>
                <template #activator>
                  <mt-button
                      icon
                      href="/policy.docx"
                      target="_blank"
                      download="privacy.docx"
                      class="agreement-policy__btn"
                  >
                    <download-icon />
                  </mt-button>
                </template>
                Скачать
              </app-tooltip>
              <span class="agreement-policy__label">
                Политика информационной безопасности в отношении обработки персональных данных
              </span>
            </div>
          </template>
          <template #append-buttons>
            <mt-button
              color="light-red"
              class="signup__logout"
              @click="logoutDialogActivator = true"
            >
              Выйти
            </mt-button>
          </template>
        </user-form-builder>
        <confirmation-dialog
          :handler="logOut"
          v-model="logoutDialogActivator"
          :handler-button-text="`Выйти`"
          :title="`Вы уверены, что хотите выйти с аккаунта?`"
          :text="`Процесс регистрации еще не завершен`"
        />
      </div>

    </template>
  </auth-wrapper>

	<mt-dialog size="lg" v-model="showPersonalDataText">
		<div
      v-html="computedPersonalDataText"
      class="agreement-text"
    />
	</mt-dialog>

	<mt-dialog size="lg" v-model="showConfidentialityText">
		<div
      v-html="authUser.confidentialityText"
      class="agreement-text"
    />
	</mt-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AuthWrapper from '@/components/workspace/auth/AuthWrapper/index.vue';
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import AuthApi from '@/api/auth.api.ts';
import PasswordRules from "@/components/workspace/auth/PasswordRules/index.vue";
import FinishSignUpSetPasswordStep from "@/components/workspace/auth/FinishSignUpForm/FinishSignUpSetPasswordStep.vue";
import UserFormBuilder from "@/components/workspace/UserFormBuilder.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";
import AppSteps from "@/components/UI/AppSteps/AppSteps.vue";
import AuthTokenCache from "@/cache/auth-token.cache";
import ConfirmationDialog from "@/components/composite/confirmationDialog/confirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
    AppSteps,
    AppCheckbox,
    MtButton,
    UserFormBuilder,
    FinishSignUpSetPasswordStep,
    PasswordRules,
    AuthWrapper,
    MtDialog,
    AppTooltip,
    DownloadIcon,
  },
  data() {
    return {
      logoutDialogActivator: false,
      SET_PASSWORD: 1,
      PERSONAL_DATA: 2,
      step: 1,

			showPersonalDataText: false,
			showConfidentialityText: false,
			personalDataChecked: false,
			confidentialityChecked: false,

      fullName: ''
    }
  },

  computed: {
    ...mapGetters(['authUser']),

    computedPersonalDataText() {
      let text = this.authUser.personalDataTemplateText ?? '';
      return text.replace('%RESPONDER%', this.fullName);
    },

    steps() {
      return [
        {
          isActive: this.step === this.SET_PASSWORD,
          name: 'Установка пароля'
        },
        {
          isActive: this.step === this.PERSONAL_DATA,
          name: 'Анкета пользователя'
        }
      ]
    },

    isConfidentialityAndAgreementChecked() {
      return this.personalDataChecked && this.confidentialityChecked;
    },
  },

  methods: {
    ...mapActions(['getAuthUser']),
    logOut() {
      AuthTokenCache.clear()
      this.$router.push({ name: 'Signin' })
    },
    buildFullNameFromForm(fields) {
      this.fullName = `${fields.firstname?.modelValue ?? ''} ${fields.lastname.modelValue ?? ''} ${fields.middlename.modelValue ?? ''}`.trim()
    },
    async send(payload) {
      AuthApi.finishSignUpSaveSettings({
          ...payload,
          personalDataText: this.computedPersonalDataText,
          confidentialityText: this.authUser.confidentialityText,
        })
        .then(async () => {
          await this.getAuthUser();
          this.$router.push('/');
        });
    },
  },

  async created() {
    if(this.authUser.status !== 'wait') {
      this.$router.push('/');
    }
    if(this.authUser.registrationStep === 'personal_data') {
      this.step = this.PERSONAL_DATA;
    }
    if(this.authUser.registrationStep === 'set_password') {
      this.step = this.SET_PASSWORD;
    }
  },

}
</script>

<style lang="scss">
.signup {
  &__logout {
    width: 100%;
    padding: 0 20px;
    font-size: 16px;
    margin-top: 15px;
    height: 50px;
  }
  .app-form-builder__btn {
    width: 100%;
    height: 50px;
  }
  &__additional-fields {
    &__title {
      margin-bottom: 15px;
      text-align: center;
    }

  }
  &__email-view {
    border: 1px solid #C2C8D0;
    background: #eee;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    margin-bottom: 30px;
    display: flex;
    font-size: 14px;
    align-items: center;
    padding: 0 12px;
  }
}

.agreement-policy {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #7C869D;
  margin-bottom: 24px;
  &__btn {
    margin-right: 8px;
  }
}
.agreement-link {
  color: rgba(7, 124, 146, .95);

  &:hover {
    color: rgba(0, 120, 140, 1);
  }
}
</style>
