<template>
  <div class="tests-library--test-settings">
    <Testing class="tests-library--test-settings__testing"/>
    <Report class="tests-library--test-settings__report"/>
  </div>
</template>

<script>
import { mapActions, } from "vuex";
import { inputSettingsAdapter, } from '@/adapters/testLibrary/tests/testSettings.adapter';
import Testing from '@/components/workspace/testLibrary/tests/item/settings/Testing/index.vue';
import Report from '@/components/workspace/testLibrary/tests/item/settings/Report/index.vue';

export default {
  components: {
    Testing,
    Report,
  },
  data: () => ({
    settings: null,
  }),
  computed: {
    testSettings() {
      return this.$store.getters['test/settings'];
    },
  },

  watch: {},
  methods: {
    ...mapActions('test', ['fetchTestSettings', 'setTestsSettingsStore']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    /* GETTERS */
    async getSettings() {
      this.setTestsSettingsStore(
          inputSettingsAdapter(
              await this.fetchTestSettings({
                uuidTest: this.$route.params.uuid,
              })
          )
      );
    },

    async initBreadcrumbs() {
      if(!this.$route.meta.breadcrumbs) return;

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        { value: this.$route.query.testName }
      ])
    },
  },

  created() {
    this.getSettings();
  },
  mounted() {
    this.initBreadcrumbs();
  },
}
</script>

<style lang="scss">
.tests-library--test-settings {
  max-width: 1121px;

  &__report,
  &__export-to-excel {
    margin-top: 20px;
  }
}
</style>