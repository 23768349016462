<template>
  <div class="mt-res-download-stub">
    <div class="mt-res-download-stub__logo">
      <svg
          class="mt-res-download-stub__icon"
          width="60"
          height="54"
          viewBox="0 0 60 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M32.9166 21.1667H47.4999L29.9999 38.6667L12.4999 21.1667H27.0833V0.75H32.9166V21.1667ZM6.66659 47.4167H53.3333V27H59.1666V50.3333C59.1666 51.1069 58.8593 51.8488 58.3123 52.3957C57.7653 52.9427 57.0235 53.25 56.2499 53.25H3.74992C2.97637 53.25 2.2345 52.9427 1.68752 52.3957C1.14054 51.8488 0.833252 51.1069 0.833252 50.3333V27H6.66659V47.4167Z"
            fill="white"
        />
      </svg>
    </div>
    <div class="mt-res-download-stub__title">Загрузка началась автоматически</div>
    <div class="mt-res-download-stub__description">если этого не произошло,
      <span
        class="mt-res-download-stub__description--download-repeat"
        @click="download"
    >
      {{ ' нажмите сюда' }}
    </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {},
  data: () => ({}),
  computed: {},

  watch: {},
  methods: {
    /* GETTERS */
    /* SETTERS */
    /* HANDLERS */
    download() {
      this.$emit('download');
    },

    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.mt-res-download-stub {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;

  &__logo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 148px;
    background: #C5C7CD;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 100px;
  }

  &__icon {
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #252733;
    margin-top: 25px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;

    &--download-repeat {
      color: #00687C;
      cursor: pointer;
    }
  }
}
</style>