<script setup lang="ts">

import {computed} from "vue";

type Status = {
  [key: string]: {
    label: string,
    color: string,
  }
}

const props = defineProps({
  value: {
    type: String,
    default: '',
  }
})


const status: Status = {
  active: {
    label: 'Активный',
    color: 'light-green'
  },
  block: {
    label: 'Заблокирован',
    color: 'light-red'
  },
  wait: {
    label: 'Ожидает',
    color: 'light-brown'
  },
}

const activeStatus = computed(() => {
  return status[props.value];
})

</script>

<template>
  <span>{{ activeStatus.label }}</span>
</template>
