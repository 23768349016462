<template>
  <mt-popover
      v-model="computedVisibility"
      style="max-width: none;"
  >
    <div class="mt-download-report">
      <div class="mt-download-report__title">
        <svg
            class="mt-download-report__title-arrow"
            v-show="step !== THIRDSTEP && step !== FIRSTSTEP"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="setStep({ step: step - 1 })"
        >
          <path
              d="M2.82795 7.00001L7.77795 11.95L6.36395 13.364L-4.522e-05 7.00001L6.36396 0.636013L7.77796 2.05001L2.82795 7.00001Z"
              fill="#2A4365"
          />
        </svg>
        <div
            class="mt-download-report__title-text"
            v-show="step === FIRSTSTEP"
        >
          Варианты отчета
        </div>
        <div
            class="mt-download-report__title-text"
            v-show="step === SECONDSTEP"
        >
          Формат отчета
        </div>
      </div>
      <div class="mt-download-report__views">
        <ReportOptions
            v-show="step === FIRSTSTEP"
            @next="setStep({ step: SECONDSTEP })"
            @download="download"
            @downloadHTML="downloadHTML"
            :options="reports"
        />
        <DownloadOptions
            v-show="step === SECONDSTEP"
            :isGroup="isGroup"
            @download="download({ step: THIRDSTEP, format: $event.format })"
        />
        <DownloadStub
            v-show="step === THIRDSTEP"
            @download="downloadRepeat"
        />
      </div>

      <mt-button
          class="mt-download-report__next"
          v-show="step !== THIRDSTEP && step !== SECONDSTEP"
          @click="next"
      >
        Далее
      </mt-button>
    </div>
  </mt-popover>
</template>

<script>
import mtPopover from "@/components/UI/mtPopover/mtPopover.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import DownloadOptions from "./DownloadOptions/index.vue";
import DownloadStub from "./DownloadStub/index.vue";
import ReportOptions from "./ReportOptions/index.vue";

export default {
  name: 'downloadReportPopover',
  components: {
    mtPopover,
    mtButton,
    DownloadOptions,
    DownloadStub,
    ReportOptions,
  },
  emits: ['close', 'download', 'downloadHTML'],
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    reports: {
      type: Array,
      default: () => ([]),
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    FIRSTSTEP: 1,
    SECONDSTEP: 2,
    THIRDSTEP: 3,
    format: null,
  }),
  computed: {
    computedVisibility: {
      get() {
        return this.visibility
      },
      set(newVal) {
        if(!newVal) {
          this.$emit('close');

          this.step = this.FIRSTSTEP;
        }
      }
    }
  },

  watch: {
    visibility(newVisibility) {
      if (!newVisibility) {
        this.$emit('close');

        this.step = this.FIRSTSTEP;
      }
    },
    step(newStep) {
      if (newStep === this.THIRDSTEP) {

      }
    },
  },
  methods: {
    /* GETTERS */
    /* SETTERS */

    /* HANDLERS */
    next() {


      if (
          this.step === this.FIRSTSTEP &&
          !this.reports
              .filter(report => report.selected)
              .length
      ) {
        this.$toast.warning('Необходимо выбрать, как минимум, один отчёт.');

        return;
      }

      this.setStep({step: this.step + 1})
    },
    setStep({step,}) {
      if (!step) {
        return;
      }

      this.step = step;
    },
    download({step, format,}) {
      this.format = format;

      this.setStep({step,});
      this.$emit(
          'download',
          {
            format,
            variants: this.reports.filter(report => report.selected)
                .map(report => report.variant),
            titles: this.reports.filter(report => report.selected)
                .map(report => report.title),
          }
      );
    },
    downloadHTML({variant}) {
      this.$emit(
          'downloadHTML',
          {
            variant: variant
          }
      );
    },
    downloadRepeat() {
      this.download({step: this.THIRDSTEP, format: this.format});
    },

    /* HELPERS */
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.mt-download-report {
  width: 408px;

  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    &-arrow {
      margin-right: 18px;
      cursor: pointer;
    }

    &-text {
      user-select: none;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #133952;
    }
  }

  &__next {
    margin-top: 10px;
  }
}
</style>