<template>
  <div class="mt-treelist">
    <div class="mt-treelist__header">
      <div
          class="mt-treelist__header-back"
          v-if="backTitle !== null"
          @click="back"
      >
        <chevron-left-icon
          class="mr-1"
        />
        <span>{{ backTitle }}</span>
      </div>
      <div
          class="mt-treelist__header-slot"
          v-if="!showChild"
      >
        <slot name="header"/>
      </div>
    </div>
    <div
        class="mt-treelist__list"
        v-if="!showChild"
    >
      <div class="mt-treelist__selected-item"/>
    </div>
    <div class="mt-treelist__list">
      <template v-if="!showChild">
        <div
            class="mt-treelist__item"
            v-for="(col, index) in cols"
            :key="index"
            @click="setColFilter(index)"
        >
          <span>{{ col.title }}</span>
          <mt-icon
              name="shevron-left"
              style="margin-left: auto;"
          />
        </div>
      </template>
    </div>
    <div
        class="mt-treelist__filters"
        v-if="showChild"
    >
      <template v-if="cols[currentIndex].filter === 'datetime'">
        <app-date-time-picker
            v-model="dateFrom"
            label="От"
            format="yyyy-MM-dd"
            style="margin-bottom: 15px"
        />
        <app-date-time-picker
            v-model="dateTo"
            label="До"
            format="yyyy-MM-dd"
        />
      </template>
      <template v-if="cols[currentIndex].filter === 'range' || cols[currentIndex].filter === 'integer'">
        <mt-input
            v-model="rangeFrom"
            placeholder="от"
        />
        <mt-input
            v-model="rangeTo"
            placeholder="до"
            style="margin-top: 20px;"
        />
      </template>
      <template v-if="cols[currentIndex].filter === 'list'">
        <div
            class="mt-treelist__option"
            v-for="(option, index) in cols[currentIndex].options"
            :key="index"
            @click.self="option.value = !option.value"
        >
          <span @click.self="option.value = !option.value">{{ option.name || option.title }}</span>
          <mt-checkbox v-model="option.value"/>
        </div>
      </template>
      <template v-if="cols[currentIndex].filter === 'string'">
        <mt-input
            v-model="currentText"
            autofocus="autofocus"
            @keyup.enter="addFilter(cols[currentIndex].filter)"
        />

      </template>
      <template v-if="cols[currentIndex].filter === 'time'">
        <mt-input
            v-model="timeFrom"
            placeholder="от"
            :error="inputTimeFromError"
            :mask="TIME_MASK"
            @input="inputTime({event: $event, timeFrom: true})"
        />
        <mt-input
            v-model="timeTo"
            placeholder="до"
            style="margin-top: 20px;"
            :error="inputTimeToError"
            :mask="TIME_MASK"
            @input="inputTime({event: $event, timeTo: true})"
        />
      </template>
      <mt-button
        class="mt-treelist__button"
        @click="addFilter(cols[currentIndex].filter)"
      >
        Применить
      </mt-button>
    </div>
  </div>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue';
import mtInput from '@/components/UI/mtInput/mtInput.vue';
import mtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import {TIME_MASK} from "@/constants/mask.js";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import {isNullOrEmpty} from "@/services/utils.js";
import AppDateTimePicker from "@/components/UI/AppDateTimePicker/AppDateTimePicker.vue";
import AppDatePicker from "@/components/UI/AppDateTimePicker/AppDateTimePicker.vue";

export default {
  name: 'mtFiltersView',
  components: {
    AppDatePicker,
    AppDateTimePicker,
    MtButton,
    ChevronLeftIcon,
    mtIcon,
    mtInput,
    mtCheckbox,
  },
  props: {
    cols: {
      type: [Array, Object],
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      datepickerRefs: [],
      backTitle: null,
      currentIndex: null,
      showChild: false,
      date: {},
      dateFrom: null,
      dateTo: null,
      rangeFrom: null,
      rangeTo: null,
      currentText: null,
      timeFrom: null,
      inputTimeFromError: false,
      timeTo: null,
      inputTimeToError: false,
    }
  },
  computed: {
    TIME_MASK() {
      return TIME_MASK
    }
  },

  methods: {
    setDates(event) {
      if (!!event[0]) {
        let date = new Date(event[0]);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        this.dateFrom = `${year}-${month}-${day}`;
      }
      if (!!event[1]) {
        let date = new Date(event[1]);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        this.dateTo = `${year}-${month}-${day}`;
      }
    },

    back() {
      this.showChild = false;
      this.backTitle = null;
      this.currentText = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.date = null;
      this.rangeFrom = null;
      this.rangeTo = null;
    },

    setColFilter(index) {
      this.backTitle = this.cols[index].title;
      this.currentIndex = index;
      this.showChild = true;

      this.setColFilterData();
    },

    addFilter(type) {
      let field = this.cols[this.currentIndex].stub;
      let title = this.cols[this.currentIndex].title;

      let filter = {
        field, title
      };

      let filterIndex = this.filters.findIndex(item => item.field === filter.field);
      let filtersBuffer = this.filters;

      if (type === 'datetime' && (this.dateFrom !== null || this.dateTo !== null)) {
        filter.type = 'datetime';
        filter.value = {};

        if(this.dateFrom) filter.value.from = this.dateFrom;
        if(this.dateTo) filter.value.to = this.dateTo;

        this.dateFrom = null;
        this.dateTo = null;
        this.date = null;
      }
      if (type === 'range' || type === 'integer') {

        filter.type = 'range';
        filter.value = {};

        if (this.rangeFrom !== null) filter.value['from'] = this.rangeFrom;
        if (this.rangeTo !== null) filter.value['to'] = this.rangeTo;

        this.rangeFrom = null;
        this.rangeTo = null;
      }
      if (type === 'list') {
        filter.type = 'list';
        filter.value = [];
        filter.list = [];

        if (this.cols[this.currentIndex].additional) { // FIXME
          filter.additional = true;
        }

        this.cols[this.currentIndex].options.forEach(option => {
          if (option.value) {
            filter.value.push(option.stub)
            filter.list.push(option.name)
          }
        })
      }
      if (type === 'string') {
        filter.type = 'string';
        filter.value = this.currentText;
      }
      if (type === 'time') {
        if (!this.inputTimeFromError && !this.inputTimeToError) {
          filter.type = 'time';
          filter.value = {};

          if (this.timeFrom !== null) filter.value.from = this.formatTime(this.timeFrom);
          if (this.timeTo !== null) filter.value.to = this.formatTime(this.timeTo);

          this.timeFrom = null;
          this.timeTo = null;
        }
      }

      if(isNullOrEmpty(filter.value)) {
        if (filterIndex !== -1) {
          filtersBuffer.splice(filterIndex, 1)
        }
      } else {
        if (filterIndex !== -1) {
          filtersBuffer[filterIndex] = filter
        } else {
          filtersBuffer.push(filter)
        }
      }

      this.$emit("update:filters", filtersBuffer);
      this.$emit('changeFilter');
      this.back();
    },

    formatTime(time) {
      let splittedTime = time.split(':');
      if (splittedTime[2].length === 0) {
        splittedTime[2] = '00';
      }
      else if (splittedTime[2].length === 1) {
        splittedTime[2] = `0${splittedTime[2]}`;
      }
      return splittedTime.join(':');
    },

    setColFilterData() {
      let FOUND_FILTER = this.filters.find(
          filter => filter.field === this.cols[this.currentIndex].stub
      );

      if (!FOUND_FILTER) return;

      switch (this.cols[this.currentIndex].filter) {
        case 'string':
          this.currentText = FOUND_FILTER.value;
          break;

        case 'range':
        case 'integer':
          this.rangeFrom = FOUND_FILTER.value?.from;
          this.rangeTo = FOUND_FILTER.value?.to;

          break;

        case 'list':
          FOUND_FILTER.list.forEach(
              item => this.cols[this.currentIndex].options.forEach(
                  option => {
                    if (option.name === item) {
                      option.value = true;
                    }
                  }
              )
          );

          break;

        case 'datetime':// DELETE

            this.dateFrom = FOUND_FILTER.value?.from;
            this.dateTo = FOUND_FILTER.value?.to;

          this.date = [
            new Date(FOUND_FILTER.value?.from),
            new Date(FOUND_FILTER.value?.to),
          ];

          break;

        default:break;
      }
    },

    inputTime({event, timeFrom, timeTo,}) {
      if (timeFrom) {
        this.inputTimeFromError = event.target.value.split(':').length < 3;
      }
      if (timeTo) {
        this.inputTimeToError = event.target.value.split(':').length < 3;
      }
    },

    validation() {
    }
  },
}

</script>

<style lang="scss" src="./mtFiltersView.scss" />