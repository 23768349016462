<template>
  <mtExpander class="assessment-plan__expander">
    <slot />
  </mtExpander>
</template>

<script>
import mtExpander from '@/components/UI/mtExpander/mtExpander.vue';
export default {
  name: "testPlanRows",
  components: {mtExpander}
}
</script>

<style scoped>

</style>