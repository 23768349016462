<template>
  <svg :style="{'--current-color': color}" width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.24992 1.94789V6.05223L4.76742 4.00006L1.24992 1.94789ZM0.521924 0.173393L6.65159 3.74923C6.69552 3.77494 6.73195 3.81171 6.75727 3.85586C6.78259 3.90002 6.79591 3.95003 6.79591 4.00094C6.79591 4.05184 6.78259 4.10185 6.75727 4.14601C6.73195 4.19017 6.69552 4.22693 6.65159 4.25264L0.521924 7.82848C0.477471 7.85442 0.426944 7.86813 0.375475 7.86823C0.324006 7.86833 0.273428 7.85481 0.228876 7.82904C0.184324 7.80327 0.147384 7.76616 0.121809 7.7215C0.0962336 7.67683 0.0829331 7.6262 0.0832578 7.57473V0.42481C0.0832425 0.37349 0.0967687 0.323074 0.12247 0.278654C0.148172 0.234234 0.185138 0.197381 0.229638 0.171818C0.274138 0.146254 0.324595 0.132884 0.375915 0.133058C0.427234 0.133233 0.477599 0.146945 0.521924 0.17281V0.173393Z" fill="var(--current-color)"/>
  </svg>

</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  },
}
</script>