<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5337 16.0018L22.1736 20.6407L20.6407 22.1736L16.0018 17.5337C14.2758 18.9173 12.1289 19.6699 9.91675 19.6667C4.53475 19.6667 0.166748 15.2987 0.166748 9.91675C0.166748 4.53475 4.53475 0.166748 9.91675 0.166748C15.2987 0.166748 19.6667 4.53475 19.6667 9.91675C19.6699 12.1289 18.9173 14.2758 17.5337 16.0018ZM15.3605 15.198C16.7354 13.7841 17.5032 11.8889 17.5001 9.91675C17.5001 5.72641 14.106 2.33341 9.91675 2.33341C5.72641 2.33341 2.33341 5.72641 2.33341 9.91675C2.33341 14.106 5.72641 17.5001 9.91675 17.5001C11.8889 17.5032 13.7841 16.7354 15.198 15.3605L15.3605 15.198Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>