<template>
  <app-input
      v-model="computedModelValue"
      :type="isShow ? 'text' : 'password'"
      :half="half"
      :disabled="disabled"
      :placeholder="placeholder"
      :errors="errors"
  >
    <template #label>
      {{ label }}

      <slot name="label" />
    </template>

    <template #append>
      <app-icon-toggler
          icon-on="/img/icons/eye_on.svg"
          icon-off="/img/icons/eye_off.svg"
          v-model="isShow"
      />
    </template>
  </app-input>
</template>

<script>
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppIconToggler from "@/components/UI/AppIconToggler/AppIconToggler.vue";

export default {
  name: "AppInputPassword",
  components: { AppIconToggler, AppInput },
  props: {
    disabled: Boolean,
    errors: {
      type: Array,
      default: () => ([]),
    },
    half: {
      type: Boolean
    },
    label: String,
    placeholder: String,
    modelValue: [String, Number]
  },
  data() {
    return {
      isShow: false
    }
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>