<template>
  <div class="header">
    <div class="header__desktop">
      <div class="header__page-info">
        <router-link to="/">
          <img class="header__page-info__logo" src="/img/logo.svg" />
        </router-link>

        <div class="header__page-info__details">
          <h2 class="header__page-info__title">{{ $route.meta.title }}</h2>
          <mt-breadcrumbs />
        </div>
      </div>
      <div class="header__user-block">
        <app-tooltip one-liner>
          <template #activator>
            <router-link
              :to="{
                name: 'Billing',
              }"
              v-if="isAdmin"
              class="header__user-block__currency"
            >
              {{ authUser.balance ?? 0 }} / {{ authUser.availableBalance ?? 0 }} Ψ
            </router-link>
          </template>
          Баланс / Доступно
        </app-tooltip>

        <auth-user-notification-btn v-if="isAdmin" />

        <template v-if="authUser">
          <app-dropdown
            class="header__user-dropdown"
            ref="actionsDropdown"
            :items="dropdownItems"
          >
            <template #header>
              <auth-user-header-block />
              <div
                class="header__user-block__user-settings header__user-block_item"
              >
                <div class="header_user-settings_vector">
                  <img
                    src="@/assets/img/svg/user-block_vector__logo.svg"
                    alt="Настройки пользователя"
                    :class="{ 'popup-open__vector': isOpen }"
                  />
                </div>
              </div>
            </template>
          </app-dropdown>
        </template>
      </div>
    </div>
    <div class="header__mobile">
      <div class="header__mobile__main">
        <div
          class="header__menu-icon-btn"
          @click="toggleMenu"
          :class="{ 'header__menu-icon-btn_active': computedModelValue }"
        >
          <span />
          <span />
          <span />
        </div>
        <div class="header__mobile__logo">
          <img src="/img/full-logo.svg" />
        </div>
      </div>
      <div class="header__page-info">
        <div class="header__page-info__details">
          <h2 class="header__page-info__title">{{ $route.meta.title }}</h2>
          <mt-breadcrumbs />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mtSvg from '@/components/UI/mtSvg/index.vue'
import AppDropdown from '@/components/UI/AppDropdown/AppDropdown.vue'
import MtBreadcrumbs from '@/components/workspace/mtBreadcrumbs.vue'
import AuthUserHeaderBlock from '@/components/workspace/auth/AuthUserHeaderBlock/AuthUserHeaderBlock.vue'
import NotificationIcon from '@/components/UI/mtSvg/svg/NotificationIcon.vue'
import MtDialog from '@/components/UI/mtDialog/mtDialog.vue'
import AuthApi from '@/api/auth.api.ts'
import AppSlider from '@/components/UI/AppSlider/AppSlider.vue'
import AppTooltip from '@/components/UI/AppTooltip/AppTooltip.vue'
import AuthUserNotificationBtn from '@/components/workspace/auth/AuthUserNotificationBtn/index.vue'
import MtSelect from '@/components/UI/mtSelect/mtSelect.vue'
import AppSelect from '@/components/UI/AppSelect/AppSelect.vue'
import LightningBoltCircle from '@/components/icons/LightningBoltCircleIcon.vue'
import LightningBoltCircleIcon from '@/components/icons/LightningBoltCircleIcon.vue'
import UserEditIcon from '@/components/icons/UserEditIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import AuthTokenCache from "@/cache/auth-token.cache";

export default {
  name: 'AppHeader',
  components: {
    LogoutIcon,
    SettingsIcon,
    UserEditIcon,
    LightningBoltCircleIcon,
    LightningBoltCircle,
    AppSelect,
    MtSelect,
    AuthUserNotificationBtn,
    AppTooltip,
    AppSlider,
    MtDialog,
    NotificationIcon,
    AuthUserHeaderBlock,
    MtBreadcrumbs,
    AppDropdown,
    mtSvg,
  },
  props: {
    isMenuOpened: Boolean,
  },
  data() {
    return {
      activeSection: 'AssessmentPlans',
      activeSectionTitle: 'Планы оценок',
      isOpen: false,
      key: 0,
      vcoSettings: {
        events: ['mousedown'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },

      email: null,
      fullName: null,
      branches: [],
      branch: null,
      balance: null,
      availableBalance: null,
      reminder: null,
      currentBranch: null,

      dropdownItems: [
        {
          label: 'Личные данные',
          icon: markRaw(UserEditIcon),
          to: {
            name: 'UserCabinet',
          },
        },
        {
          label: 'Смена пароля',
          icon: markRaw(SettingsIcon),
          to: {
            name: 'UserSecurity',
          },
        },
        {
          label: 'Выйти из системы',
          icon: markRaw(LogoutIcon),
          onClick: this.logout,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['administratorBranches', 'authUser', 'isAdmin']),

    computedModelValue: {
      get() {
        return this.isMenuOpened
      },
      set(newValue) {
        this.$emit('update:isMenuOpened', newValue)
      },
    },
  },
  methods: {
    ...mapActions(['fetchBranches']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),
    ...mapMutations('assessmentPlans/plan', ['clearExpRespPlans']),

    goBack() {
      window.history.back()
    },

    logout() {
      this.clearExpRespPlans()
      AuthTokenCache.clear()
      this.$router.push({ name: 'Signin' })
    },
    toggleMenu() {
      this.computedModelValue = !this.computedModelValue
    },
  },
  async mounted() {
    this.setBreadcrumbs(this.$route.meta.breadcrumbs)
  },
  watch: {
    authUser: {
      handler(info) {
        this.email = info.email
        this.fullName = info.full_name
        this.balance = info.balance
        this.availableBalance = info.availableBalance
        this.reminder = info.reminder
      },
      deep: true,
    },

    $route: {
      handler() {
        this.setBreadcrumbs(this.$route.meta.breadcrumbs)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" src="./style.scss" />
