<template>
  <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M3.963 2.43301C5.77756 0.86067 8.09899 -0.00333986 10.5 9.70266e-06C16.023 9.70266e-06 20.5 4.47701 20.5 10C20.5 12.136 19.83 14.116 18.69 15.74L15.5 10H18.5C18.5001 8.43163 18.0392 6.89781 17.1747 5.58927C16.3101 4.28072 15.0799 3.25517 13.6372 2.64013C12.1944 2.0251 10.6027 1.84771 9.05996 2.13003C7.5172 2.41234 6.09145 3.14191 4.96 4.22801L3.963 2.43301ZM17.037 17.567C15.2224 19.1393 12.901 20.0034 10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 7.86401 1.17 5.88401 2.31 4.26001L5.5 10H2.5C2.49987 11.5684 2.96075 13.1022 3.82534 14.4108C4.68992 15.7193 5.92007 16.7449 7.36282 17.3599C8.80557 17.9749 10.3973 18.1523 11.94 17.87C13.4828 17.5877 14.9085 16.8581 16.04 15.772L17.037 17.567Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>