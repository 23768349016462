<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
    <path d="M0 0.333374H15V2.00004H0V0.333374ZM0 12.8334H15V14.5H0V12.8334ZM6.66667 8.66671H15V10.3334H6.66667V8.66671ZM6.66667 4.50004H15V6.16671H6.66667V4.50004ZM0 7.41671L3.33333 4.50004V10.3334L0 7.41671Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  name: "AlignLeft",
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>