<template>
  <div
      class="mt-checkbox"
      :class="[{ 'mt-checkbox_checked': computedValue, 'mt-checkbox_disabled': disabled }]"
      @click="updateModelValue"
  >
    <mt-icon
        v-if="computedValue && !multiple"
        name="check"
        fill="#fff"
    />
    <svg
        v-if="multiple"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
      <path
          fill="#FFFFFF"
          d="M20 14H4V10H20"
      />
    </svg>
  </div>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue'

export default {
  name: 'mtCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: null
    },
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {mtIcon},
  data() {
    return {}
  },

  computed: {
    computedValue() {
      if(this.value !== null) return this.value;

      return this.modelValue;
    }
  },

  methods: {
    updateModelValue(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.disabled) return;

      this.$emit('update:modelValue', !this.computedValue);
      this.$emit('change')
    }
  },
}

</script>

<style
    lang="scss"
    scoped
>
.mt-checkbox {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #636366;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &_disabled {
    pointer-events: none;
  }

  &_checked {
    border-color: #00788C;
    background-color: #00788C;
  }

  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

}
</style>