import api from "@/network";

export default {
  namespaced: true,

  state: {
    settings: [],
  },

  getters: {
    settings(state) {
      return state.settings;
    }
  },

  actions: {
    /* FETCHERS */
    async fetchTestVariantBlock({ }, { uuidTest, uuidVariant, uuidBlock, }) {
      return await api.get(`tests/${uuidTest}/variants/${uuidVariant}/blocks/${uuidBlock}`);
    },

    /* API ACTIONS */
    async createTestVariantBlock({ }, { uuidTest, uuidVariant, payload, }) {
      return await api.post(`tests/${uuidTest}/variants/${uuidVariant}/blocks`, payload);
    },
    async updateTestVariantBlock({ }, { uuidTest, uuidVariant, uuidBlock, payload, }) {
      return await api.put(`tests/${uuidTest}/variants/${uuidVariant}/blocks/${uuidBlock}`, payload);
    },
    async updateTestVariantBlockMultiple({ }, { uuidTest, payload, }) {
      return await api.put(
          `tests/${uuidTest}/variants/blocks/mass-update`,
          payload.map((item) => ({
            uuid: item.uuid,
            order: item.order,
            status: item.status,
          }))
      );
    },
    async deleteTestVariantBlocks({ }, { uuidTest, payload, }) {
      return await api.delete(
          `tests/${uuidTest}/variants/blocks`,
          {
            data: payload.map(item => item.uuid),
          }
      );
    },

    /* LOCAL ACTIONS */
  },

  mutations: {
    async updateTestSettings(state, settings) {
      state.settings = settings;
    },
  },
}