<template>
  <component :is="getPlansPage()"/>
</template>

<script>
import planAdmin from "@/components/workspace/assessmentPlans/plans/plan/admin/index.vue";
import planExpert from "@/components/workspace/assessmentPlans/plans/plan/expert/index.vue";
import planRespondent from "@/components/workspace/assessmentPlans/plans/plan/respondent/index.vue";

export default {
  components: {
    planRespondent,
    planExpert,
    planAdmin
  },

  computed: {
    role() {
      return this.$store.getters['authUser']?.role?.slug || '';
    },
  },
  methods: {
    getPlansPage() {
      switch (this.role.toLowerCase()) {
        case 'operator':
          return 'planDefault';

        case 'expert':
        case 'inspector':
          return 'planExpert';

        case 'respondent':
          return 'planRespondent';

        case 'admin':
          return 'planAdmin';

        default:
          return null;
      }
    },
  }
}
</script>