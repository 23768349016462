<template>
  <div
      class="mt-input"
      :class="[
        `mt-input_theme-${theme}`, `mt-input_${size}`, {
        'mt-input_focused': focused || hasValue,
        'mt-input_invalid': error,
        'mt-input_center': center,
        'mt-input_error': hasError,
        'mt-input_disabled': disabled
        }]"
      @click="setFocus"
  >
    <div class="mt-input__left">
      <slot name="left"/>
    </div>
    <div
        v-if="!!placeholder"
        :class="['mt-input__label', {'mt-input__label_with-left': !!$slots.left || false}]"
    >
      {{ placeholder }}
      <span
          v-if="required"
          class="mt-input__label__star">
        *
      </span>
    </div>
    <input
        ref="ipt"
        v-model="computedModelValue"
        v-maska="mask"
        :type="getType"
        :disabled="disabled"
        @input="$emit('input')"
        @blur="$emit('blur'); focused = false"
        @focus="$emit('focus'); touched = true; focused = true"
        @keyup.enter="submit"
        @keypress="keypress"
    />
    <div
        class="mt-input__switch"
        @click.stop="showPassword = !showPassword"
        v-if="type === 'password'"
    >
      <img
          v-if="showPassword"
          src="@/assets/img/svg/eye-close.svg"

      />
      <img
          v-else
          src="@/assets/img/svg/eye-open.svg"

      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'mtInput',
  props: {
    type: {
      type: String,
      default: () => "text"
    },
    mask: {
      type: String,
      default: () => null,
    },
    error: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: () => "default"
    },
    center: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    keypress: Function,
    size: {
      type: String,
      validator(value) {
        return ['sm'].includes(value);
      }
    },
    value: {
      type: String
    }
  },
  mounted() {
    if (this.autofocus) this.$nextTick(() => {
      this.setFocus()
    })
  },
  data() {
    return {
      focused: false,
      touched: false,
      showPassword: false,
      randomId: Math.random()
    }
  },

  computed: {
    hasValue() {
      return !!this.modelValue || !!this.value
    },
    getType() {
      return this.showPassword ? 'text' : this.type
    },

    computedModelValue: {
      get() {
        return this.value || this.modelValue;
      },
      set(newVal, oldVal) {
        if (this.type === 'number') {
          let temp = parseInt(newVal) || 0
          this.$emit('update:modelValue', temp > 150 ? 150 : temp)
        }

        if (this.type === 'date') {
          let dateArray = newVal.split('-')
          if (dateArray[0].length > 4) {
            this.$emit('update:modelValue', `2100-${dateArray[1]}-${dateArray[2]}`)
          } else {
            this.$emit('update:modelValue', newVal)
          }
        }
        else {
          if (newVal !== oldVal) this.$emit('update:modelValue', newVal)
        }
      }
    }
  },

  methods: {
    setFocus() {
      this.$refs.ipt.focus()
    },
    setTouched(value) {
      this.touched = value;
    },

    submit() {
      this.$emit('submit')
    }
  }
}

</script>

<style lang="scss" scoped src="./mtInput.scss" />