<template>
  <mt-dialog
      v-model="computedModelValue"
      class="participants-modal"
      size="lg"
  >
    <h2 class="participants-modal__title">
      Добавить пользователей
    </h2>

    <div class="participants-modal__filter">
      <app-input
          v-model="fullName"
          placeholder="ФИО"
          class="participants-modal__search"
          size="sm"
          @update:modelValue="searchParticipant"
      />
      <app-select
          v-model="role"
          :items="userRoles"
          item-text="name"
          item-value="slug"
          label="Роль"
          class="participants-modal__search"
          size="sm"
          clearable
          @update:modelValue="$refs.table.getItems()"
      />
    </div>

    <app-server-data-table
        ref="table"
        v-model:params="params"
        v-model:selected-items="selectedItems"
        :headers="cols"
        checkable
        with-pagination
        :items-provider="getAllParticipants"
    >
      <template #active="{item}">
        {{ item.role.name }}
      </template>
      <template #participants="{item}">
        <user-status-chip :value="item.status" />
      </template>

      <template #footer-actions>
        <mt-button
            :loading="requestPending"
            :class="['participants-modal__add-btn']"
            :disabled="!selectedItems.length"
            @click="addParticipant"
        >
          <slot name="next">Добавить</slot>
        </mt-button>
      </template>
    </app-server-data-table>
  </mt-dialog>
</template>

<script>
import mtPagination from "@/components/UI/mtPagination/mtPagination.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtInput from "@/components/UI/mtInput/mtInput.vue";
import mtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import InfoBlock from "@/components/workspace/InfoBlock.vue";
import ParticipantApi from "@/api/participant.api.ts";
import AppServerDataTable from "@/components/UI/AppServerDataTable/AppServerDataTable.vue";
import UserStatusChip from "@/components/workspace/UserStatusChip.vue";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import {USER_ROLES} from "@/constants/roles.constant";
import PlansAdminApi from "@/api/plans-admin.api";

export default {
  name: "AddParticipantsToPlanDialog",
  components: {
    AppSelect,
    AppInput,
    UserStatusChip,
    AppServerDataTable,
    InfoBlock,
    MtDialog,
    MtLoadingAnimationLinear,
    mtInput,
    mtPagination,
    mtButton,
    mtCheckbox,
  },
  props: {
    modelValue: Boolean
  },
  data() {
    return {
      requestPending: false,
      multiple: false,
      fullName: '',
      role: "",
      cols: [
        {
          title: "ФИО",
          stub: "full_name",
          visible: true,
          filter: "string",
        },
        {
          title: "E-mail",
          stub: "email",
          visible: true,
          filter: "string",
        },
        {
          title: "Роль",
          stub: "active",
          visible: true,
          filter: "list",
        },
        {
          title: "Статус",
          stub: "participants",
          visible: true,
          filter: "string",
        },
      ],
      params: {
        settings: {
          value: '',
          role: ''
        },
      },
      selectedItems: [],
      debounceTimeout: ''
    };
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },

    userRoles() {
      return USER_ROLES
    }
  },
  methods: {
    async getAllParticipants() {
      return await PlansAdminApi.getParticipantsOfPlan(this.$route.params.planUuid, {
        ...this.params,
        settings: {
          value: this.fullName,
          role: this.role ? [this.role] : undefined
        }
      })
    },

    async addParticipant() {
      this.requestPending = true;

      const userUuids = this.selectedItems.map((participant) => participant.uuid);
      await ParticipantApi.addParticipantsToPlan(this.$route.params.planUuid, userUuids)
          .then(() => {
            PlansAdminApi.getParticipantsOfPlan(this.$route.params.planUuid, {})
                .then(({data}) => {
                  if(!data.data.length) {
                    this.$emit('empty')
                  }
                });
            this.selectedItems = [];
            this.$toast.success("Пользователи успешно добавлены");
            this.computedModelValue = false;
            this.$emit('success');
            this.role = '';
            this.fullName = '';
          })
          .catch(() => {
            this.$toast.error("Что-то пошло не так, пожалуйста повторите запрос позже")
          })
          .finally(() => {
            this.requestPending = false;
          });
    },

    searchParticipant() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.$refs.table.getItems();
      }, 500);
    },
  }
};
</script>

<style lang="scss">
.participants-modal {
  .app-data-table__header {
    display: none;
  }

  .mt-dialog__window {
    width: 800px;
  }

  &__title {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 22px;
    color: #252733;
    text-align: center;
  }

  &__filter {
    display: flex;
    gap: 20px;
  }
}
</style>