<template>
  <div class="mt-pagination">
    <div
        class="mt-pagination__per-page"
        v-if="!hidePerPage"
    >
      <span class="mt-pagination__per-page__label">Кол-во записей:</span>
      <app-select
          class="mt-pagination__per-page__select"
          v-model="computedPerPage"
          :items="perPages"
          hide-details
          from-top
      />
    </div>
    <div class="mt-pagination__pages">
      <div class="mt-pagination__pages__current">
        <span class="mt-pagination__pages__current__span">
          {{ !getTotalPages ? 0 : page }}
        </span>
        из
        <span class="mt-pagination__pages__current__span">
          {{ getTotalPages }}
        </span>
        ({{ total }} записей)
      </div>
      <div class="mt-pagination__pages-actions">
        <mt-button
          @click="changePage(page - 1)"
          icon
          :disabled="isFirstPage"
        >
          <chevron-left-icon/>
        </mt-button>
        <mt-button
          @click="changePage(page + 1)"
          :disabled="isLastPage"
          icon
        >
          <chevron-right-icon/>
        </mt-button>
      </div>
    </div>

  </div>
</template>

<script>
import MtButton from "@/components/UI/mtButton/index.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";

export default {
  name: 'mtPagination',
  components: {
    AppSelect,
    ChevronRightIcon,
    ChevronLeftIcon,
    MtButton
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    },
    hidePerPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      perPages: [
        {
          value: 5,
          label: '5'
        },
        {
          value: 10,
          label: '10'
        },
        {
          value: 15,
          label: '15'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        }
      ]
    }
  },

  computed: {
    getTotalPages() {
      if(this.pages) return this.pages;
      if(!this.total || !this.perPage) return 1;

      return Math.ceil(this.total / this.perPage)
    },

    computedPerPage: {
      get() {
        return this.perPage;
      },
      set(newVal) {
        this.$emit('change', {
          page: 1,
          perPage: newVal,
          total: this.total
        })
      }
    },

    isLastPage() {
      if(!this.getTotalPages) return true
      return this.page === this.getTotalPages
    },

    isFirstPage() {
      return this.page === 1
    }
  },

  methods: {
    changePage(page) {
      this.$emit('change', {
        page: page,
        perPage: this.perPage,
        total: this.total
      })
    },
  }
}

</script>

<style
    lang="scss"
    src="./mtPagination.scss"
/>
