import api from '@/network';
import {scaleIndexAdapter} from "@/adapters/assessmentPlans/tasks/customAssessment.adapter.js";

export default {
  async getScales (
      planUuid: string,
      taskUuid: string,
      sessionUuid: string
  ) {
    const response = await api.get(`/plans/${planUuid}/tests/${taskUuid}/session/${sessionUuid}/scales`);
    response.data.data = scaleIndexAdapter(response.data.data);

    return response;
  },

  async getMark (
      planUuid: string,
      taskUuid: string,
      sessionUuid: string
  ) {
    const response = await api.get(`/plans/${planUuid}/tests/${taskUuid}/session/${sessionUuid}/result`);
    return response.data.data;
  },

  async setMark (
      planUuid: string,
      taskUuid: string,
      sessionUuid: string,
      payload: any,
  ) {
    const response = await api.post(
        `/plans/${planUuid}/tests/${taskUuid}/session/${sessionUuid}/result`,
        payload
    );
    return response.data.data;
  },

  async getRespondentSession (
      planUuid: string,
      taskUuid: string,
      respondentUuid: string
  ) {
    return await api.get(`/plans/${planUuid}/tests/${taskUuid}/respondent/${respondentUuid}`);
  },

  async startSession(planUuid: string, taskUuid: string) {
    return await api.post(`plans/${planUuid}/tests/${taskUuid}/session/expert-review`)
        .then((response) => {
          return response.data.uuid;
        });
  },
}