
/* INPUT ADAPTERS */
export function inputPlanGeneralSettings(settings = null) {
  if (!settings) return;

  return {
    ...settings,
  }
}
export function inputPlanSettings(settings = null) {
  if (!settings) return;

  return {
    selfRegistration: settings.self_registraion,
    notification: settings.notification,
    archive: settings.archive
  }
}
export function inputPlanUserNotificationSettings(settings = null) {

  if (!settings) return;

  return {
    notificationSettings: settings.map(
      (user) => ({
        uuid: user.uuid,
        fullName: user.full_name,
        role: user.role,
        status: user.notifications,
      })
    )
  }
}

/* OUTPUT ADAPTERS */
export function outputPlanNotifications(payload = null) {


  if (!payload) return null;

  let settings = {};

  if (payload.notifications !== undefined) settings.notification = payload.notifications;

  return settings;
}
export function outputPlanNotificationSettings(payload = null) {


  if (!payload) return null;

  let settings = {};

  if (payload.oneSheetExcel !== undefined) settings.lists = payload.oneSheetExcel;
  if (payload.points !== undefined) settings.scale = payload.points;
  if (payload.defaultPoints !== undefined) settings.value_default = payload.defaultPoints;
  if (payload.defaultScales !== undefined) settings.scale_default = payload.defaultScales;
  if (payload.scales !== undefined) settings.scales = payload.scales;
  if (payload.tasks !== undefined) settings.tasks = payload.tasks;
  if (payload.responseTime !== undefined) settings.tasks_times = payload.responseTime;
  if (payload.answersToTasks !== undefined) settings.tasks_answer = payload.answersToTasks;
  if (payload.rawPointForTasks !== undefined) settings.tasks_scales = payload.rawPointForTasks;
  if (payload.matchPercentage !== undefined) settings.tasks_assignments = payload.matchPercentage;
  if (payload.respondentData !== undefined) settings.respondent = payload.respondentData;
  if (payload.respondentViewSettings !== undefined) settings.respondent_display_settings = payload.respondentViewSettings;
  if (payload.respondentAdditionalQuestionnaire !== undefined) settings.respondent_datacube = payload.respondentAdditionalQuestionnaire;

  return settings;
}