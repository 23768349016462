<template>
  <mt-input
      v-model="computedModelValue"
      :keypress="numbersOnly"
  />
</template>

<script>
import MtInput from "@/components/UI/mtInput/mtInput.vue";

export default {
  name: 'mtInputAmount',
  components: { MtInput },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    numbersOnly(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        return event.preventDefault();
      }
      return true;
    }
  }
}

</script>