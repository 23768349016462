<template>
  <div class="mt-livesearch">
    <mt-input
        class="mt-livesearch__input"
        ref="livesearch__input"
        placeholder="Поиск"
        v-model="computedModelValue"
        :disabled="disabled"
        size="sm"
        @click.self="clickBySearch({event: $event,})"
    >
      <template #left>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875V15.875ZM12.178 7.176C11.8831 7.3092 11.625 7.51195 11.4257 7.76686C11.2264 8.02176 11.0919 8.32121 11.0338 8.63951C10.9757 8.95781 10.9957 9.28546 11.0921 9.59433C11.1885 9.90321 11.3583 10.1841 11.5871 10.4129C11.8159 10.6417 12.0968 10.8115 12.4057 10.9079C12.7145 11.0043 13.0422 11.0243 13.3605 10.9662C13.6788 10.9081 13.9782 10.7736 14.2331 10.5743C14.4881 10.375 14.6908 10.1169 14.824 9.822C15.0868 10.6756 15.0563 11.5926 14.7375 12.427C14.4186 13.2613 13.8297 13.9648 13.0646 14.4256C12.2994 14.8864 11.4021 15.0778 10.5155 14.9694C9.62896 14.8609 8.8042 14.4589 8.17264 13.8274C7.54108 13.1958 7.13906 12.371 7.03064 11.4845C6.92223 10.5979 7.11365 9.70058 7.5744 8.93544C8.03516 8.1703 8.73875 7.58138 9.57305 7.26252C10.4074 6.94366 11.3244 6.9132 12.178 7.176V7.176Z"
              fill="#C5C7CD"
          />
        </svg>
      </template>
    </mt-input>
    <ul
        class="mt-livesearch__found-items"
        v-if="items.length"
        :style="{width: foundItemsWidth ? `${foundItemsWidth}px` : 'auto'}"
    >
      <li
          class="mt-livesearch__found-item"
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem({ item })"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import mtInput from "@/components/UI/mtInput/mtInput.vue";

export default {
  components: {
    mtInput,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  watch: {
    bufferValue(newVal, oldVal) {
      if (this.type === "number") {
        let temp = parseInt(newVal) || 0;
        this.bufferValue = temp > 150 ? 150 : temp;
      }
      if (this.type === "date") {
        let dateArray = newVal.split("-");

        if (dateArray[0].length > 4) {
          this.bufferValue = `2100-${dateArray[1]}-${dateArray[2]}`;

          this.$emit(
              "update:modelValue",
              `2100-${dateArray[1]}-${dateArray[2]}`
          );
        } else {
          this.$emit("update:modelValue", newVal);
        }
      } else {
        if (newVal !== oldVal) {
          this.$emit("update:modelValue", this.bufferValue);
        }
      }
    },
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.bufferValue = newVal;
      }


      this.$refs.livesearch__input.$el.click();
    },
  },
  methods: {
    selectItem({item}) {
      this.$emit('selectItem', item);
    },
    clickBySearch({event,}) {
      this.foundItemsWidth = event.srcElement.offsetWidth;
    },
  },

  data() {
    return {
      bufferValue: this.modelValue,
      foundItemsWidth: null,
    };
  },
  created() {
    this.bufferValue = this.modelValue;
  },
  mounted() {

  },
};
</script>

<style lang="scss">
.mt-livesearch {

  &__input {
    width: 100%;
  }

  &__found-items {
    position: absolute;
    top: 72px;
    border: 2px solid #00788c;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: navajowhite;
    box-sizing: border-box;
    max-height: 150px;
    background: #ffffff;
    overflow-y: scroll;
  }

  &__found-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-top: 5px;
    padding: 0px 15px;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background: #ecedf4;
    }
  }
}
</style>