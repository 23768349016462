export default function ( instance ) {
  return {
    getPlanEditdataExd ( uuid ) {
      return instance.get( `plans/${uuid}` );
    },

    deletePlan ( uuid ) {
      return instance.delete( `plans/${uuid}` );
    },

    updatePlan ( uuid, data ) {
      return instance.put( `plans/${uuid}`, data );
    },

    getSingleRespondentPlans (uuid, params) {
      return instance.get(`/respondents/${uuid}/plans`, params)
    }
  }
}