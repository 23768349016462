<template>
  <div
      class="mt-preloader"
      :style="`height: ${height}px;`"
  />
</template>

<script>
export default {
  props: {
    height: {
      type: [String, Number,],
      default: 50,
    }
  },
}
</script>

<style
    lang="scss"
    src="./mtPreloader.scss"
    scoped
/>