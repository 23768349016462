import axios from 'axios';

axios.defaults.headers['Content-Type'] = "application/json";

axios.interceptors.request.use(
  function (config) {
    config.maxContentLength = 500000000;
    config.maxBodyLength = 5000000000;

    return config;
  },
);

export default axios;