<template>
  <div
      class="alert"
      v-if="item.dateFrom || item.dateTo"
  >
    <mtSvg
        class="alert__icon"
        name="timerfilled"
        color="#FAB56E"
        width="15"
    />
    доступен
    <template v-if="item.dateFrom">&nbsp;с {{ item.dateFrom }}</template>
    <template v-if="item.dateTo">&nbsp;по {{ item.dateTo }}</template>
  </div>
</template>

<script>
import mtSvg from '@/components/UI/mtSvg/index.vue';

export default {
  name: "planTestTime",
  components: {mtSvg},
  props: {
    item: Object
  }
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>