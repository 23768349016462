<template>
  <div class="tests-library--settings-report">
    <div class="tests-library--settings-report__header">
      <div class="tests-library--settings-report__title">Отчет</div>
      <mt-button
          class="tests-library--settings-report__apply"
          :loading="applyLoader"
          @click="apply"
      >
        Применить
      </mt-button>
    </div>
    <div class="tests-library--settings-report__body">
      <DefaultReport class="tests-library--settings-report__default"/>
    </div>
  </div>
</template>

<script>
import {mapActions,} from "vuex";
import {outputSettingsAdapterDefaultReport} from '@/adapters/testLibrary/tests/testSettings.adapter';
import mtButton from "@/components/UI/mtButton/index.vue";
import DefaultReport from './DefaultReport/index.vue';
import BlockVisibility from './BlockVisibility/index.vue';

export default {
  components: {
    mtButton,
    DefaultReport,
    BlockVisibility,
  },

  props: {},
  data: () => ({
    applyLoader: false,
  }),
  computed: {
    testSettings() {
      return this.$store.getters['test/settings'];
    },
  },

  watch: {},
  methods: {
    ...mapActions('test', [
      'saveExportExcelSettings',
    ]),
    ...mapActions('report', [
      'updateTestVariantBlockMultiple',
      'deleteTestVariantBlocks',
    ]),

    /* GETTERS */
    /* SETTERS */
    /* HANDLERS */
    async apply() {
      if (!this.applyLoader) {
        this.applyLoader = true;

        if (
            this.testSettings.submitReportToRespondent &&
            !this.testSettings.reports.filter(report => report.status).length
        ) {
          this.$toast.warning('Необходимо выбрать хотя бы один отчёт');
        } else {
          await this.saveExportExcelSettings({ // FIXME
            uuidTest: this.$route.params.uuid,
            uuidSettings: this.testSettings.uuid,
            payload: outputSettingsAdapterDefaultReport(this.testSettings),
          });
          /*
          await this.updateBlocks();
          await this.deleteBlocks();
           */
        }

        this.applyLoader = false;
      }
    },

    /* HELPERS */
    async updateBlocks() {


      const BLOCKS = await this.searchBlocksByAction({action: 'update'});


      if (BLOCKS.length) {
        await this.updateTestVariantBlockMultiple({
          uuidTest: this.$route.params.uuid,
          payload: BLOCKS,
        });
      }
    },
    async deleteBlocks() {


      const BLOCKS = await this.searchBlocksByAction({action: 'delete'});


      if (BLOCKS.length) {
        await this.deleteTestVariantBlocks({
          uuidTest: this.$route.params.uuid,
          payload: BLOCKS,
        });
      }
    },
    async searchBlocksByAction({action,}) {
      const BLOCKS = [];

      await this.testSettings.reports.forEach(async (report) => {
        await report.blocks.forEach(async (block) => {
          if (block.action === action) {
            BLOCKS.push(block);
          }
        });
      });

      return BLOCKS
    },
  },

  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.tests-library--settings-report {
  background: #FFFFFF;
  border: 1px solid #E7E8F0;
  border-radius: 8px;

  &__header,
  &__body {
    padding: 22px 20px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid #DFE0EB;
  }

  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }

  &__apply {
    width: 125px !important;
    height: 33px !important;
    border-radius: 8px !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    user-select: none;
  }

  &__default {
  }

  &__blocks-visibility {
    margin-top: 30px;
  }
}
</style>