<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
    <path d="M3.91858 2.80251C5.88435 1.09914 8.39923 0.16313 11.0003 0.166759C16.9836 0.166759 21.8337 5.01684 21.8337 11.0001C21.8337 13.3141 21.1078 15.4591 19.8728 17.2184L16.417 11.0001H19.667C19.6671 9.30102 19.1678 7.63938 18.2312 6.22179C17.2946 4.80419 15.9619 3.69318 14.3989 3.02689C12.836 2.36061 11.1116 2.16844 9.44028 2.47428C7.76896 2.78012 6.2244 3.57048 4.99866 4.74709L3.91858 2.80251ZM18.0821 19.1977C16.1163 20.901 13.6014 21.8371 11.0003 21.8334C5.01708 21.8334 0.166992 16.9833 0.166992 11.0001C0.166992 8.68609 0.892825 6.54109 2.12783 4.78176L5.58366 11.0001H2.33366C2.33352 12.6992 2.83281 14.3608 3.76944 15.7784C4.70607 17.196 6.03873 18.307 7.60171 18.9733C9.1647 19.6396 10.8891 19.8317 12.5604 19.5259C14.2317 19.2201 15.7762 18.4297 17.002 17.2531L18.0821 19.1977Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  name: "Refresh",
  props: {
    color: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>

</style>