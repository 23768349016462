<template>
  <svg :style="{'--current-color': color}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.06 6.10999L12.475 7.52499C13.1251 8.17501 13.6408 8.94671 13.9926 9.79604C14.3444 10.6454 14.5255 11.5557 14.5255 12.475C14.5255 13.3943 14.3444 14.3046 13.9926 15.1539C13.6408 16.0033 13.1251 16.775 12.475 17.425L12.121 17.778C10.8082 19.0908 9.02762 19.8283 7.17101 19.8283C5.3144 19.8283 3.53383 19.0908 2.22101 17.778C0.90819 16.4652 0.170654 14.6846 0.170654 12.828C0.170654 10.9714 0.90819 9.19081 2.22101 7.87799L3.63601 9.29299C3.16838 9.75654 2.79692 10.3079 2.54294 10.9154C2.28896 11.5229 2.15748 12.1746 2.15604 12.833C2.1546 13.4915 2.28323 14.1437 2.53454 14.7523C2.78585 15.3609 3.1549 15.9139 3.62049 16.3795C4.08609 16.8451 4.63906 17.2141 5.24767 17.4655C5.85628 17.7168 6.50853 17.8454 7.16698 17.844C7.82543 17.8425 8.47711 17.711 9.08461 17.4571C9.69211 17.2031 10.2435 16.8316 10.707 16.364L11.061 16.01C11.9984 15.0724 12.5249 13.8008 12.5249 12.475C12.5249 11.1492 11.9984 9.87763 11.061 8.93999L9.64601 7.52499L11.061 6.11099L11.06 6.10999ZM17.778 12.121L16.364 10.707C16.8316 10.2434 17.2031 9.69208 17.4571 9.08458C17.7111 8.47708 17.8425 7.8254 17.844 7.16695C17.8454 6.5085 17.7168 5.85625 17.4655 5.24765C17.2142 4.63904 16.8451 4.08607 16.3795 3.62047C15.9139 3.15487 15.361 2.78583 14.7524 2.53451C14.1437 2.2832 13.4915 2.15457 12.833 2.15602C12.1746 2.15746 11.5229 2.28894 10.9154 2.54292C10.3079 2.79689 9.75656 3.16836 9.29301 3.63599L8.93901 3.98999C8.00166 4.92763 7.47508 6.19917 7.47508 7.52499C7.47508 8.85081 8.00166 10.1223 8.93901 11.06L10.354 12.475L8.93901 13.889L7.52501 12.475C6.87493 11.825 6.35925 11.0533 6.00742 10.2039C5.65559 9.35461 5.47451 8.4443 5.47451 7.52499C5.47451 6.60567 5.65559 5.69536 6.00742 4.84604C6.35925 3.99671 6.87493 3.22501 7.52501 2.57499L7.87901 2.22199C9.19183 0.909167 10.9724 0.171631 12.829 0.171631C14.6856 0.171631 16.4662 0.909167 17.779 2.22199C19.0918 3.53481 19.8294 5.31538 19.8294 7.17199C19.8294 9.0286 19.0918 10.8092 17.779 12.122L17.778 12.121Z" fill="var(--current-color)"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>