<template>
    <loader-wrapper :loading="!loaded" class="cabinet">
      <alert
          v-if="!user.allFieldsAreFilled && !isAdmin"
          type="error"
          class="mb-4"
      >
        Доступ к приложению заблокирован. Пожалуйста, заполните все обязательные поля
      </alert>

      <user-form-builder
          :user="user"
          :handler="save"
          :half="true"
          handler-button-text="Сохранить"
      />
    </loader-wrapper>
</template>

<script>
import MtInput from "@/components/UI/mtInput/mtInput.vue";
import AuthApi from '@/api/auth.api.ts';
import mtButton from "@/components/UI/mtButton/index.vue";
import validation from "@/plugins/validation";
import Alert from "@/components/UI/AppAlert/AppAlert.vue";
import genders from "@/constants/genders";
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import MtRadioGroup from "@/components/UI/mtRadio/mtRadioGroup.vue";
import MtRadio from "@/components/UI/mtRadio/mtRadio.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import DynamicFormBuilder from "@/components/workspace/DynamicFormBuilder.vue";
import AppDateTimePicker from "@/components/UI/AppDateTimePicker/AppDateTimePicker.vue";
import AppDatePicker from "@/components/UI/AppDateTimePicker/AppDateTimePicker.vue";
import MtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import {mapGetters, mapState} from "vuex";
import AppFormBuilder from "@/components/UI/AppFormBuilder.vue";
import AppInputAmount from "@/components/UI/AppInputAmount/AppInputAmount.vue";
import mtRadioGroup from "@/components/UI/mtRadio/mtRadioGroup.vue";
import {required, requiredIf} from "@vuelidate/validators";
import UserFormBuilder from "@/components/workspace/UserFormBuilder.vue";
import {mapActions} from "vuex";
import LoaderWrapper from "@/components/UI/LoaderWrapper.vue";
export default {
  name: "Cabinet",
  components: {
    LoaderWrapper,
    UserFormBuilder,
    AppFormBuilder,
    MtLinearProgress,
    AppDatePicker,
    AppDateTimePicker,
    DynamicFormBuilder,
    AppSelect,
    MtRadio,
    MtRadioGroup,
    AppInput,
    Alert,
    mtButton,
    MtInput
  },
  data() {
    return {
      user: null,
      loaded: false,

      genders,
    }
  },
  computed: {
    ...mapGetters(['authUser', 'isAdmin']),
  },
  methods: {
    ...mapActions(['getAuthUser']),
    async getUserInfo() {
      const { data } = await AuthApi.getUserInfo()
      this.user = data.data;
    },

    async save(payload) {
      return await AuthApi.updateUserInfo(payload)
          .then((response) => {
            this.$router.push({
              name: 'AssessmentPlansList'
            })
            this.getAuthUser()
            this.$toast.success('Ваши личные данные успешно обновлены')
          })
          .catch((error) => {
            this.$toast.error('Непредвиденная ошибка на сервере, пожалуйста повторите позже')
          });
    },
  },
  async mounted() {
    await this.getUserInfo();
    this.loaded = true;
  }
}
</script>

<style lang="scss">
.cabinet {
  border: 1px solid #dfe0eb;
  padding: 32px;
  border-radius: 8px;
  max-width: 700px;
  .mt-button {
    width: 100%;
  }
}
</style>