<script setup lang="ts">
// import PaperIcon from "@/components/icons/PaperIcon.vue";
import PaperPencilCheckIcon from "@/components/icons/PaperPencilCheckIcon.vue";
import DoubleCheckIcon from "@/components/icons/DoubleCheckIcon.vue";
// import AccountSearchOutlineIcon from "@/components/icons/AccountSearchOutlineIcon.vue";
import TaskType from "@/enums/task-type.enum";
import PeopleZoomIcon from "@/components/icons/PeopleZoomIcon.vue";
import TestProIcon from "@/components/icons/TestProIcon.vue";

interface Props {
  type: string;
  size?: 'small'
}

const props = defineProps<Props>();

const computedIcon = computed(() => {
  if (props.type === TaskType.TEST) {
    return PaperPencilCheckIcon;
    // return PaperIcon;
  } else if (props.type === TaskType.TEST_PRO) {
    return TestProIcon;
  } else if (props.type === "confirmation") {
    return DoubleCheckIcon;
  } else if (props.type === TaskType.EXPERT) {
    return PeopleZoomIcon;
    // return AccountSearchOutlineIcon;
  }
});
</script>

<template>
  <div :class="['task-icon-wrapper', `task-icon-wrapper_${props.type}`, {
    'task-icon-wrapper_small': props.size === 'small'
  }]">
    <component
        :is="computedIcon"
        :class="['task-icon', {
          'task-icon_small': props.size === 'small'
        }]"
    />
  </div>
</template>

<style lang="scss">
.task-icon {
  width: 22px;
  height: 22px;
  &-wrapper {
    color: #fff;
    border-radius: 5px;
    @include flexCenter;
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    &_small {
      width: 24px;
      height: 24px;
    }

    &_test {
      // background-color: $brown;
      background-color: #c5a89c;
    }
    &_test_pro {
      background-color: #788470;
      ;
    }
    &_expert {
      background-color: #6d6f71;
    }
    &_confirmation {
      background-color: $green;
    }
  }

  &_small {
    width: 16px;
    height: 16px;
  }
}
</style>
