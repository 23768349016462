import AuthApi from '@/api/auth.api.ts';

export function isTwoObjEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export function isEmpty(value) {
    return value === '' || value === null || value === undefined;
}

export const groupBy = (x, f) => x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});

export function generateRandomID() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < charactersLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export async function isEmailUnique(value) {
    if (!value) return true;

    let emailIsTaken;

    await AuthApi.checkEmailExisting(value)
        .then(({data}) => {
            emailIsTaken = data.exists;
        });

    return !emailIsTaken;
}

export function isNullOrEmpty(value) {
    if (value == null || value === '') {
        return true;
    }

    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return Object.keys(value).length === 0;
        }
    }

    return false;
}

export function deepClone(value) {
    return JSON.parse(JSON.stringify(value));
}

export function addLeadingZero(value) {
    return value < 10 ? `0${value}` : value;
}

export function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function inputNumbersOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        return event.preventDefault();
    }
    return true;
}

export function arrayOfObjectsToObject(array, keyProperty) {
    const data = deepClone(array);
    const obj = {};

    data.forEach((item) => {
        let key = item[keyProperty];
        delete item[keyProperty];
        obj[key] = {
            ...item,
        };
    })

    return obj;

}

export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    const domainPart = email.split('@')[1];
    if (domainPart && (domainPart.startsWith('.') || domainPart.endsWith('.'))) {
        return false;
    }
    return emailRegex.test(email);
}
