<template>
  <mt-popover
      v-model="computedVisibility"
      :width="408"
  >
    <div class="mt-tags-popover-header">
      <mtLiveSearchInput
          class="mt-tags-popover-header__search"
          v-model="searchTag"
          :items="foundTags"
          @input="searchTags"
          @selectItem="selectTag({ tag: $event })"
      />
      <div
          class="mt-tags-popover-add"
          @click="addTag"
      >
        <svg
            class="mt-icon"
            v-if="!addLoader"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.4821 9.98214H13.3214C13.1538 9.98214 13.0179 9.84622 13.0179 9.67857V4.51786C13.0179 3.67963 12.3382 3 11.5 3C10.6618 3 9.98214 3.67963 9.98214 4.51786V9.67857C9.98214 9.84622 9.84622 9.98214 9.67857 9.98214H4.51786C3.67963 9.98214 3 10.6618 3 11.5C3 12.3382 3.67963 13.0179 4.51786 13.0179H9.67857C9.84622 13.0179 9.98214 13.1538 9.98214 13.3214V18.4821C9.98214 19.3204 10.6618 20 11.5 20C12.3382 20 13.0179 19.3204 13.0179 18.4821V13.3214C13.0179 13.1538 13.1538 13.0179 13.3214 13.0179H18.4821C19.3204 13.0179 20 12.3382 20 11.5C20 10.6618 19.3204 9.98214 18.4821 9.98214Z"
          />
        </svg>
        <mt-icon
            v-else
            name="preloader"
            :preloader="addLoader"
            fill="#FFFFFF"
        />
      </div>
    </div>
    <div class="mt-tags-popover-body">
      <div
          class="mt-tags-popover-item"
          v-for="tag in bufferTags"
          :key="tag"
      >
        <div class="mt-tags-popover-name">{{ tag.name }}</div>
        <svg
            class="mt-tags-popover-delete"
            v-if="!tag.deleteLoader"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="deleteTag({ tag })"
        >
          <path
              d="M3 6H5H21"
              stroke="#C5C7CD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
              stroke="#C5C7CD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M14 11V17"
              stroke="#C5C7CD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M10 11V17"
              stroke="#C5C7CD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
        <mt-icon
            v-else
            name="preloader"
            fill="#C5C7CD"
            :preloader="tag.deleteLoader"
        />
      </div>
    </div>
  </mt-popover>
</template>

<script>
import _ from 'lodash';
import mtPopover from "@/components/UI/mtPopover/mtPopover.vue";
import mtLiveSearchInput from "@/components/composite/mtLiveSearchInput.vue";
import mtIcon from "@/components/UI/mtIcon/mtIcon.vue";

export default {
  components: {
    mtPopover,
    mtLiveSearchInput,
    mtIcon,
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => ([]),
    },
    foundTags: {
      type: Array,
      default: () => ([]),
    },
    addLoader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTag: null,
    bufferTags: [],
  }),
  computed: {
    computedVisibility: {
      get() {
        return this.visibility
      },
      set(newVal) {
        this.$emit('update:visibility', newVal)
        if (!newVal) {
          this.$emit('close');
        }
      }
    }
  },

  watch: {
    tags(newTags) {
      if (newTags.length) {
        this.bufferTags = newTags.map((tag) => ({
          ...tag,
          deleteLoader: false,
        }));
      } else {
        this.bufferTags = newTags;
      }
    },
  },
  methods: {
    searchTags: _.debounce(function () {
      this.$emit('search', {tag: this.searchTag});
    }, 400),

    selectTag({tag}) {
      this.searchTag = tag;

      this.$emit('select', {tag,});
    },

    addTag() {
      this.$emit('add', {tag: this.searchTag});
      this.searchTag = ''
    },

    deleteTag({tag,}) {
      tag.deleteLoader = true;

      this.$emit('delete', {tag: tag.name,});
    }
  }
}
</script>

<style lang="scss">
.mt-tags-popover {
  &-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;

    &__search {
      flex: 1;
    }
  }

  &-body {
  }

  &-name {
    font-size: 14px;
    padding-left: 3px;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-delete {
  }

  &-add {
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: #00788c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;

    &:active {
      background: #066a7e;
    }
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #C2C8D0;
    padding: 10px 0;

    &:first-child {
      margin-top: 10px;
    }
  }

  &-delete {
    cursor: pointer;

    path {
      transition: stroke .3s;
    }

    &:hover {
      path {
        stroke: #00788c;
      }
    }
  }
}
</style>