import axios from 'axios';
import router from "@/router";
import AuthTokenCache from "@/cache/auth-token.cache";
import store from "@/store";

axios.defaults.baseURL = `${import.meta.env.VITE_API_URL}/api/v1/`;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials = false;

axios.interceptors.request.use(
    function (config) {
        const token = AuthTokenCache.get();

        if (token) {
            config.headers.Authorization = `Bearer ${ token }`;
        }

        return config;
    },
);
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response?.status === 401) {
            AuthTokenCache.clear();
        }

        const errorText = error?.response?.data?.errorType;

        if (errorText === 'emptyRequiredFields') {
            router.push({
                name: store.getters.authUser?.registrationStep !== null ? 'FinishSignup' : 'UserCabinet'
            })
        }
        if (errorText === 'showAgreements') router.push({ name: 'Agreements' })
        if (errorText === 'setPermanentPassword') router.push({ name: 'SetPermanentPassword' })

        return Promise.reject(error.response ?? error);
    }
)

export default axios;