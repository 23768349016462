<template>
  <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M21.5 16.875V1.125C21.5 0.501562 20.9984 0 20.375 0H5C2.51562 0 0.5 2.01562 0.5 4.5V19.5C0.5 21.9844 2.51562 24 5 24H20.375C20.9984 24 21.5 23.4984 21.5 22.875V22.125C21.5 21.7734 21.3359 21.4547 21.0828 21.2484C20.8859 20.5266 20.8859 18.4688 21.0828 17.7469C21.3359 17.5453 21.5 17.2266 21.5 16.875ZM6.5 6.28125C6.5 6.12656 6.62656 6 6.78125 6H16.7188C16.8734 6 17 6.12656 17 6.28125V7.21875C17 7.37344 16.8734 7.5 16.7188 7.5H6.78125C6.62656 7.5 6.5 7.37344 6.5 7.21875V6.28125ZM6.5 9.28125C6.5 9.12656 6.62656 9 6.78125 9H16.7188C16.8734 9 17 9.12656 17 9.28125V10.2188C17 10.3734 16.8734 10.5 16.7188 10.5H6.78125C6.62656 10.5 6.5 10.3734 6.5 10.2188V9.28125ZM18.3781 21H5C4.17031 21 3.5 20.3297 3.5 19.5C3.5 18.675 4.175 18 5 18H18.3781C18.2891 18.8016 18.2891 20.1984 18.3781 21Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>