<template>
	<div class="auth__container">
		<div class="agreement-page">
			<div class="agreement-row">
				<mt-checkbox v-model="personalDataChecked" />
				<div>
					<span class="agreement-label" @click="personalDataChecked = !personalDataChecked">
						Соглашаюсь с условиями обработки персональных данных
					</span>
					<button class="agreement-link" @click="showPersonalDataText = true">(показать)</button>
				</div>
			</div>

			<div class="agreement-row">
				<mt-checkbox v-model="confidentialityChecked" />
				<div>
					<span class="agreement-label" @click="confidentialityChecked = !confidentialityChecked">
						Соглашаюсь с условиями соблюдения конфиденциальности
					</span>
					<button class="agreement-link" @click="showConfidentialityText = true">(показать)</button>
				</div>
			</div>

			<div class="agreement-row agreement-policy">
				<app-tooltip>
					<template #activator>
						<mt-button icon @click="downloadPolicy()">
							<download-icon />
						</mt-button>
					</template>

					Скачать
				</app-tooltip>
				<div @click="confidentialityChecked = !confidentialityChecked">
					Политика информационной безопасности в отношении обработки персональных данных
				</div>
			</div>

			<div class="agreement-action">
				<mt-button
          class="auth__actions__btn"
          :loading="requestPending"
          @click="send()"
          :disabled="!validateAgreements()"
        >
					Войти в кабинет
				</mt-button>
			</div>
		</div>
	</div>

	<mt-dialog size="lg" v-model="showPersonalDataText">
		<div v-html="personalDataText" class="agreement-text"></div>
	</mt-dialog>

	<mt-dialog size="lg" v-model="showConfidentialityText">
		<div v-html="confidentialityText" class="agreement-text"></div>
	</mt-dialog>
</template>

<script>
import AuthApi from '@/api/auth.api.ts';
import MtButton from '@/components/UI/mtButton/index.vue';
import MtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";

export default {
	components: { MtButton, MtCheckbox, MtDialog, AppTooltip, DownloadIcon },

	data() {
		return {
			personalDataText: '',
			confidentialityText: '',
			showPersonalDataText: false,
			showConfidentialityText: false,
			personalDataChecked: false,
			confidentialityChecked: false,
			policyFileName: 'policy.docx',
			policyFileUrl: '/policy.docx',
			requestPending: false
		};
	},

	methods: {
		send() {
			this.requestPending = true;

			AuthApi.putAgreements({
				personalDataChecked: this.personalDataChecked,
				confidentialityChecked: this.confidentialityChecked
			})
				.then((response) => {
					this.$router.push('/');
				})
				.finally(() => {
					this.requestPending = false;
				});
		},

		validateAgreements() {
			return this.personalDataChecked && this.confidentialityChecked;
		},

		downloadPolicy() {
			const link = document.createElement('a');
			link.href = this.policyFileUrl;
			link.target = '_blank';
			link.download = this.policyFileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	},

	async mounted() {
		const response = await AuthApi.getAgreements();
		if (!response.data?.needAgreements) {
			this.$router.push('/');
			return;
		}
		this.personalDataText = response.data?.personalDataText ?? '';
		this.confidentialityText = response.data?.confidentialityText ?? '';
	}
};
</script>

<style lang="scss" scoped>
.agreement-page {
	display: grid;
	margin-top: -1rem;
	width: 100%;
	padding: 0 1rem;
	max-width: 800px;

	.agreement-row {
		display: flex;
		gap: .75rem;
		align-items: center;

		&.agreement-policy {
			padding-top: .5rem;
			gap: .85rem;
		}

		.agreement-label {
			cursor: pointer;
		}

		.agreement-link {
			color: rgba(7, 124, 146, .95);

			&:hover {
				color: rgba(0, 120, 140, 1);
			}
		}

	}

	.agreement-row+.agreement-row {
		margin-top: 1rem;
	}

	.agreement-action {
		max-width: 300px;
		padding-top: 2.5rem;
	}
}
</style>

<style lang="scss">
.agreement-text {
	color: #252733;

	h1 {
		font-size: 2em;
		font-weight: bold;
		text-align: center;
	}

	h2 {
		font-size: 1.5em;
		font-weight: bold;
		text-align: center;
	}

	h3 {
		font-size: 1.17em;
		font-weight: bold;
		text-align: center;
	}

	p {
		margin: 1rem 0;
	}
}
</style>
