import { makeFileItems } from '@/adapters/files/files.adapter';
import { formatDateTimeByDefault } from '@/services/date';

export const chatMessageIndexInputAdapter = (items) => {
  return items.map(item => makeMessageItem(item))
}
export const chatMessageSingleInputAdapter = (item) => {
  return makeMessageItem(item)
}
function rolesTranslation(role) {
  switch (role) {
    case 'expert': return 'Эксперт'
    case 'inspector': return 'Проверяющий'
    case 'respondent': return 'Респондент'
  }
}
const makeMessageItem = (item) => {
  return {
    files: makeFileItems(item.file),
    value: item.message,
    uuid: item.uuid,
    user: {
      fullName: item.from_user.name,
      role: rolesTranslation(item.from_user.role)
    },
    createdAt: formatDateTimeByDefault(item.created_at)
  }
}