import plan from './plan';

export default {
  namespaced: true,

  state: {},
  getters: {},
  actions: {},
  mutations: {},

  modules: {
    plan
  },
}