<template>
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.25 7.66671H17.1667C17.4208 7.66671 17.6646 7.76767 17.8443 7.9474C18.024 8.12712 18.125 8.37087 18.125 8.62504V20.125C18.125 20.3792 18.024 20.623 17.8443 20.8027C17.6646 20.9824 17.4208 21.0834 17.1667 21.0834H1.83333C1.57917 21.0834 1.33541 20.9824 1.15569 20.8027C0.975967 20.623 0.875 20.3792 0.875 20.125V8.62504C0.875 8.37087 0.975967 8.12712 1.15569 7.9474C1.33541 7.76767 1.57917 7.66671 1.83333 7.66671H3.75V6.70837C3.75 5.18338 4.3558 3.72084 5.43414 2.64251C6.51247 1.56418 7.97501 0.958374 9.5 0.958374C11.025 0.958374 12.4875 1.56418 13.5659 2.64251C14.6442 3.72084 15.25 5.18338 15.25 6.70837V7.66671ZM2.79167 9.58337V19.1667H16.2083V9.58337H2.79167ZM8.54167 13.4167H10.4583V15.3334H8.54167V13.4167ZM4.70833 13.4167H6.625V15.3334H4.70833V13.4167ZM12.375 13.4167H14.2917V15.3334H12.375V13.4167ZM13.3333 7.66671V6.70837C13.3333 5.69171 12.9295 4.71669 12.2106 3.9978C11.4917 3.27891 10.5167 2.87504 9.5 2.87504C8.48334 2.87504 7.50831 3.27891 6.78942 3.9978C6.07053 4.71669 5.66667 5.69171 5.66667 6.70837V7.66671H13.3333Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>

<style scoped>

</style>