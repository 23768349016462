<template>
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      :fill="color"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 8.5C13.8807 8.5 15 7.38071 15 6C15 4.61929 13.8807 3.5 12.5 3.5C11.3255 3.5 10.3402 4.30992 10.072 5.40172C7.12149 6.41069 5 9.20759 5 12.5C5 16.6421 8.35786 20 12.5 20C16.4741 20 19.7263 16.909 19.9836 13H17.9776C17.725 15.8033 15.369 18 12.5 18C9.46243 18 7 15.5376 7 12.5C7 10.1945 8.41848 8.22041 10.4303 7.40271C10.88 8.06494 11.6392 8.5 12.5 8.5Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>