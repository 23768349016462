<script setup lang="ts">
import AppSelect from '../AppSelect/AppSelect.vue'

type Props = {
  modelValue: any
  items: any[]
  itemText: string
  itemValue: string
  disabled: boolean
}

const props = withDefaults(defineProps<Props>(), {
  items: [],
  itemText: 'label',
  itemValue: 'value',
  modelValue: null,
})

const emit = defineEmits(['update:modelValue', 'change'])

const searchText = ref('')
const selectRef = ref()

const computedModelValue = computed({
  get() {
    return props.modelValue
  },

  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const computedItems = computed(() => {
  if (!searchText.value.length) return props.items

  return props.items.filter((item) => {
    const value = item[props.itemText] as string

    if (
      value &&
      value?.toLowerCase().includes(searchText.value.toLocaleLowerCase())
    ) {
      return true
    }

    return false
  })
})

function onChange() {
  emit('change')
}

function clearSearch() {
  searchText.value = ''
}

defineExpose({
  clearSearch
})

watch(
  [() => computedModelValue.value, () => props.items],
  () => {
    if (computedModelValue.value && props.items.length) {
      const item = props.items?.find(
        (item) => item[props.itemValue] === computedModelValue.value,
      )

      searchText.value = item ? item[props.itemText] : ''
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <app-select
    ref="selectRef"
    v-bind="$attrs"
    :active="!!searchText?.length ?? false"
    :items="computedItems"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    v-model="computedModelValue"
    @change="onChange"
  >
    <template #header>
      <input
        type="text"
        v-model="searchText"
        :disabled="disabled"
        class="app__autocomplete__input"
        @focus="selectRef.activateOptions()"
      />
    </template>
    <template #item="item">
      <slot name="item" :item="item" />
    </template>
  </app-select>
</template>

<style lang="scss" src="./AppAutocomplete.scss"></style>
