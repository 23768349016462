export default function (instance) {
    return {
        sendAppeal(body) {
            return instance.post(`support`, body);
        },

        uploadFiles(support_uuid, file) {
            let formData = new FormData();

            formData.append("file", file);
            return instance.post(`support/${support_uuid}/file`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            });
        },

        deleteFile(file_uuid) {
            return instance.delete(`files/${file_uuid}`);
        },
    }
}
