<template>
  <div class="expander">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'mtExpander'
}
</script>

<style lang="scss" src="./mtExpander.scss"/>