<template>
  <app-tooltip>
    <template #activator>
      <button
          :class="[
              'header__notification',
              {
                'header__notification_active': isNotificationAvailable
              }
            ]"
          @click="openBannersDialog()"
      >
        <notification-icon class="header__notification__icon"/>
        <div class="header__notification__unread"/>
      </button>
    </template>
    <template #default>
      {{isNotificationAvailable ? 'Есть уведомления' : 'Нет уведомлений'}}
    </template>
  </app-tooltip>
  <mt-dialog size="md" class="banners-dialog" v-model="bannerDialog">
    <app-slider
        :class="[
            'banners-dialog__slider',
            {
              'banners-dialog__slider_disabled': banners.length === 1
            }
          ]"
        @change="onSliderChange"
        v-if="banners.length > 0"
        v-model="sliderNum"
        :options="{
            prevNextButtons: true,
            pageDots: true,
            wrapAround: true,
            adaptiveHeight: true
          }"
        ref="bannerSlider"
    >
      <div
          class="banners-dialog__item"
          v-for="banner in banners"
      >
        <div class="banners-dialog__item__media" v-if="banner.media">
          <video
              class="banners-dialog__item__media__item"
              controls
              v-if="banner.media?.mime?.includes('video')"
          >
            <source :src="banner.media.url" type="video/mp4"/>
          </video>
          <template
              v-else-if="banner.media?.mime?.includes('image')"
          >
            <img
                :alt="banner.title"
                class="banners-dialog__item__media__item banners-dialog__item__media__item_main"
                :src="banner.media.url"
            />
            <img
                :alt="banner.title"
                class="banners-dialog__item__media__item banners-dialog__item__media__item_bg"
                :src="banner.media.url"
            />
          </template>
        </div>
        <h3 class="banners-dialog__item__title">{{ banner.name }}</h3>
        <h3 class="banners-dialog__item__hint">{{ banner.hint }}</h3>
        <div class="banners-dialog__item__content">
          <p>{{ banner.description }}</p>
        </div>
        <mt-button class="banners-dialog__item__btn" v-if="banner.url" :href="banner.url">
          Перейти
        </mt-button>
      </div>

    </app-slider>

  </mt-dialog>
</template>

<script>
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import AppSlider from "@/components/UI/AppSlider/AppSlider.vue";
import AuthApi from "@/api/auth.api.ts";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import NotificationIcon from "@/components/UI/mtSvg/svg/NotificationIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";

export default {
  name: 'AuthUserNotificationBtn',
  data() {
    return {
      bannerDialog: false,
      banners: [],
      sliderNum: null,
    }
  },
  computed: {
    isNotificationAvailable() {
      return this.banners.some((banner) => !banner.isRead)
    },

  },
  methods: {
    onSliderChange(index) {
      const selectedBanner = this.banners[index];
      this.readBanner(selectedBanner);
    },
    openBannersDialog() {
      this.bannerDialog = true;

      setTimeout(() => {
        this.$refs.bannerSlider.resize();
      });
    },
    readBanner(banner) {
      if(!banner || banner.isRead) return;

      AuthApi.readBanner(banner?.id).then(() => {
        banner.isRead = true;
      });
    },
    async getBanners() {
      const { data } = await AuthApi.getBanners()
      this.banners = data.map(item => {
        return item;
      });
    },
  },
  components: {MtButton, NotificationIcon, AppTooltip, AppSlider, MtDialog},
  mounted() {
    this.getBanners();
  },
  watch: {
    bannerDialog(value) {
      if (value) {
        this.readBanner(this.banners[0]);
      } else {
        const videos = document.querySelectorAll('video');
        videos.forEach((video) => {
          video.pause();
        })
      }
    },
  }

}
</script>

<style
    lang="scss"
    src="./style.scss"
/>