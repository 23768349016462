<template>
  <div
      :class="[
      'app-form-block',
      {
        'app-form-block_half': props.half,
        'app-form-block_hide-details': props.hideDetails
      }
  ]"
  >
    <div
        :class="[
          'app-form-block__label',
          {
            'app-form-block__label--active': props.active
          }
        ]"
    >
      <slot name="label"/>
    </div>

    <slot/>

    <div
        v-if="Array.isArray(errors) && errors.length > 0"
        class="app-form-block__error"
    >
      {{ errors[0].$message }}
    </div>
  </div>
</template>

<script setup lang="ts">

interface Props {
  errors?: any[] | boolean
  hideDetails?: boolean
  half?: boolean
  active?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
  hideDetails: false,
  half: false,
  active: false
})

</script>

<style lang="scss" src="./AppFormBlock.scss"/>