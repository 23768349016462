<template>
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.76775 5.02671L7.59142 5.85038L12.5299 0.911876L13.3548 1.73671L7.59142 7.50004L3.87909 3.78771L4.70392 2.96288L5.9435 4.20246L6.76775 5.02613V5.02671ZM6.76892 3.37704L9.65759 0.487793L10.4801 1.31029L7.59142 4.19954L6.76892 3.37704ZM5.11984 6.67579L4.29559 7.50004L0.583252 3.78771L1.40809 2.96288L2.23234 3.78713L2.23175 3.78771L5.11984 6.67579Z" fill="white"/>
  </svg>


</template>
<script>
export default {

}
</script>