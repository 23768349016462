<template>
  <admin-auth-user-header-block v-if="isAdmin"/>
  <default-auth-user-header-block v-else/>
</template>

<script>
import {mapGetters} from "vuex";
import AdminAuthUserHeaderBlock from "@/components/workspace/auth/AuthUserHeaderBlock/AdminAuthUserHeaderBlock.vue";
import DefaultAuthUserHeaderBlock from "@/components/workspace/auth/AuthUserHeaderBlock/DefaultAuthUserHeaderBlock.vue";

export default {
  name: "AuthUserHeaderBlock",
  components: {DefaultAuthUserHeaderBlock, AdminAuthUserHeaderBlock},
  computed: {
    ...mapGetters(['authUser', 'isAdmin']),
  }
}
</script>

<style scoped>

</style>