<template>
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{'--current-color': color}">
<path d="M7.5 14.5833C6.66282 14.5833 5.83384 14.4184 5.06039 14.0981C4.28694 13.7777 3.58417 13.3081 2.99219 12.7161C2.40022 12.1242 1.93064 11.4214 1.61027 10.6479C1.28989 9.8745 1.125 9.04552 1.125 8.20834C1.125 7.37117 1.28989 6.54219 1.61027 5.76874C1.93064 4.99529 2.40022 4.29251 2.99219 3.70054C3.58417 3.10856 4.28694 2.63898 5.06039 2.31861C5.83384 1.99824 6.66282 1.83334 7.5 1.83334C9.19075 1.83334 10.8123 2.50499 12.0078 3.70054C13.2033 4.89608 13.875 6.51759 13.875 8.20834C13.875 9.8991 13.2033 11.5206 12.0078 12.7161C10.8123 13.9117 9.19075 14.5833 7.5 14.5833V14.5833ZM8.20833 8.20834V4.66668H6.79167V9.62501H10.3333V8.20834H8.20833ZM0.237457 3.44976L2.74142 0.945801L3.74371 1.94738L1.23833 4.45205L0.237457 3.44976ZM12.2579 0.945801L14.7625 3.44976L13.761 4.45205L11.2563 1.94738L12.2586 0.945801H12.2579Z" fill="var(--current-color)"/>
</svg>

</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    }
  },
}
</script>