<template>
    <div class="mt-radio">
      <input
          ref="radioInput"
          :id="randomId"
          :value="value"
          :checked="checked"
          type="radio"
          class="mt-radio__input"
          @change="change"
      />
      <label
          :for="randomId"
          class="mt-radio__label"
      >
        <span class="mt-radio__label__text">
          <slot name="label" />

        <template v-if="!$slots.label">
          {{ label }}
        </template>
        </span>
      </label>
    </div>
</template>

<script>
import { generateRandomID } from "@/services/utils";

export default {
  name: "mtRadio",
  props: {
    value: String,
    label: String
  },
  data: () => ({
    randomId: generateRandomID(),
    checked: false,
  }),
  methods: {
    change() {
      this.$parent.$parent.change({
        label: this.label,
        value: this.value
      })
    }
  }
}
</script>

<style lang="scss" src="./mtRadio.scss" />