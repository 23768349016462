<template>
  <div>
    <component
        :is="getPlansPage(role)"
        :rows="archivePlansRows"
    >
      <template #title>
        Архив планов оценок
      </template>
    </component>
  </div>
</template>

<script>
import plansDefault from "@/components/workspace/assessmentPlans/plans/plansDefault.vue";
import plansExpert from "@/components/workspace/assessmentPlans/plans/plansExpert.vue";
import plansRespondent from "@/components/workspace/assessmentPlans/plans/plansRespondent.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    plansDefault,
    plansExpert,
    plansRespondent,
  },
  computed: {
    ...mapGetters('assessmentPlans/plan', ['archivePlansRows']),

    role() {
      return this.$store.getters['authUser']?.role?.slug || '';
    },
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchArchivePlans']),

    getPlansPage(role) {
      switch (role.toLowerCase()) {
        case 'expert': return 'plansExpert'
        case 'respondent': return 'plansRespondent'
        default: return 'plansDefault'
      }
    }
  },

  async created() {
    if(this.role !== 'admin') await this.fetchArchivePlans();
  }
}
</script>

<style lang="scss">
</style>