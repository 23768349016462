<script>
import StarIcon from "@/components/icons/StarIcon.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import ConfirmationDialog from "@/components/composite/confirmationDialog/confirmationDialog.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import SendIcon from "@/components/icons/SendIcon.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import {mapState} from "vuex";
import FileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import ChatApi from "@/api/chat.api";

export default {
  name: 'CustomAssessmentChat',
  components: {
    SendIcon,
    LinkIcon,
    MtButton,
    AppTextarea,
    ConfirmationDialog,
    TrashIcon,
    FileDownloadCard,
    MtLoadingAnimationLinear,
    StarIcon
  },
  data: () => ({
    chatObserver: null,
    chatMessages: [],
    newMessage: {
      message: '',
      files: []
    },
    pendingActionAfterReset: null,
    isChatLoading: false,
    isMessageSending: false,
    isConfirmationDialogOpen: false
  }),
  computed: {
    ...mapState('assessmentPlans/plan/task', ['mark', 'sessionUuid']),
  },
  methods: {
    checkToExistingNewMessage(action) {
      if (this.newMessage.message || this.newMessage.files.length) {
        this.isConfirmationDialogOpen = true;
        this.pendingActionAfterReset = action;
      } else {
        action();
      }
    },
    confirm() {
      this.isConfirmationDialogOpen = false;
      this.pendingActionAfterReset();
    },
    uploadFiles(event) {
      let files = [];

      event.target.files.forEach((file) => {
        if (file.size > 10485760) {
          this.$toast.error(`Файл ${file.name} превышает максимальный размер 10 МБ`);
          return;
        }

        files.push(file);
      })

      this.newMessage.files = [...this.newMessage.files, ...files];
    },
    deleteFile(index) {
      this.newMessage.files.splice(index, 1);
    },
    sendMessage() {
      if (this.isMessageSending || (this.newMessage.files.length === 0 && !this.newMessage.message)) return;

      this.isMessageSending = true;
      ChatApi.sendMessage(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          this.sessionUuid,
          this.newMessage,
      )
          .then((message) => {
            this.newMessage.message = '';
            this.newMessage.files = [];
            this.chatMessages.push(message);
            this.scrollToEndOfChat()
            setTimeout(() => {
              this.$refs.textarea.calculateHeight();
            })
          })
          .catch((error) => {
            if (error?.data?.message) {
              this.$toast.error(error?.data?.message)
            }
          })
          .finally(() => {
            this.isMessageSending = false;
          });
    },
    scrollToEndOfChat() {
      this.$nextTick(() => {
        const chat = this.$refs.chat;
        chat.scroll(0, chat.scrollHeight);
      });
    },
    chatObserve() {
      const chat = this.$refs.chat;
      const firstChild = chat.children[0]

      if (!firstChild) return;

      this.chatObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const isExistNextPage = this.meta.current_page < this.meta.last_page
            if (isExistNextPage && !this.isChatLoading) {
              this.loadMessages(this.meta.current_page + 1)
              chat.scrollTop = chat.children[0].offsetTop
              this.chatObserver.unobserve(firstChild)
            }
          }
        })
      })
      this.chatObserver.observe(firstChild)
    },
    startChatResizeObserver() {
      const chat = this.$refs.chat;
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(({target}) => {
          const scrollHeight = target.scrollHeight;
          const clientHeight = target.clientHeight;
          chat.style.paddingRight = scrollHeight > clientHeight ? '10px' : 0;
        })
      })
      resizeObserver.observe(chat)
    },
    async loadMessages(page = 1) {
      if (!this.sessionUuid) return;
      this.isChatLoading = true;

      if (page !== 1) this.chatObserve();

      return await ChatApi.getMessages(
          this.$route.params.planUuid,
          this.$route.params.taskUuid,
          this.sessionUuid,
          {
            page
          }
      )
          .then(response => {
            this.isChatLoading = false;
            this.chatMessages = [
              ...response.data.data.reverse(),
              ...this.chatMessages
            ];
            this.meta = response.data.meta;

            this.$nextTick(() => {
              this.chatObserve();
              this.scrollToEndOfChat();
              this.startChatResizeObserver();
            })
          })
          .finally(() => {
            this.isChatLoading = false
          })
    },
  },
  mounted() {
    this.loadMessages();
  },
  watch: {
    sessionUuid() {
      this.loadMessages();
    }
  }
}
</script>

<template>
  <div class="custom-assessment__chat">
    <div class="custom-assessment__title">Ответ</div>
    <div
        v-if="mark"
        class="custom-assessment__chat__item custom-assessment__chat__item_mark"
    >
      <div class="custom-assessment__chat__item__header">
        <star-icon class="custom-assessment__chat__item__star" />
        <h3 class="custom-assessment__chat__item__title">{{ mark.fullName }}</h3>
        <p class="custom-assessment__chat__item__date"> {{ mark.createdAt }}</p>
      </div>
      <div class="custom-assessment__chat__item__chips-row">
        <div
            class="custom-assessment__chat__item__chip"
            v-for="(scale, index) in mark.scales"
            :key="index"
        >
          {{ scale.name }}
          <span>{{ scale.value }}</span>
        </div>
      </div>
    </div>

    <mt-loading-animation-linear v-if="isChatLoading" />

    <div
        ref="chat"
        class="custom-assessment__chat__list"
    >
      <div
          class="custom-assessment__chat__item"
          v-for="message in chatMessages"
          :key="message.uuid"
      >
        <div class="custom-assessment__chat__item__header">
          <h3 class="custom-assessment__chat__item__title">{{ message.user.fullName }} ({{ message.user.role }})</h3>
          <p class="custom-assessment__chat__item__date">{{ message.createdAt }}</p>
        </div>
        <p class="custom-assessment__chat__item__text">{{ message.value }}</p>
        <file-download-card
            class="mt-2"
            :links="message.files"
            color="#fff"
        />
      </div>
    </div>

    <file-download-card
        v-show="newMessage.files.length"
        :links="newMessage.files"
        link-label="name"
        disabled
        class="custom-assessment__input-box__files"
    >
      <h4 class="custom-assessment__input-box__files__title">Файлы для отправки</h4>
      <template #item-append="{index}">
        <trash-icon
            class="custom-assessment__input-box__files__item__delete"
            @click="deleteFile(index)"
        />
      </template>
    </file-download-card>

    <mt-loading-animation-linear
        :class="[
              'custom-assessment__message-send-loading',
              {
                'custom-assessment__message-send-loading_show': isMessageSending
              }
            ]"
    />

    <div class="custom-assessment__input-box">
      <input
          ref="newMessageFileInput"
          type="file"
          multiple="multiple"
          style="display: none;"
          @change="uploadFiles"
      />
      <app-textarea
          v-model="newMessage.message"
          ref="textarea"
          placeholder="Отправить сообщение ..."
          class="custom-assessment__input-box__textarea"
          :loading="isMessageSending"
          :initial-height="25"
          @submit="sendMessage()"
      />
      <mt-button
          icon
          color="white"
          class="custom-assessment__input-box__btn"
          @click="$refs.newMessageFileInput.click()"
      >
        <link-icon />
      </mt-button>
      <mt-button
          icon
          color="white"
          class="custom-assessment__input-box__btn"
          :disabled="isMessageSending"
          @click="sendMessage()"
      >
        <send-icon />
      </mt-button>
    </div>
  </div>

  <confirmation-dialog
      v-model="isConfirmationDialogOpen"
      text="Вы уверены, что хотите перейти к другому заданию?"
      submit-text="Перейти"
      :handler="confirm"
  >
    <template #title>
      Введенное вами сообщение будет потеряно
    </template>
  </confirmation-dialog>
</template>

<style lang="scss">
.custom-assessment__chat {
  margin-top: auto;
  position: relative;
  padding-top: 30px;

  &::after {
    content: "";
    position: absolute;
    left: -30px;
    width: calc(100% + 60px);
    top: 0;
    border-top: 2px solid #DFE0EB;
  }

  &__item {
    margin-bottom: 10px;
    padding: 15px;
    background: $light-gray;
    border-radius: 12px;

    &_mark {
      background-color: $light-green;
    }

    &__header {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin-bottom: 5px;
    }

    &__title {
      flex-grow: 1;
      color: #00788C;
    }

    &__star {
      color: $green;
      margin-right: 8px;
    }

    &__date {
      white-space: nowrap;
      color: #9FA2B4;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #252733;
      margin-top: 5px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &__chips-row {
      display: flex;
      flex-wrap: wrap;
      margin: 10px -5px 0;

      & + & {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    &__chip {
      display: flex;
      margin: 5px;
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 12px;
      font-weight: 600;
      font-size: 14px;
      color: #00788C;
      align-items: center;

      span {
        margin-left: 8px;
        padding: 5px;
        border: 2px solid #00788C;
        border-radius: 3px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }

  &__list {
    max-height: 400px;
    overflow: auto;
    margin-bottom: 15px;
    padding-right: 10px;
  }
}
</style>