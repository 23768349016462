<template>
  <mtDialog
      v-model="computedModelValue"
      :size="size"
  >
    <div class="confirmation-dialog">
      <div
          v-if="$slots.icon"
          class="confirmation-dialog__icon"
      >
        <slot name="icon"/>
      </div>
      <h2 class="confirmation-dialog__title">
        <slot
            v-if="$slots.title"
            name="title"
        />

        <template v-else>{{ title }}</template>
      </h2>
      <p class="confirmation-dialog__text">{{ text }}</p>
      <div class="confirmation-dialog__btns">
        <mt-button
            class="confirmation-dialog__submit-btn"
            @click="close"
        >
          Закрыть
        </mt-button>
        <mt-button
            @click="submit"
            :loading="requestPending"
        >
          {{ submitText }}
        </mt-button>
      </div>
    </div>
  </mtDialog>
</template>

<script>
import mtDialog from '@/components/UI/mtDialog/mtDialog.vue';
import mtButton from '@/components/UI/mtButton/index.vue';

export default {
  name: "confirmationDialog",
  components: {mtButton, mtDialog},
  data() {
    return {
      requestPending: false
    }
  },
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: 'Подтвердить'
    },
    text: String,
    size: {
      type: String,
      default: 'sm'
    },
    submitText: {
      type: String,
      default: 'Подтвердить'
    },
    handler: Function
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    async submit() {
      this.requestPending = true;
      await this.handler()
          .finally(() => {
            this.requestPending = false
            this.close()
          })
    },

    close() {
      this.computedModelValue = false
    }
  }
}
</script>

<style
    lang="scss"
    src="./confirmationDialog.scss"
/>