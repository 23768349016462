<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";
import Flickity from "flickity";


const emit = defineEmits(['change']);

const props = defineProps({
  options: {
    type: Object
  }
});
let flickity: Flickity | null = null;
const root = ref<HTMLElement | null>(null);

onMounted(() => {
  flickity = new Flickity(root.value as HTMLElement, props.options)

  flickity.on('change', function(index: number) {
    emit('change', index)
  })
})

onUnmounted(() => flickity?.destroy())

function resize () {
  flickity.resize();
}


defineExpose({
  resize
});

</script>

<template>
  <div
    ref="root"
    class="flickity"
  >
    <slot />
  </div>
</template>

<style lang="scss" src="./AppSlider.scss" />