export default {
  install: ( App ) => {
    App.config.globalProperties.$helpersFunc = {
      has ( nodeList, element ) {
        let classList = element.className.toString().split( ' ' );
        classList[ 0 ] = '.' + classList[ 0 ];

        let selector = classList.join( '.' );

        return selector !== '.' ? Array.from( nodeList ).filter( e => e.querySelector( selector ) ) : [];
      },
      formattedDate (timestamp, time = false) {
        let d = new Date(timestamp)
        let year = d.getFullYear().toString()
        let day = d.getDate()
        let month = d.getMonth() + 1
        let hours = d.getHours()
        let minuts  = d.getMinutes()

        const fmt = (i) => {
          if (i.toString().length === 1) return `0${i}`
          else return i
        }

        day = fmt(day)
        month = fmt(month)
        hours = fmt(hours)
        minuts = fmt(minuts)

        return `${day}.${month}.${year}${time ? ' ' + hours + ':' + minuts : '' }`
      }
    };
  }
}