import * as validators from "@vuelidate/validators";

const validationMessages = {
  email: "Введен неправильный формат e-mail",
  maxValue: "Максимальная сумма ",
  minValue: "Сумма менее %{minValue}",
  required: "Обязательное поле",
  mustEnter: "Поля обязательны для заполнения",
  onlyLatin: "Только латинские буквы",
  incorrectFormat: "Неверный формат",
  minLength: "Минимальное кол-во символов: ",
  maxLength: "Максимальная  кол-во символов: "
};

export function useValidations() {

  const requiredIf = (cb) => {
    return validators.helpers.withMessage(() => validationMessages["required"],
      validators.requiredIf(cb))
  }

  const required = validators.helpers.withMessage(
    () => validationMessages["required"],
    validators.required
  );

  const minLength = (value) => {
    return validators.helpers.withMessage(
      () => validationMessages["minLength"] + value,
      validators.minLength(value)
    );
  };

  const maxLength = (value) => {
    return validators.helpers.withMessage(
      () => validationMessages["maxLength"] + value,
      validators.maxLength(value)
    );
  };

  const minValue = (value) => {
    return validators.helpers.withMessage(
      () => validationMessages["minValue"] + value,
      validators.minValue(value)
    );
  };

  const maxValue = (value) => {
    return validators.helpers.withMessage(
      () => validationMessages["maxValue"] + value,
      validators.maxValue(value)
    );
  };

  const email = validators.helpers.withMessage(
    () => validationMessages["email"],
    validators.email
  );

  return {
    requiredIf,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    email,
  };
}


export function getVuelidateError(errors) {
  if (errors.length === 0) return;

  return errors[0].$message;
}