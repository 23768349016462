<template>

</template>

<script>
export default {
  name: "Upload"
}
</script>

<style scoped>
svg {
  width: 18px;
}
svg path {
  fill: #7C869D;
}
</style>