<template>
  <auth-wrapper>
    <template #title>Введите постоянный пароль</template>
    <template #body>
      <div class="auth__fields">
        <app-input-password
          v-model="payload.password"
          label="Новый пароль"
          :errors="v$.payload.password.$errors"
          @keyup.enter="setPermanentPassword()"
        />
        <app-input-password
          v-model="payload.confirmPassword"
          label="Повторите пароль"
          :errors="v$.payload.confirmPassword.$errors"
          @keyup.enter="setPermanentPassword()"
        />
        <password-rules
          ref="passwordRules"
          :password-for-check="payload.password"
        />
      </div>

      <div class="auth__actions">
        <mt-button
          class="auth__actions__btn"
          @click="setPermanentPassword()"
          :loading="requestPending"
        >
          Продолжить
        </mt-button>
      </div>
    </template>
  </auth-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mtButton from '@/components/UI/mtButton/index.vue'
import mtInput from '@/components/UI/mtInput/mtInput.vue'
import validation from '@/plugins/validation.js'
import AuthWrapper from '@/components/workspace/auth/AuthWrapper/index.vue'
import genders from '@/constants/genders'
import MtRadioGroup from '@/components/UI/mtRadio/mtRadioGroup.vue'
import MtRadio from '@/components/UI/mtRadio/mtRadio.vue'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import AuthApi from '@/api/auth.api.ts'
import PasswordRules from '@/components/workspace/auth/PasswordRules/index.vue'
import { helpers, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import AppInputPassword from '@/components/UI/AppInputPassword.vue'
import { useValidations } from '@/plugins/vuelidate-wrapper'

export default {
  components: {
    AppInputPassword,
    PasswordRules,
    AppInput,
    MtRadio,
    MtRadioGroup,
    AuthWrapper,
    mtInput,
    mtButton,
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      payload: {
        confirmPassword: null,
        password: null,
      },
      requestPending: false,

      passwordSecurity: [],
    }
  },
  validations() {
    const { required } = useValidations()
    return {
      payload: {
        password: {
          required,
          isValid: helpers.withMessage(
            'Пожалуйста, учтите все правила создания пароля',
            helpers.withAsync(
              async (value) => this.$refs.passwordRules.allRulesAreValid,
            ),
          ),
        },
        confirmPassword: {
          required,
          sameAs: helpers.withMessage(
            'Пароль не совпадают',
            sameAs(this.payload.password),
          ),
        },
      },
    }
  },
  computed: {},

  methods: {
    async setPermanentPassword() {
      const validated = await this.v$.$validate()
      if (!validated) return

      this.requestPending = true
      AuthApi.setPermanentPassword({
        password: this.payload.password,
      })
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          this.requestPending = false
        })
    },
  },
}
</script>

<style lang="scss"></style>
