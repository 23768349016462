import api from "@/network";

type DeleteDto = {
    planUuid: string;
    testUuid: string;
}
export default {
    async upload(file: File, signal?: AbortSignal): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return await api.post<string>('/temporary-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            signal
        })
            .then(({data}) => data);
    },
    async get(uuids: string[]): Promise<any[]> {
        return await api.get('/temporary-files', {
            params: {
                uuids
            }
        });
    }
}