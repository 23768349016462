<template>
  <svg
      :style="{'--current-color': color}"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M0 12.0001H6V10.0001H0V0.846106C1.48755e-05 0.75922 0.0226703 0.673839 0.0657334 0.598376C0.108797 0.522913 0.170781 0.459974 0.245577 0.415763C0.320372 0.371552 0.405398 0.347594 0.492273 0.346251C0.579148 0.344908 0.664873 0.366227 0.741 0.408106L19.203 10.5621C19.2814 10.6053 19.3468 10.6687 19.3923 10.7457C19.4378 10.8228 19.4618 10.9106 19.4618 11.0001C19.4618 11.0896 19.4378 11.1774 19.3923 11.2545C19.3468 11.3315 19.2814 11.3949 19.203 11.4381L0.741 21.5921C0.664873 21.634 0.579148 21.6553 0.492273 21.654C0.405398 21.6526 0.320372 21.6287 0.245577 21.5844C0.170781 21.5402 0.108797 21.4773 0.0657334 21.4018C0.0226703 21.3264 1.48755e-05 21.241 0 21.1541V12.0001Z"
        fill="var(--current-color)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    },
  }
}
</script>