<script
    setup
    lang="ts"
>

</script>

<template>
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4821 9.98214H13.3214C13.1538 9.98214 13.0179 9.84622 13.0179 9.67857V4.51786C13.0179 3.67963 12.3382 3 11.5 3C10.6618 3 9.98214 3.67963 9.98214 4.51786V9.67857C9.98214 9.84622 9.84622 9.98214 9.67857 9.98214H4.51786C3.67963 9.98214 3 10.6618 3 11.5C3 12.3382 3.67963 13.0179 4.51786 13.0179H9.67857C9.84622 13.0179 9.98214 13.1538 9.98214 13.3214V18.4821C9.98214 19.3204 10.6618 20 11.5 20C12.3382 20 13.0179 19.3204 13.0179 18.4821V13.3214C13.0179 13.1538 13.1538 13.0179 13.3214 13.0179H18.4821C19.3204 13.0179 20 12.3382 20 11.5C20 10.6618 19.3204 9.98214 18.4821 9.98214Z"
    />
  </svg>
</template>