<template>
  <div class="user-info">
    <h4 class="user-info__name">
      <slot name="title"/>
    </h4>
    <h5 class="user-info__role">
      <slot name="subtitle"/>
    </h5>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AuthUserHeaderBlockWrapper",
  computed: {
    ...mapGetters(['authUser']),
  }
}
</script>

<style lang="scss" src="./style.scss"/>