export default function ( instance ) {
  return {
    getAll ( per_page = 5, page = 1, name = '', sort = null /*filters = {}*/ )
    {
      let params = {
        data : JSON.stringify(
          {
            name      : name,
            sort      : sort,
            //filters   : filters,
          }
        )
      };

      //return instance.get( `testResult?per_page=${per_page}&page=${page}`, { params : params } );
      return instance.get( `plans/results?per_page=${per_page}&page=${page}`, { params : params } );
    },
  }
}