<template>
  <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="{'--current-color': color}"
  >
    <path
        d="M9.27716 3.48125L10.1247 2.63367L10.9496 3.4585L10.102 4.30609C10.9395 5.35443 11.3439 6.68368 11.232 8.02083C11.1201 9.35798 10.5004 10.6015 9.50028 11.4961C8.50015 12.3906 7.19546 12.8683 5.85415 12.831C4.51285 12.7936 3.23675 12.2441 2.28794 11.2953C1.33912 10.3465 0.789619 9.0704 0.752279 7.72909C0.71494 6.38779 1.1926 5.0831 2.08716 4.08297C2.98171 3.08284 4.22527 2.46319 5.56242 2.35129C6.89957 2.23939 8.22881 2.64372 9.27716 3.48125ZM5.99999 11.6666C6.53622 11.6666 7.0672 11.561 7.56262 11.3558C8.05803 11.1506 8.50817 10.8498 8.88735 10.4706C9.26652 10.0914 9.5673 9.64129 9.7725 9.14588C9.97771 8.65046 10.0833 8.11948 10.0833 7.58325C10.0833 7.04702 9.97771 6.51604 9.7725 6.02063C9.5673 5.52521 9.26652 5.07507 8.88735 4.6959C8.50817 4.31673 8.05803 4.01595 7.56262 3.81074C7.0672 3.60554 6.53622 3.49992 5.99999 3.49992C4.91703 3.49992 3.87841 3.93013 3.11264 4.6959C2.34687 5.46167 1.91666 6.50028 1.91666 7.58325C1.91666 8.66622 2.34687 9.70483 3.11264 10.4706C3.87841 11.2364 4.91703 11.6666 5.99999 11.6666ZM5.41666 4.66658H6.58333V8.16658H5.41666V4.66658ZM3.66666 0.583252H8.33333V1.74992H3.66666V0.583252Z"
        fill="var(--current-color)"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000",
    }
  },
}
</script>