<script setup>

</script>

<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7439_10088)">
      <path d="M14 8C14 6.80653 13.5259 5.66193 12.682 4.81802C11.8381 3.97411 10.6935 3.5 9.5 3.5C8.30653 3.5 7.16193 3.97411 6.31802 4.81802C5.47411 5.66193 5 6.80653 5 8V14H14V8ZM15.5 14.5002L15.8 14.9C15.8418 14.9557 15.8672 15.022 15.8735 15.0913C15.8797 15.1607 15.8666 15.2304 15.8354 15.2927C15.8043 15.355 15.7564 15.4074 15.6971 15.444C15.6379 15.4806 15.5696 15.5 15.5 15.5H3.5C3.43036 15.5 3.36209 15.4806 3.30285 15.444C3.24361 15.4074 3.19573 15.355 3.16459 15.2927C3.13345 15.2304 3.12026 15.1607 3.12652 15.0913C3.13277 15.022 3.15821 14.9557 3.2 14.9L3.5 14.5002V8C3.5 6.4087 4.13214 4.88258 5.25736 3.75736C6.38258 2.63214 7.9087 2 9.5 2C11.0913 2 12.6174 2.63214 13.7426 3.75736C14.8679 4.88258 15.5 6.4087 15.5 8V14.5002ZM7.625 16.25H11.375C11.375 16.7473 11.1775 17.2242 10.8258 17.5758C10.4742 17.9275 9.99728 18.125 9.5 18.125C9.00272 18.125 8.52581 17.9275 8.17417 17.5758C7.82254 17.2242 7.625 16.7473 7.625 16.25Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_7439_10088">
        <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>

</template>