<template>
  <mt-card
      :icon="assessmentPlan?.icon?.name"
      :class="['assessment-plans-card', 'assessment-plans-card--' + assessmentPlan?.icon?.className]"
  >
    <template #header>
      <h3 class="assessment-plans-card__title">{{ assessmentPlan.name }}</h3>
      <div
          v-if="assessmentPlan.notifications"
          class="assessment-plans-card__notification-icon"
      >
        <img
            src="/img/icons/notification.svg"

        >
      </div>
    </template>
    <template #content>
      <mt-show-more-text
          class="assessment-plans-card__desc"
          height="67px"
      >
        <p>{{ assessmentPlan.description }}</p>
      </mt-show-more-text>

      <div class="assessment-plans-card__footer">
        <plan-test-time :item="assessmentPlan"/>
        <div class="assessment-plans-card__statistics" v-if="showProgress">
          <slot
              name="item-statistics"
              v-bind="assessmentPlan"
          />
          <div class="assessment-plans-card__progress assessment-plans-card__progress_wrap">
            <div class="assessment-plans-card__progress__text">
              <div class="assessment-plans-card__progress__title">Прогресс: {{ assessmentPlan.progress }}%</div>
            </div>
            <mt-linear-progress
                class="assessment-plans-card__progress__linear"
                :height="8"
                background-color="#fff"
                :progress="assessmentPlan.progress"
            />
          </div>
        </div>
        <mt-button
            class="assessment-plans-card__btn"
            :disabled="!assessmentPlan.isOpened"
            @click="goToPlanPage(assessmentPlan.uuid)"
        >
          {{ assessmentPlan.btn.label }}
        </mt-button>
      </div>

    </template>
  </mt-card>
</template>

<script>
import mtLinearProgress from "@/components/UI/mtLinearProgress/mtLinearProgress.vue";
import mtCard from "@/components/UI/mtCard/index.vue";
import mtButton from "@/components/UI/mtButton/index.vue";
import mtShowMoreText from "@/components/UI/mtShowMoreText/index.vue";
import PlanTestTime from '@/components/workspace/assessmentPlans/planTestTime/index.vue';

export default {
  name: "assessmentPlanCard",
  components: {
    PlanTestTime,
    mtCard,
    mtButton,
    mtShowMoreText,
    mtLinearProgress
  },
  computed: {
    role() {
      return this.$store.getters['authUser']?.role?.slug || '';
    },
    showProgress() {
      return this.role != 'respondent' || this.assessmentPlan.show_progress
    },
  },
  props: {
    assessmentPlan: {
      type: Object
    }
  },

  methods: {
    goToPlanPage(uuid) {
      const routeName = this.$route.name === 'AssessmentPlansArchive' ? 'AssessmentPlansArchiveSingle' : 'ExpRespAssessmentPlanSingle'
      this.$router.push({
        name: routeName,
        params: {
          planUuid: uuid
        }
      })
    }
  },
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>