import api from "@/network";
import {TestEntity} from "@/types/test.entity.ts";
import {Keyable} from "@/types/global.ts";
import PaginationEntity from "@/types/pagination.entity.ts";
import {AxiosResponse} from "axios";


type UpdateDto = {
    uuids: string[],
    status: boolean,
}

type GetAllDto = {
    is_pro?: number;
    page?: number,
    per_page?: number,
    settings?: object,
}
type AttachTagsDto = {
    tests: {
        uuid: string,
        tags: string[]
    }
}

export default {
    async getAll(params: GetAllDto): Promise<AxiosResponse<PaginationEntity<TestEntity>>> {
        let documentationTypeLabel: {
            [key: string]: string
        } = {
            'methodics': 'Методическое пособие',
            'annotation': 'Аннотация'
        }

        const response = await api.get(`tests`, {
            params: {
                per_page: params.per_page,
                page: params.page,
                settings: params.settings,
                is_pro: params.is_pro ? '1' : '0',
            }
        });

        response.data.data = response.data.data.map((test: Keyable): TestEntity => {
            return {
                documentations: Object.keys(test.documentations).map((key) => {
                    return {
                        link: test.documentations[key],
                        label: documentationTypeLabel[key]
                    }
                }),
                version: test.version,
                name: test.TestTitle,
                maintestName: test.MaintestName,
                uuid: test.uuid,
                sessions: test.sessions,
                results: test.results,
                plans: test.plans,
                cost: test.cost,
                status: test.status
            }
        });

        return response;


    },
    async update(payload: UpdateDto) {
        return await api.put(`tests/active`, payload);
    },
    async attachTags(payload: AttachTagsDto) {
        return await api.put(`tests/tags-attach`, payload);
    },
    async run(uuid: string) {
        return await api.post(`tests/${uuid}/sessions`);
    },

}
