<template>
  <div
      :class="[
        'c-alert',
        'c-alert--' + type,
        {
          'c-alert--hide-icon': hideIcon
        }
      ]"
  >
    <div class="c-alert__icon">{{ computedIcon }}</div>
    <div class="c-alert__block">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAlert",
  props: {
    type: String,
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedIcon() {
      if (this.type === 'info') return 'i'
      return '!'
    }
  }

}
</script>

<style lang="scss" src="./AppAlert.scss"/>