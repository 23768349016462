<template>
  <div
      class="mt-popover"
      :style="computedStyles"
      ref="popup"
      v-click-outside="vcoSettings"
      v-show="computedModelValue"
  >
    <svg
        class="mt-popover__arrow"
        width="19"
        height="12"
        viewBox="0 0 19 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="popupPosition.arrowOffset"
    >
      <path
          d="M10.8451 1.58526C10.0641 0.804913 8.79862 0.804912 8.0177 1.58526L1.59032 8.00788C0.329671 9.2676 1.22184 11.4226 3.00401 11.4226H15.8588C17.6409 11.4226 18.5331 9.2676 17.2725 8.00788L10.8451 1.58526Z"
          fill="white"
      />
      <path
          d="M10.1382 2.29264L16.5654 8.71525C16.6533 8.80286 16.7197 8.89918 16.7676 9.00001H17.8198C17.7441 8.64634 17.5674 8.30262 17.2725 8.00788L10.8452 1.58527C10.064 0.804909 8.79883 0.804909 8.01758 1.58527L1.59033 8.00788C1.29541 8.30262 1.11865 8.64634 1.04297 9.00001H2.09521C2.14307 8.89918 2.20947 8.80286 2.29736 8.71525L8.72461 2.29264C9.11523 1.90247 9.74756 1.90247 10.1382 2.29264Z"
          fill="#00788C"
      />
    </svg>
    <div
        class="mt-popover__body"
        :style="{maxHeight: popupPosition.height}"
    >
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      popupPosition: {
        top: 0,
        left: 0,
        arrowOffset: {
          left: '30px'
        },
        ready: false
      },
      vcoSettings: {
        handler: this.close,
        events: ['mousedown'],
        isActive: true,
        detectIFrame: true,
        capture: false
      }
    }
  },

  computed: {
    computedModelValue() {
      return this.modelValue
    },

    computedStyles() {
      return {
        left: this.popupPosition.left + 'px',
        top: this.popupPosition.top + 'px',
        width: !!this.width ? `${ this.width }px` : 'auto'
      }
    }
  },

  created() {
    document.addEventListener('click', this.initPopupPosition)
  },

  methods: {
    close() {
      this.$emit('update:modelValue', false)
      this.$emit('close')
      this.popupPosition.ready = false
    },

    initPopupPosition(e) {
      if(!this.computedModelValue || this.popupPosition.ready) return;

      let bound = this.$refs.popup.getBoundingClientRect();
      let positionOnTop = false;
      let x = e.pageX;
      let y = e.pageY;
      let hasOverflow = (window.innerHeight - y) < (bound.height + 15);

      if (bound.width >= window.innerWidth - x) {
        x = window.innerWidth - bound.width - 20;
      }
      else {
        const res = (bound.width / 2 + 20) > e.pageX;
        if(!res) {
          x = e.pageX - (bound.width / 2 + 20);
        }
        else {
          x = e.pageX - 15
        }
      }

      let offset = {
        left: e.pageX - x
      };

      if (y > (window.innerHeight / 2)) {
        y = y - bound.height - 40;
        offset.top = 'unset';
        offset.bottom = '-11px';
        offset.transform = 'rotate(180deg)';
        positionOnTop = true;
        hasOverflow = y < (bound.height + 15)
      }

      this.popupPosition = {
        top: y + 20,
        left: x,
        arrowOffset: offset,
        overflow: hasOverflow,
        height: hasOverflow ? (window.innerHeight - y) - 35 + 'px' : '',
        ready: true
      }
    },
  },

  beforeUnmount() {
    document.removeEventListener('click', this.initPopupPosition)
  }
}

</script>

<style lang="scss" scoped src="./mtPopover.scss" />