import {deepClone} from "@/services/utils.js";

export default {
  namespaced: true,

  state: {
    breadcrumbs: [],
  },

  getters: {
    breadcrumbs(state) {
      return state.breadcrumbs;
    }
  },

  actions: {
    setBreadcrumbs({ state }, breadcrumbs) {
      state.breadcrumbs = breadcrumbs ? deepClone(breadcrumbs) : [];
    },
  }
}