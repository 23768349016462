<template>
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      :fill="fill"
      :class="['mt-icon', {'mt-icon_preloader': preloader}]"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="getIconData"
    />
  </svg>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: 'search'
    },
    fill: {
      type: String,
      default: '#000'
    },
    preloader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons: {
        "preloader": "M12.5 8.5C13.8807 8.5 15 7.38071 15 6C15 4.61929 13.8807 3.5 12.5 3.5C11.3255 3.5 10.3402 4.30992 10.072 5.40172C7.12149 6.41069 5 9.20759 5 12.5C5 16.6421 8.35786 20 12.5 20C16.4741 20 19.7263 16.909 19.9836 13H17.9776C17.725 15.8033 15.369 18 12.5 18C9.46243 18 7 15.5376 7 12.5C7 10.1945 8.41848 8.22041 10.4303 7.40271C10.88 8.06494 11.6392 8.5 12.5 8.5Z",
        "search": "M16.7825 15.3093L21.2439 19.7697L19.77 21.2437L15.3095 16.7822C13.6499 18.1126 11.5856 18.8363 9.4585 18.8333C4.2835 18.8333 0.0834961 14.6333 0.0834961 9.45825C0.0834961 4.28325 4.2835 0.083252 9.4585 0.083252C14.6335 0.083252 18.8335 4.28325 18.8335 9.45825C18.8365 11.5853 18.1129 13.6496 16.7825 15.3093ZM14.6929 14.5364C16.0149 13.1769 16.7531 11.3545 16.7502 9.45825C16.7502 5.42909 13.4866 2.16659 9.4585 2.16659C5.42933 2.16659 2.16683 5.42909 2.16683 9.45825C2.16683 13.4864 5.42933 16.7499 9.4585 16.7499C11.3548 16.7529 13.1771 16.0146 14.5366 14.6926L14.6929 14.5364Z",
        "filters": "M17.9952 0.125H1.00493C0.224239 0.125 -0.169694 1.07224 0.383468 1.6254L7.15624 8.3992V15.9453C7.15624 16.2321 7.29617 16.5008 7.53113 16.6653L10.4608 18.7154C11.0388 19.12 11.8437 18.7099 11.8437 17.9954V8.3992L18.6167 1.6254C19.1687 1.07334 18.7775 0.125 17.9952 0.125Z",
        "cols": "M18.8752 14.2291V16.8333H0.12519L0.125 14.2291H9.50019H18.8752ZM15.1294 0.0666504L19.9169 4.85415L15.1294 9.64165L13.6564 8.16873L16.971 4.85415L13.6564 1.53957L15.1294 0.0666504ZM9.50019 7.45832V10.0624H0.12519V7.45832H9.50019ZM9.50019 0.16665V2.77076H0.125L0.12519 0.16665H9.50019Z",
        "shevron-left": "M13.1722 12L8.22217 7.05L9.63617 5.636L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z",
        "check": "M16.6596 7.24839C17.0747 7.61267 17.1159 8.24449 16.7516 8.6596L10.6088 15.6596C10.4275 15.8661 10.1693 15.989 9.89475 15.9993C9.62018 16.0096 9.35345 15.9065 9.15721 15.7142L6.30007 12.9142C5.90562 12.5277 5.89923 11.8945 6.28579 11.5001C6.67235 11.1056 7.30548 11.0992 7.69993 11.4858L9.80244 13.5463L15.2484 7.34042C15.6127 6.92531 16.2445 6.88411 16.6596 7.24839Z",
        "plus": "M18.4821 9.98214H13.3214C13.1538 9.98214 13.0179 9.84622 13.0179 9.67857V4.51786C13.0179 3.67963 12.3382 3 11.5 3C10.6618 3 9.98214 3.67963 9.98214 4.51786V9.67857C9.98214 9.84622 9.84622 9.98214 9.67857 9.98214H4.51786C3.67963 9.98214 3 10.6618 3 11.5C3 12.3382 3.67963 13.0179 4.51786 13.0179H9.67857C9.84622 13.0179 9.98214 13.1538 9.98214 13.3214V18.4821C9.98214 19.3204 10.6618 20 11.5 20C12.3382 20 13.0179 19.3204 13.0179 18.4821V13.3214C13.0179 13.1538 13.1538 13.0179 13.3214 13.0179H18.4821C19.3204 13.0179 20 12.3382 20 11.5C20 10.6618 19.3204 9.98214 18.4821 9.98214Z",
        "cross": "M12.364 10.95L17.314 6L18.728 7.414L13.778 12.364L18.728 17.314L17.314 18.728L12.364 13.778L7.414 18.728L6 17.314L10.95 12.364L6 7.414L7.414 6L12.364 10.95Z",
        "arrow-down": "M5.29289 8.2929C5.68342 7.90238 6.31658 7.90238 6.70711 8.2929L12 13.5858L17.2929 8.2929C17.6834 7.90238 18.3166 7.90238 18.7071 8.2929C19.0976 8.68343 19.0976 9.31659 18.7071 9.70712L12.7778 15.6364C12.3482 16.066 11.6518 16.066 11.2222 15.6364L5.29289 9.70712C4.90237 9.31659 4.90237 8.68343 5.29289 8.2929Z",
        "basket": "M10 3C9.73478 3 9.48043 3.10536 9.29289 3.29289C9.10536 3.48043 9 3.73478 9 4V5H15V4C15 3.73478 14.8946 3.48043 14.7071 3.29289C14.5196 3.10536 14.2652 3 14 3H10ZM17 5V4C17 3.20435 16.6839 2.44129 16.1213 1.87868C15.5587 1.31607 14.7956 1 14 1H10C9.20435 1 8.44129 1.31607 7.87868 1.87868C7.31607 2.44129 7 3.20435 7 4V5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 20.7957 4.31607 21.5587 4.87868 22.1213C5.44129 22.6839 6.20435 23 7 23H17C17.7957 23 18.5587 22.6839 19.1213 22.1213C19.6839 21.5587 20 20.7957 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H17ZM6 7V20C6 20.2652 6.10536 20.5196 6.29289 20.7071C6.48043 20.8946 6.73478 21 7 21H17C17.2652 21 17.5196 20.8946 17.7071 20.7071C17.8946 20.5196 18 20.2652 18 20V7H6ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM13 11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11Z",
        "email-settings": "M20 7.238L12.072 14.338L4 7.216V19H14V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13H20V7.238ZM19.501 5H4.511L12.061 11.662L19.502 5H19.501ZM17.05 19.548C16.9831 19.1857 16.9831 18.8143 17.05 18.452L16.036 17.866L17.036 16.134L18.05 16.72C18.328 16.482 18.649 16.295 19 16.17V15H21V16.17C21.351 16.295 21.672 16.482 21.95 16.72L22.964 16.134L23.964 17.866L22.95 18.452C23.0169 18.8143 23.0169 19.1857 22.95 19.548L23.964 20.134L22.964 21.866L21.95 21.28C21.6699 21.5203 21.3478 21.7067 21 21.83V23H19V21.83C18.6522 21.7067 18.3301 21.5203 18.05 21.28L17.036 21.866L16.036 20.134L17.05 19.548ZM20 20C20.2652 20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19C21 18.7348 20.8946 18.4804 20.7071 18.2929C20.5196 18.1054 20.2652 18 20 18C19.7348 18 19.4804 18.1054 19.2929 18.2929C19.1054 18.4804 19 18.7348 19 19C19 19.2652 19.1054 19.5196 19.2929 19.7071C19.4804 19.8946 19.7348 20 20 20Z",
        "menu": "M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z",
        "edit": "M6.414 16L16.556 5.85796L15.142 4.44396L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32196C14.6225 2.13449 14.8768 2.02917 15.142 2.02917C15.4072 2.02917 15.6615 2.13449 15.849 2.32196L18.678 5.15096C18.8655 5.33849 18.9708 5.5928 18.9708 5.85796C18.9708 6.12313 18.8655 6.37743 18.678 6.56496L7.243 18ZM3 20H21V22H3V20Z",
        "forbidden": "M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM16.891 8.523L8.523 16.891C7.97637 16.5015 7.49849 16.0236 7.109 15.477L15.477 7.109C16.0236 7.49849 16.5015 7.97637 16.891 8.523V8.523Z",
        "play": "M8.49997 7.89566V16.1043L15.535 12L8.49997 7.89566ZM7.04397 4.34666L19.3033 11.4983C19.3912 11.5498 19.464 11.6233 19.5147 11.7116C19.5653 11.7999 19.5919 11.8999 19.5919 12.0017C19.5919 12.1035 19.5653 12.2036 19.5147 12.2919C19.464 12.3802 19.3912 12.4537 19.3033 12.5052L7.04397 19.6568C6.95506 19.7087 6.85401 19.7361 6.75107 19.7363C6.64813 19.7365 6.54698 19.7095 6.45787 19.6579C6.36877 19.6064 6.29489 19.5322 6.24374 19.4429C6.19259 19.3535 6.16599 19.2523 6.16664 19.1493V4.8495C6.16661 4.74686 6.19366 4.64603 6.24506 4.55719C6.29647 4.46835 6.3704 4.39464 6.4594 4.34351C6.5484 4.29239 6.64931 4.26565 6.75195 4.26599C6.85459 4.26634 6.95532 4.29377 7.04397 4.3455V4.34666Z"
      }
    }
  },
  computed: {
    getIconData() {
      if (this.preloader) return this.icons['preloader']
      return this.icons[this.name] || this.icons[0]
    }
  }
}
</script>

<style lang="scss" src="./mtIcon.scss" />