<template>
  <mt-expander-item
    :disabled="assessmentPlans.plans?.length === 0"
    class="assessment-plans"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #content>
      <div class="assessment-plan-cards-row" v-if="isLoading">
        <test-card-skeleton-loader v-for="i in 4" />
      </div>
      <div
        v-if="!isLoading && assessmentPlans.plans?.length"
        class="assessment-plan-cards-row"
      >
        <assessment-plan-card
          v-for="assessmentPlan in assessmentPlans.plans"
          :key="assessmentPlan.uuid"
          :assessment-plan="assessmentPlan"
        >
          <template #item-statistics="item">
            <slot name="item-statistics" v-bind="item" />
          </template>
        </assessment-plan-card>
      </div>
      <alert
        v-if="!isLoading && !assessmentPlans.plans?.length"
        type="info"
        class="assessment-plan-alert"
      >
        Нет планов оценок
      </alert>
      <button
        class="assessment-plans__archive-link"
        v-if="
          assessmentPlans.meta?.current_page !== assessmentPlans.meta?.last_page
        "
        @click="updateAssessmentPlansList(assessmentPlans)"
      >
        Загружать ещё
      </button>
    </template>
  </mt-expander-item>
</template>

<script>
import assessmentPlanCard from '@/components/workspace/assessmentPlans/plans/card/index.vue'
import mtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue'
import Alert from '@/components/UI/AppAlert/AppAlert.vue'
import TestCardSkeletonLoader from '@/components/workspace/assessmentPlans/plans/card/CardSkeletonLoader.vue'
import { mapState } from 'vuex'

export default {
  name: 'assessmentPlansRow',
  components: {
    Alert,
    mtExpanderItem,
    assessmentPlanCard,
    TestCardSkeletonLoader,
  },
  props: {
    assessmentPlans: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    ...mapState('assessmentPlans/plan', ['isLoading']),
  },
  methods: {
    updateAssessmentPlansList(data) {
      this.$emit('update', data)
    },
  },
}
</script>

<style lang="scss" src="./style.scss" />
