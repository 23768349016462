<template>
  <app-preloader v-if="!isLoaded"/>
  <component
    v-else
    :is="layout"
  >
    <router-view />
  </component>
  <app-toast />
</template>

<script>
import "external-svg-loader";
import WorkspaceMainLayout from "@/components/layouts/WorkspaceMainLayout.vue";
import AuthMainLayout from "@/components/layouts/AuthMainLayout.vue";
import AppToast from "./components/UI/AppToast/AppToast.vue";
import AppPreloader from "@/components/workspace/AppPreloader.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    AppPreloader,
    AppToast,
    WorkspaceMainLayout,
    AuthMainLayout,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    layout() {
      return this.$route.meta.layout;
    },
  },
  methods: {
    ...mapActions(["getAuthUser"]),
  },
  created () {
    if (location.pathname == '/auth/signin') {
      this.isLoaded = true;
      return;
    }

    this.getAuthUser()
        .then((user) => {
          if(this.authUser.registrationStep !== null) {
            this.$router.push({
              name: 'FinishSignup'
            })
          }
          if(this.$route.meta?.role && this.$route.meta?.role !== user.role?.slug) {
            this.$router.push('/')
          }
        })
        .finally(() => {
          this.isLoaded = true;
        })
  }
};
</script>


<style lang="scss" src="./assets/scss/app.scss"/>
