<script setup lang="ts">

</script>

<template>
  <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.46875 9.59672L10.9868 18.0947L27.4981 2.07593" stroke="currentColor" stroke-width="5.00586"/>
  </svg>

</template>
