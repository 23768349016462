<template>
  <div class="progress-circle">
    <svg
        class="progress-circle__svg"
        :height="computedSize"
        :width="computedSize"
    >
      <circle
          :cx="Number(radius) + 2"
          :cy="Number(radius) + 2"
          :r="radius"
          :stroke-width="stroke"
          fill="transparent"
          stroke="#fff"
      />
      <circle
          :cx="Number(radius) + 2"
          :cy="Number(radius) + 2"
          :r="radius"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="dashoffset"
          :stroke-width="stroke"
          :style="{'--val': modelValue, '--radius': radius}"
          fill="transparent"
          stroke="#00788C"
      />
    </svg>
    <span class="progress-circle__percent">
      <!-- {{ modelValue }}%-->
    </span>
  </div>
</template>

<script>
export default {
  name: 'mtCircleProgress',
  props: {
    modelValue: {
      type: [String, Number],
    },
    radius: {
      type: [String, Number],
      default: '15'
    },
    stroke: {
      type: [String, Number],
      default: '2'
    },
    max: {
      type: [String, Number],
      default: 100
    }
  },
  computed: {
    circumference() {
      if (!this.radius) return;
      return this.radius * 2 * 3.14
    },
    dashoffset() {
      return this.circumference - this.modelValue / this.max * this.circumference
    },
    computedSize() {
      return Number(this.radius) * 2 + Number(this.stroke) + 1 + 'px'
    },
  }
}
</script>

<style
    lang="scss"
    src="./mtCircleProgress.scss"
/>