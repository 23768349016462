<template>
  <vue-slider
      v-model="computedModelValue"
      lazy
      tooltip="focus"
      :data="props.data"
      :marks="marksFormat"
      :min="props.min"
      :max="props.max"
      :interval="props.step"
      class="app-range-slider"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import VueSlider from 'vue-slider-component';

const props = defineProps<{
  modelValue: number | undefined;
  data?: number[];
  min?: number;
  max?: number;
  step?: number;
}>();
const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
  get: () => props.modelValue,
  set: (value: number) => {
    if (props.modelValue === value) return;
    emit('update:modelValue', value)
  }
});

function marksFormat(value: number) {
  return Number.isInteger(value);
}
</script>

<style lang="scss">
$themeColor: #00788C;

@import "vue-slider-component/lib/theme/default";

.app-range-slider {
  margin-top: 20px;

  .vue-slider-mark-label {
    font-size: 12px;
    color: #000;
  }
}
</style>