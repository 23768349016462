<template>
  <main class="not-found">
    <div class="container">
      <h2 class="not-found__title">404</h2>
      <h4>
        Page Not Found
      </h4>
      <div class="not-found__btns">
        <mt-button
            :to="{
                    name: 'AssessmentPlansList'
                }"
        >
          Перейти на главную страницу
        </mt-button>
      </div>
    </div>
  </main>
</template>

<script>
import mtButton from "@/components/UI/mtButton/index.vue";

export default {
  name: "PageNotFound",
  components: { mtButton },

}
</script>

<style lang="scss">
.not-found {
  margin-bottom: 70px;
  height: 300px;
  position: relative;
  color: #00788C;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 30px;
  }
  &__text {
    font-size: 36px;
  }
  &__btns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

}
</style>