import planList       from './pages/PlanList';
import planListTable  from './pages/PlanListTable';
import expResp        from './pages/ExpResp';
import results        from './pages/Results';

export default function ( instance ) {
  return {
    planList        : planList( instance ),
    planListTable   : planListTable( instance ),
    expResp         : expResp( instance ),
    results         : results( instance ),
  }
}