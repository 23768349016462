<template>
  <div class="statistics-line">
    <div class="statistics-line__header">
      <div class="statistics-line__title">Прогресс</div>
      <div class="statistics-line__subtitle">Пройдено {{ progress }}%</div>
    </div>
    <mtLinearProgress
        :height="height"
        :progress="progress"
        :background-color="backgroundColor"
    />
  </div>
</template>

<script>
import MtLinearProgress from '@/components/UI/mtLinearProgress/mtLinearProgress.vue';

export default {
  name: "statisticsLine",
  components: {MtLinearProgress},
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number
    },
    backgroundColor: String
  },
}
</script>

<style
    lang="scss"
    src="./style.scss"
/>