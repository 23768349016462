<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6111 18H1.38889C1.15314 18 0.927048 17.9052 0.760349 17.7364C0.59365 17.5676 0.5 17.3387 0.5 17.1V0.9C0.5 0.661305 0.59365 0.432387 0.760349 0.263604C0.927048 0.0948211 1.15314 0 1.38889 0H15.6111C15.8469 0 16.073 0.0948211 16.2397 0.263604C16.4064 0.432387 16.5 0.661305 16.5 0.9V17.1C16.5 17.3387 16.4064 17.5676 16.2397 17.7364C16.073 17.9052 15.8469 18 15.6111 18ZM14.7222 16.2V1.8H2.27778V16.2H14.7222ZM4.94444 4.5H12.0556V6.3H4.94444V4.5ZM4.94444 8.1H12.0556V9.9H4.94444V8.1ZM4.94444 11.7H12.0556V13.5H4.94444V11.7Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>

</style>