import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import workspaceApi from './plugins/workspaceApi';
import lodash from './plugins/lodash';
import helpersFunc from './plugins/helpersFunc';
import vClickOutside from "click-outside-vue3";
import Maska from 'maska';
import useToast from "./components/UI/AppToast/useToast";

const app = createApp(App);
app.config.globalProperties.$toast = useToast().toast;

app
    .use(store)
    .use(router)
    .use(workspaceApi)
    .use(lodash)
    .use(helpersFunc)
    .use(vClickOutside)
    .use(Maska)

router.isReady()
    .then(() => {
        app.mount('#app');
    });