import item from './item';

export default {
  namespaced: true,

	state : {
    ...item.state,
  },

	getters : {
    ...item.getters,
  },

	actions : {
    ...item.actions,
  },

	mutations : {
    ...item.mutations,
  },
}