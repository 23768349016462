<template>
  <div
      class="mt-linear-progress"
      :style="[{height: height + 'px'}, {'background-color': backgroundColor}]"
  >
    <div
        :style="{ width: progress + '%' }"
        :class="classObject"
    />
  </div>
</template>

<script>
export default {
  name: 'mtLinearProgress',
  components: {},

  props: {
    progress: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number
    },
    backgroundColor: {
      type: String
    }
  },

  computed: {
    classObject() {
      return [
        'mt-linear-progress__item',
        {
          'mt-linear-progress__item_red': this.progress < 30,
          'mt-linear-progress__item_orange': this.progress >= 30 && this.progress < 80,
          'mt-linear-progress__item_green': this.progress >= 80,
        },
      ];
    },
  },

  methods: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped src="./mtLinearProgress.scss" />