import api from "@/network";

export default {
    async getAll(params: any) {
        params.type = 'user';
        return await api.get('admin/user', {
            params
        });
    },
    async create(data) {
        return await api.post('admin/user', data)
    },
    async update(uuid: string, data) {
        return await api.put(`admin/user/${uuid}`, data);
    },
    async revokeAgreements(uuid: string, data) {
        return await api.put(`admin/user/${uuid}/revoke-agreements`, data);
    },
    async delete(uuid: string) {
        return await api.delete(`admin/user/${uuid}`);
    },
    async import(file: File, planUuid: string) {
        let formData = new FormData()
        formData.append('file', file);
        if(planUuid) formData.append('planUuid', planUuid);
        return await api.put('import/users', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    async get(uuid: string) {
        return await api.get(`admin/user/${uuid}`);
    },

    async unblock(uuid: string) {
        return await api.put(`admin/user/${uuid}/unblock`);
    },

    async block(uuid: string) {
        return await api.put(`admin/user/${uuid}/block`);
    },
}