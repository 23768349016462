<script setup lang="ts">

import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import AlertCircleIcon from "@/components/icons/AlertCircleIcon.vue";

interface Props {
  errors: string[]
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => []
})
</script>

<template>
  <app-tooltip
      v-if="props.errors.length > 0"
      left
      class="cell-error"
  >
    <template #activator>
      <alert-circle-icon class="cell-error__icon"/>
    </template>
    <ul>
      <li
          v-for="error in props.errors ?? []"
      >
        {{ error }}
      </li>
    </ul>
  </app-tooltip>

</template>

<style lang="scss">
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }

}
.cell-error {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 40;
  &__icon {
    color: $red;
    width: 24px;
    height: 24px;
    cursor: help;
    animation: pulse 1s infinite;

  }
  .tooltip__body {
    width: 250px;
    ul {
      padding-left: 10px;
      color: #fff;
      li {
        font-size: 12px;
        text-align: left;
        text-transform: none!important;
        font-weight: 400!important;
      }
    }
  }
}
</style>